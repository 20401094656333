import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CapacitationModel } from 'src/app/models/capacitation';
import { CertificationRequestModel } from 'src/app/models/certificate';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent implements OnInit {

  constructor(private datepipe: DatePipe, private route: Router, private core: CoreService, private fb: FormBuilder, private auth: AuthService) { }


  disableItems: boolean = true;
  selected : string[];
  capacitationsAll: CapacitationModel[];
  capacitations: CapacitationModel[];
  selectedCurso : string;
  date: string;
  showView: boolean = false;
  certification: CertificationRequestModel = new CertificationRequestModel();
  goToSend: boolean = false;
  filenameFirm: string;
  fileFirm: FileList;
  private containFileFirm: boolean = false;
  filenameLogo: string;
  fileLogo: FileList;
  private containFileLogo: boolean = false;
  filenameUni: string;
  fileUni: FileList;
  private containFileUni: boolean = false;
  loadingLive: boolean = false;
  private IMG_URL = environment.IMG_URL;
  liveView: boolean = false;
  actualDate: Date;
  filterEndDate: Date;
  loadingCapacitations: boolean = false;

  ngOnInit(): void {
    this.actualDate = new Date();
    this.filenameFirm = 'Nombre del archivo.PNG';
    this.filenameLogo = 'Nombre del archivo.PNG';
    this.filenameUni = 'Nombre del archivo.PNG';
    this.getAllCapacitations();
  }

  getAllCapacitations() {
    this.capacitationsAll = [];
    this.capacitations = [];
    this.loadingCapacitations = true;
    this.core.allCapacitations().subscribe((data: any) => {
      this.capacitationsAll = data.data;
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
      console.log(error);
    }, () => {
      
    });
  }
  certSelected: boolean[] = [false, false, false];
  type: number;

  getSelectedValue(){
    console.log(this.selected);
  }

  changeView(event: boolean) {
    this.certForm.reset();
    this.goToSend = event;
    this.showView = false;
    this.disableItems = true;
    this.certSelected = [false, false, false];
  }

  changeDate(event: Date) {
    this.filterEndDate = event;
    debugger
    // this.getAllCapacitations();
    this.capacitationsAll.forEach(element => {
      if (element.currentStudents > 0 && element.status.id === 6 && element.isCertification) {
        let date: Date = new Date(element.endDate);
        if (date.getFullYear() == this.filterEndDate.getFullYear() && parseInt(this.datepipe.transform(element.endDate, 'MM')) == parseInt(this.datepipe.transform(this.filterEndDate, 'MM'))) {
          this.capacitations = [...this.capacitations, element]
        }
      }
      this.loadingCapacitations = false;
    });
  }

  generateCert() {
    if (!this.liveView) {
      this.certification.CapacitationId = this.certForm.value.CapacitationId;
      this.certification.Type = this.type;
      this.certification.UrlFirm = null;
      this.certification.UrlLogos = null;
      this.certification.UrlUniversity = null;
      this.core.uploudFilePng(this.fileFirm[0] ? this.fileFirm[0] : null).subscribe((data: any) => {
        this.certification.UrlFirm = `${this.IMG_URL}${data.data}`;
      }, error => {
        this.showError(error);
        console.log(error);
      }, () => {
        if (this.containFileLogo && !this.containFileUni) {
          this.core.uploudFilePng(this.fileLogo[0] ? this.fileLogo[0] : null).subscribe((data: any) => {
            this.certification.UrlLogos = `${this.IMG_URL}${data.data}`;
          }, error => {
            this.showError(error);
            console.log(error);
          }, () => {
            this.goToSend = true;
          });
        }
        if (!this.containFileLogo && this.containFileUni) {
          this.core.uploudFilePng(this.fileUni[0] ? this.fileUni[0] : null).subscribe((data: any) => {
            this.certification.UrlUniversity = `${this.IMG_URL}${data.data}`;
          }, error => {
            this.showError(error);
            console.log(error);
          }, () => {
            this.goToSend = true;
          });
        }
        if (this.containFileLogo && this.containFileUni) {
          this.core.uploudFilePng(this.fileLogo[0] ? this.fileLogo[0] : null).subscribe((data: any) => {
            this.certification.UrlLogos = `${this.IMG_URL}${data.data}`;
          }, error => {
            this.showError(error);
            console.log(error);
          }, () => {
            this.core.uploudFilePng(this.fileUni[0] ? this.fileUni[0] : null).subscribe((data: any) => {
              this.certification.UrlUniversity = `${this.IMG_URL}${data.data}`;
            }, error => {
              this.showError(error);
              console.log(error);
            }, () => {
              this.goToSend = true;
            });
          });
        }
        if (!this.containFileLogo && !this.containFileUni) {
          this.goToSend = true;
        }
      });
    } else {
      this.goToSend = true;
    }

  }

  changeCap(){
    this.showView = false;
  }


  showError(error: any) {
    if (error.error.code == 4) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Archivo no admitido. Solo formato PNG',
        showConfirmButton: false,
        timer: 1500
      });
    }
    if (error.status == 401 && error.error.code != 4) {
      this.auth.refreshToken();
    } else if (error.status == 500) {
      this.route.navigateByUrl('/500');
    }
  }

  selectedCert(id: number) {
    switch (id) {
      case 0:
        this.certSelected = [!this.certSelected[0], false, false];
        this.disableItems = !this.certSelected[0];
        this.type = 3;
        this.showView = false;
        break;
      case 1:
        this.certSelected = [false, !this.certSelected[1], false];
        this.disableItems = !this.certSelected[1];
        this.type = 1;
        this.showView = false;
        break;
      case 2:
        this.certSelected = [false, false, !this.certSelected[2]];
        this.disableItems = !this.certSelected[2];
        this.type = 2;
        this.showView = false;
        break;
    }
  }


  showLiveView() {
    console.log(this.certForm);
    this.loadingLive = true;
    this.certification.CapacitationId = this.certForm.value.CapacitationId;
    this.certification.Type = this.type;
    this.certification.UrlFirm = null;
    this.certification.UrlLogos = null;
    this.certification.UrlUniversity = null;
    this.core.uploudFilePng(this.fileFirm[0] ? this.fileFirm[0] : null).subscribe((data: any) => {
      this.certification.UrlFirm = `${this.IMG_URL}${data.data}`;
    }, error => {
      this.showError(error);
      this.loadingLive = false;
      console.log(error);
    }, () => {
      if (this.containFileLogo && !this.containFileUni) {
        this.core.uploudFilePng(this.fileLogo[0] ? this.fileLogo[0] : null).subscribe((data: any) => {
          this.certification.UrlLogos = `${this.IMG_URL}${data.data}`;
        }, error => {
          this.showError(error);
          this.loadingLive = false;
          console.log(error);
        }, () => {
          this.CoreShowLive(this.certification);
        });
      }
      if (!this.containFileLogo && this.containFileUni) {
        this.core.uploudFilePng(this.fileUni[0] ? this.fileUni[0] : null).subscribe((data: any) => {
          this.certification.UrlUniversity = `${this.IMG_URL}${data.data}`;
        }, error => {
          this.showError(error);
          this.loadingLive = false;
          console.log(error);
        }, () => {
          this.CoreShowLive(this.certification);
        });
      }
      if (this.containFileLogo && this.containFileUni) {
        this.core.uploudFilePng(this.fileLogo[0] ? this.fileLogo[0] : null).subscribe((data: any) => {
          this.certification.UrlLogos = `${this.IMG_URL}${data.data}`;
        }, error => {
          this.showError(error);
          this.loadingLive = false;
          console.log(error);
        }, () => {
          this.core.uploudFilePng(this.fileUni[0] ? this.fileUni[0] : null).subscribe((data: any) => {
            this.certification.UrlUniversity = `${this.IMG_URL}${data.data}`;
          }, error => {
            this.showError(error);
            this.loadingLive = false;
            console.log(error);
          }, () => {
            this.CoreShowLive(this.certification);
          });
        });
      }
      if (!this.containFileLogo && !this.containFileUni) {
        this.CoreShowLive(this.certification);
      }
      this.liveView = true;
    });

  }

  CoreShowLive (cert: CertificationRequestModel) {
    this.core.ShowLiveView(this.certification).subscribe((data: any) => {
      console.log(data);
      this.createImageFromBlob(data);
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
      console.log(error);
    }, () => {
      this.showView = true;
      this.loadingLive = false;
    });
  }

  imageToShow: any;
  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
      console.log(this.imageToShow);
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }

  }



  filechangeFirm(files: FileList) {
    this.fileFirm = files;
    this.filenameFirm = files[0].name;
    this.containFileFirm = true;
    this.showView = false;
  }

  filechangeLogo(files: FileList) {
    this.fileLogo = files;
    this.filenameLogo = files[0].name;
    this.containFileLogo = true;
    this.showView = false;
  }

  filechangeUni(files: FileList) {
    this.fileUni = files;
    this.filenameUni = files[0].name;
    this.containFileUni = true;
    this.showView = false;
  }

  certForm = this.fb.group({
    CapacitationId: [0, [Validators.required]],
    Firm:  ['', [Validators.required]],
    Logos:  [''],
    University:  [''],
  });


}
