import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CertificationGenerateRequestModel, CertificationRequestModel } from 'src/app/models/certificate';
import { StudentCertificationModel } from 'src/app/models/student';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-students-send',
  templateUrl: './students-send.component.html',
  styleUrls: ['./students-send.component.scss']
})
export class StudentsSendComponent implements OnChanges {

  @Input() cert: CertificationRequestModel; // decorate the property with @Input()
  @Output() goBack = new EventEmitter<boolean>();


  constructor(private modalService: BsModalService, private core: CoreService, private auth: AuthService, private route: Router) { }

  ngOnChanges(): void {
    this.getAllStudents();
    this.getCapacitation();
  }

  goToCertifications(value: boolean) {
    this.students = [];
    this.goBack.emit(value);
  }
  modalRef: BsModalRef;
  page: number = 1;
  students: StudentCertificationModel[];
  student: StudentCertificationModel;
  capacitationName: string;
  studentsId: number[] = [];
  loading: boolean = true;
  saving: boolean = false;
  min_max: string;
  min: number;
  max: number;
  notElements: boolean = false;
  searchText: string;
  openModal(template: TemplateRef<any>, id: number) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }


  sendPDF() {
    this.saving = true;
    let certificate: CertificationGenerateRequestModel = new CertificationGenerateRequestModel();
    certificate.CapacitationId = this.cert.CapacitationId;
    certificate.Type = this.cert.Type;
    certificate.UrlFirm = this.cert.UrlFirm;
    certificate.UrlLogos = this.cert.UrlLogos;
    certificate.UrlUniversity = this.cert.UrlUniversity;
    certificate.Students = this.studentsId;
    this.core.GeneratePDFAndSend(certificate).subscribe((data: any) => {
      //console.log(data);
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
      console.log(error);
    }, () => {
      this.saving = false;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Se han enviado las certificaciones con éxito',
        showConfirmButton: false,
        timer: 2500
      });
    });
  }


  selectedStudent(id: number) {
    this.students.forEach(element => {
      if (element.id === id) {
        this.student = element;
      }
    });
  }

  selectAll(event: any) {
    this.students.forEach(element => {
      element.isChecked = event.target.checked;
      if (event.target.checked) {
        this.studentsId.push(element.id);
      } else {
        this.studentsId.forEach((element1, index) => {
          if (element1 === element.id) {
            this.studentsId.splice(index, 1);
          }
        });
      }
    });
  }

  selectedStudents(id: number, event: any) {
    if (event.target.checked) {
      this.studentsId.push(id);
    } else {
      this.studentsId.forEach((element, index) => {
        if (element === id) {
          this.studentsId.splice(index, 1);
        }
      });
    }
  }

  notResults: boolean = false;
  search(text: string) {
    this.students = [];
    this.loading = true;
    this.notElements = false;
    this.notResults = false;
    if (text.length > 2) {
      this.core.ShowStudentsSearchCert(this.cert.CapacitationId, text).subscribe((data: any) => {
        this.students = data.data;
      }, error => {
        if (error.status == 401) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.route.navigateByUrl('/500');
        }
        console.log(error);
      }, () => {
        this.loading = false;
        if (this.students.length === 0) {
          this.notElements = true;
          this.notResults = true;
        }
      });
    } else {
      this.core.ShowStudents(this.cert.CapacitationId).subscribe((data: any) => {
        this.students = data.data;
      }, error => {
        if (error.status == 401) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.route.navigateByUrl('/500');
        }
        console.log(error);
      }, () => {
        this.loading = false;
        if (this.students.length === 0) {
          this.notElements = true;
          this.notResults = true;
        }
      });
    }

  }

  filter() {
    console.log(this.min_max);
    switch (this.min_max) {
      case 'all':
        this.min = 0;
        this.max = 100;
        break;
      case '10_20':
        this.min = 10;
        this.max = 20;
        break;
      case '20_30':
        this.min = 20;
        this.max = 30;
        break;
      case '30_40':
        this.min = 30;
        this.max = 40;
        break;
      case '40_50':
        this.min = 40;
        this.max = 50;
        break;
      case '50_60':
        this.min = 50;
        this.max = 60;
        break;
      case '60_70':
        this.min = 60;
        this.max = 70;
        break;
      case '70_100':
        this.min = 70;
        this.max = 100;
        break;
    }
    this.students = [];
    this.loading = true;
    this.notElements = false;
    this.notResults = false;
    this.core.ShowStudentsFilterCert(this.cert.CapacitationId, this.min, this.max).subscribe((data: any) => {
      this.students = data.data;
      console.log(data);
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
      console.log(error);
    }, () => {
      this.loading = false;
      if (this.students.length === 0) {
        this.notElements = true;
      }
    });
  }

  getAllStudents() {
    this.students = [];
    this.loading = true;
    this.min = 70;
    this.max = 100;
    this.notElements = false;
    this.notResults = false;
    this.core.ShowStudentsFilterCert(this.cert.CapacitationId, 70, 100).subscribe((data: any) => {
      this.students = data.data;
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
      console.log(error);
    }, () => {
      this.loading = false;
      if (this.students.length === 0) {
        this.notElements = true;
      }
    });
  }

  getCapacitation() {
    this.core.showCapacitation(this.cert.CapacitationId).subscribe((data: any) => {
      this.capacitationName = data.data.name;
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
      console.log(error);
    });
  }

}
