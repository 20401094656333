import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/services/storage.service';
import { version } from '../../../../package.json';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private route: Router, private auth: AuthService, private storage: StorageService, private core: CoreService) { }
  firstName: string;
  lastName: string;
  role: string;
  versionApp: string;
  photo: string;
  ngOnInit(): void {
    let decoded: any = jwt_decode(this.storage.getToken());
    this.firstName = decoded.firstName;
    this.lastName = decoded.lastName;
    this.role = decoded.role;
    this.versionApp = version;
    // tslint:disable-next-line: radix
    this.showInfoUser(parseInt(decoded.Id));
  }


  showInfoUser(id: number){
    this.core.getUser(id).subscribe((data: any) => {
      this.photo = data.data.photo;
    });
  }
  closeSesion(){
    this.auth.logout();
    this.route.navigateByUrl('/ingresar');
  }

  changePassword() {
    this.route.navigateByUrl('contraseña/cambiar');
  }
}
