<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
    <h2>Capacitaciones</h2>
  </div>

  <div  class="row mt-4 animate__animated animate__fadeIn animate__faster">
    <div class="col-md-12" style="margin-bottom: 10px;">
      <div class="container">
        <div class="row">
          <form style="width: 100%;">
            <div class="form-row align-items-center">
              <div class="col-auto ">
                <label class="mt-2 font-color" for="inlineFormInputName">Filtrar por:</label>
              </div>
              <div class="col-auto">
                <ng-select style="width: 200px;"  #selectDropdown [loading]="disableFilters" loadingText="Cargando..." [searchable]="false" [items]="categories" bindValue="id" bindLabel="name" placeholder="Categoría" (change)="changeCat($event)" (clear)="allCapacitations()" class="custom-filter mr-sm-2" id="inlineFormCustomSelect">
                </ng-select>
              </div>
              <div class="col-auto">
                <ng-select style="width: 110px;" #selectDropdown2 [loading]="disableFilters" loadingText="Cargando..." [searchable]="false" [items]="statusall" bindValue="id" bindLabel="description" placeholder="Estado" (change)="changeStatus($event);" (clear)="allCapacitations()" class="custom-filter mr-sm-2" id="inlineFormCustomSelect">
                </ng-select>
              </div>
              <div class="col-md-2 mr-2">
                <input [disabled]="disableFilters" #selectDate [loading]="disableFilters" tooltip="Inicio" type="date" (change)="startDate()" [(ngModel)]="start" class="form-control input-custom" placeholder="Por fecha" name="date" id="date">
              </div>
              <div class="col-md-2 mr-2">
                <input [disabled]="disableFilters" #selectDate2 [loading]="disableFilters" tooltip="Fin" type="date" (change)="endDate()" [(ngModel)]="end" class="form-control input-custom" placeholder="Por fecha" name="endDate" id="date1">
              </div>
              <div class="col-mt-2">
                <form style="width: 100%;">
                  <div class="form-row align-items-center">
                    <input type="search" class="form-control search-bar" placeholder="Buscar" [(ngModel)]="searchText" (ngModelChange)="search()" name="buscar" id="buscar">
                  </div>
                </form>
              </div>
              <div class="col-mt-1">
                <button tooltip="Quitar filtros" class="btn" style="color: #e3000b;" [disabled]="disableFilters" (click)="resetFilters(selectDropdown, selectDropdown2)"><i class="far fa-times-circle"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="error" class="text-danger col-md-12" style="font-size: 12px; margin-top: 10px;">
      Los intervalos de fecha son incorrectos.<br> La fecha de inicio no puede ser menor a la fecha de finalización.
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="!loading" class="row mt-3">
    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th style="color: #022A3A;" scope="col" (click)="orderByName()">Nombre de Capacitación <span *ngIf="isOrderByName"><i class="fas fa-chevron-up"></i></span><span *ngIf="!isOrderByName"><i class="fas fa-chevron-down"></i></span></th>
            <th style="color: #022A3A;" scope="col" (click)="orderByDate()">Fecha Inicio<span *ngIf="isDate"><i class="fas fa-chevron-up"></i></span><span *ngIf="!isDate"><i class="fas fa-chevron-down"></i></span></th>
            <th style="color: #022A3A;" scope="col">Estado </th>
            <th style="color: #022A3A;" scope="col" tooltip="Alumnos/Compras/Total">Plazas</th>
            <th style="color: #022A3A;" scope="col">Acciones</th>
          </tr>
        </thead>
        <div *ngIf="capacitations.length == 0" class="row justify-content-center mt-4 mb-4">
          <h4>No se encontraron capacitaciones.</h4>
        </div>
        <tbody>
          <tr  class="info-row" *ngFor="let i of capacitations | orderBy:order:isOrderByName | orderByDate:isDate | paginate: {itemsPerPage: 6, currentPage: page}">
            <th style="cursor: pointer;" (click)="showCapacitation(i.id, template)" tooltip="Ver más">{{i.name}}</th>
            <td>{{i.startDate | date:'dd/MM/yyyy'}}</td>
            <td>{{i.status.description}}</td>
            <td tooltip="Alumnos/Compras/Total">{{i.currentStudents}}/{{i.currentPurchases}}/{{i.quantity}}</td>
            <td>
              <div class="container-fluid">
                <div class="row justify-content-right">
                  <div *ngIf="isAdmin" class="col-auto">
                    <button class="btn btn-action" [disabled]="i.status.id === 6" tooltip="Editar" placement="top" (click)="goToEdit(i.id)"><i class="fas fa-pen"></i></button>
                  </div>
                  <div *ngIf="isAdmin" class="col-auto">
                    <button class="btn btn-action" tooltip="Duplicar" placement="top" (click)="goToDuplicate(i.id)"><i class="far fa-clone"></i></button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-action " [disabled]="i.currentStudents === 0" tooltip="Ver alumnos" placement="top" (click)="goToStudents(i.id)"><i class="far fa-user"></i></button>
                  </div>
                  <div *ngIf="isAdmin" class="col-auto">
                    <button class="btn btn-action" [disabled]="i.status.id === 6" tooltip="Eliminar" placement="top" (click)="delete(i.id, i.name)"><i class="far fa-trash-alt"></i></button>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-action " [disabled]="i.currentPurchases === 0" tooltip="Ver compradores" placement="top" (click)="goToBuyers(i.id)"><i class="fas fa-user-tag"></i></button>
                  </div>
                  <div *ngIf="i.createdByTotvs" class="col-auto">
                    <div class="" tooltip="Creado en Totvs" placement="top"><i class="fas fa-file-csv"></i></div>
                  </div>
                  
                  <div *ngIf="!i.createdByTotvs" class="col-auto">
                    <div class="" tooltip="Duplicado" placement="top"><i class="fas fa-copy"></i></div>
                  </div>

                </div>
              </div>
            </td>
          </tr>
          <br>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!loading" class="row justify-content-center animate__animated animate__fadeIn animate__faster">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
  <div class="row justify-content-center mb-5">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>

<ng-template  #template>

  <div style="z-index: 999999;" class="modal-body" >
    <button class="btn btn-close" style="z-index: 999 !important;" (click)="modalRef.hide()">Cerrar <i class="far fa-times-circle"></i></button>


      <div *ngIf="capacitation.coverImage!='-';else coverDefault" style="background-image: url({{capacitation.coverImage}});" class="cover"></div>


    <ng-template #coverDefault>
      <div style="background-image: url(../../../assets/construccion.jpg);" class="cover"></div>
    </ng-template>

    <div class="container-fluid mt-3 mr-2">
      <div class="row justify-content-start">
        <div class="col-auto">
          <img *ngIf="capacitation.thumbnail != '-';else thumbnailDefault" src="{{capacitation.thumbnail}}" class="thumbnail">
          <ng-template #thumbnailDefault>
            <img src="../../../assets/Barbieri-Logo-MarcaGrafica.png" class="thumbnail">
          </ng-template>
        </div>
        <div class="col-auto">
          <div class="card " style="margin-top: 13px;">
              <div class="container-fluid" style="margin-top: 7px;">
                <div class="row">
                  <div class="col-auto align-content-center align-self-center">
                    <p style="font-size: 20px;"><i class="far fa-user-circle"></i></p>
                  </div>
                  <div class="col-auto align-content-center align-self-center">
                    <p style="line-height: 15px;">{{capacitation.currentStudents}} <br><small>inscriptos</small></p>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div *ngIf="capacitation.linkZoom != null" class="col-auto align-self-center">
          <a tooltip="Haz click para abrir en Zoom" [href]="capacitation.linkZoom" target="_blank"><img style="width: 120px;" src="../../../assets/Zoom-VideoConferencias-logo.png"></a>
        </div>
      </div>
      <div class="row justify-content-start mt-3">
        <h2 style="font-weight: bold; color: white; z-index: 999;">{{capacitation.name}} <span><p style="font-size: 12px;">SKU: {{capacitation.sku}}</p></span></h2>
      </div>
      <div class="row justify-content-start">
        <div class="col-auto" *ngFor="let item of capacitation.userTrainer">
          <div class="card custom-card">
            <div class="container-fluid">
              <div class="row">
                <p style="color: black; line-height: 15px; font-size: 20px; margin: 0; margin-top: 7px; margin-left: 7px;">{{item.firstName}} {{item.lastName}}<br><small style="font-size: 12px;">Capacitador</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start mt-5">
        <div class="col-auto" *ngFor="let item of capacitation.categories">
          <div class="card custom-card-category">
            <p style="margin-top: 5px; text-align: center;">{{item.name}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="capacitation.categories.length != 0" class="row justify-content-start ml-3 mt-2">
        <p>Categoría/as</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <p style="color: #606060; text-align: center;"><i class="far fa-calendar-check"></i> Fecha de inicio <br><span style="color: black; font-size: 16px;">{{capacitation.startDate | date:'dd/MM/YYYY'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;"><i class="far fa-calendar-check"></i> Fecha de cierre <br><span style="color: black; font-size: 16px;">{{capacitation.endDate | date:'dd/MM/YYYY'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;"><i class="far fa-clock"></i> Horario <br><span style="color: black; font-size: 16px;">{{capacitation.startHours | date:'HH:mm'}} / {{capacitation.endHours | date:'HH:mm'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;">Precio <br><span style="color: black; font-size: 16px;">{{capacitation.price | currency:'$'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;">Lugar <br><span style="color: black; font-size: 16px;">{{capacitation.place}}</span></p>
        </div>
      </div>
      <div class="row justify-content-start">
        <p style="margin-bottom: 0;">Descripción</p>
      </div>
      <div class="row justify-content-center">
        <div class="card card-desc">
          <div  [innerHTML]="loadHtml()" style="padding: 5px;"></div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
