import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';
import { NgForm } from '@angular/forms';
import { StudentResponseModel } from 'src/app/models/student';
import { CapacitationModel } from 'src/app/models/capacitation';
import { TrainerModel } from 'src/app/models/trainer';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { CategorieModel } from 'src/app/models/categorie';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(private core: CoreService, private route: Router, private auth: AuthService) { }
  page: number = 1;
  hoverTextStudent: boolean = true;
  isFilter = false;
  isFilterCap = false;
  hoverTextTrainings: boolean = false;
  hoverTextErrors: boolean = false;
  reportType: boolean[] = [true, false, false]; //true = Reporte de Estudiantes; false = Reporte de capacitaciones;

  ngOnInit(): void {
    this.getAllStudents();
    this.getAllCapacitations();
    this.allTrainers();
    this.getAllCategories();
  }

  firstName: string = null;
  lastName: string = null;
  dni: string = null;
  email: string = null;
  name: string;
  trainerId: number;
  capacitation: string = null;
  categorySelect: string = null;

  loading: boolean = true;
  modifiedFilter: boolean = false;

  students: StudentResponseModel[];
  copyStudents: StudentResponseModel[];
  capacitations: CapacitationModel[];
  capacitationsCopy: CapacitationModel[];
  trainers: TrainerModel[];
  categories: CategorieModel[];
  
  getAllStudents() {
    this.firstName = null;
    this.lastName = null;
    this.dni = null;
    this.email = null;
    this.capacitation = null;
    this.loading = true;
    this.core.allStudentReport().subscribe((data: any) => {
      this.students = [];
      this.copyStudents = [];
      this.students = data.data;
      this.copyStudents = data.data;
      this.isFilter = false;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }

  allTrainers() {
    this.core.getTrainers().subscribe((data: any) => {
      this.trainers = data.data;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    });
  }

  getAllCategories(){
    this.core.getCategories().subscribe((data: any) => {
      this.categories = data.data;
    }, (error: any) => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if(error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    })
  }

  getAllCapacitations() {
    this.loading = true;
    this.name = null;
    this.trainerId = null;
    this.categorySelect = null;
    this.core.allCapacitationReport().subscribe((data: any) => {
      this.capacitations = [];
      this.capacitations = data.data;
      this.capacitationsCopy = data.data;
      this.isFilterCap = false;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }

 
  filterFirstName(){
    if(!this.modifiedFilter){
      this.students = this.copyStudents.filter(item => item.firstname.toLowerCase().indexOf(this.firstName.toLowerCase())!=-1);

      this.modifiedFilter = true;
    }else{
      this.students = this.students.filter(item => item.firstname.toLowerCase().indexOf(this.firstName.toLowerCase())!=-1);
    }
  }
  
  filterLastName(){
    if(!this.modifiedFilter){
      this.students = this.copyStudents.filter(item => item.lastname.toLowerCase().indexOf(this.lastName.toLowerCase())!=-1);
      this.modifiedFilter = true;
    }else{
      this.students = this.students.filter(item => item.lastname.toLowerCase().indexOf(this.lastName.toLowerCase())!=-1);
    }
  }
  
  filterDNI(){
    if(!this.modifiedFilter){
      this.students = this.copyStudents.filter(item => item.dni.toLowerCase().indexOf(this.dni.toLowerCase())!=-1);
      this.modifiedFilter = true;
    }else{
      this.students = this.students.filter(item => item.dni.toLowerCase().indexOf(this.dni.toLowerCase())!=-1);
    }
  }
  
  filterEmail(){
    if(!this.modifiedFilter){
      this.students = this.copyStudents.filter(item => item.email.toLowerCase().indexOf(this.email.toLowerCase())!=-1);
      this.modifiedFilter = true;
    }else{
      this.students = this.students.filter(item => item.email.toLowerCase().indexOf(this.email.toLowerCase())!=-1);
    }
  }
  
  filterCapacitation(){
    if(!this.modifiedFilter){
      this.students = this.copyStudents.filter(item => item.capacitationName.find(name=>name.toLowerCase().indexOf(this.capacitation.toLowerCase())!=-1));
      this.modifiedFilter = true;
    }else{
      this.students = this.students.filter(item => item.capacitationName.find(name=>name.toLowerCase().indexOf(this.capacitation.toLowerCase())!=-1));
    }
  }

  filterAll(filterForm: NgForm){
    this.page = 1;
    this.students = [];
    this.isFilter = true;
    this.modifiedFilter = false;
    if(this.firstName!=''&&this.firstName!=null) this.filterFirstName();
    if(this.lastName!=''&&this.lastName!=null) this.filterLastName();
    if(this.email!=''&&this.email!=null) this.filterEmail();
    if(this.dni!=''&&this.dni!=null) this.filterDNI();
    if(this.capacitation!=''&&this.capacitation!=null) this.filterCapacitation();
    if(!this.modifiedFilter)this.students = this.copyStudents;
  };


  // filter(filterForm: NgForm) {
  //   this.loading = true;
  //   this.core.allStudentReportFilter(this.firstName, this.lastName, this.dni, this.email, this.capacitation).subscribe((data: any) => {
  //     this.students = [];
  //     this.students = data.data;
  //     this.isFilter = true;
  //   }, error => {
  //     console.log(error);
  //     if (error.status == 401) {
  //       this.auth.refreshToken();
  //     } else if (error.status == 500) {
  //       this.route.navigateByUrl('/500');
  //     }
  //   }, () => {
  //     this.loading = false;
  //   });
  // }

  filterNameCapacitations(capacitationsList: CapacitationModel[]): CapacitationModel[]{
    return capacitationsList.filter(cap => cap.name.toLowerCase().indexOf(this.name.toLowerCase())!=-1);
  }
  filterTrainer(capacitationsList: CapacitationModel[]): CapacitationModel[]{
    return capacitationsList.filter(item => item.userTrainer.find(trainer =>  trainer.id == this.trainerId));
  }
  filterCategory(capacitationsList: CapacitationModel[]): CapacitationModel[]{
    return capacitationsList.filter(cap => cap.categories.find(cat => cat.name.toLowerCase().indexOf(this.categorySelect.toLowerCase())!=-1));
  }


  filterCapacitationsReport(){
    this.isFilterCap = true;
    let capacitationsList: CapacitationModel[] = this.capacitationsCopy;
    
    if(this.name!=''&&this.name!=null) {
      capacitationsList = this.filterNameCapacitations(capacitationsList);
    }
    if(this.trainerId!=null) {
       capacitationsList = this.filterTrainer(capacitationsList);
    }
    if(this.categorySelect!=null) {
       capacitationsList = this.filterCategory(capacitationsList);
    }
    this.capacitations = capacitationsList;
  }

  // filterCapcitation(filterForm: NgForm) {
  //   this.loading = true;
  //   this.core.allCapacitationReportFilter(this.name, this.trainerId).subscribe((data: any) => {
  //     this.capacitations = [];
  //     this.capacitations = data.data;
  //     this.isFilterCap = true;
  //     this.loading = false;
  //     this.filterCategory();
  //   }, error => {
  //     console.log(error);
  //     if (error.status == 401) {
  //       this.auth.refreshToken();
  //     } else if (error.status == 500) {
  //       this.route.navigateByUrl('/500');
  //     }
  //   }, () => {
  //     this.loading = false;
  //   });
  // }

  exportExcel() {
    if (this.reportType[0]) {
      if (this.isFilter) {
        if (this.students.length != 0) {
          this.core.exportExcel(this.firstName, this.lastName, this.dni, this.email, this.capacitation).subscribe((data: any) => {
            let blob = new Blob([data], { type: 'text/xlsx' });
            console.log(blob);
            let url = window.URL.createObjectURL(blob);
            if (navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, 'export.xlsx');
            } else {
              let a = document.createElement('a');
              a.href = url;
              a.download = `Reporte_Alumnos_${new Date().toLocaleString()}.xlsx`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            window.URL.revokeObjectURL(url);

          }, error => {
            console.log(error);
            if (error.status == 401) {
              this.auth.refreshToken();
            } else if (error.status == 500) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Ups! Ha ocurrido un error en el servidor, inténtelo de nuevo mas tarde.',
                showConfirmButton: false,
                timer: 1500
              });
            }
          }, () => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Se descargo el reporte de alumnos con filtro correctamente',
              showConfirmButton: false,
              timer: 1500
            });
          });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ups! No se encontraron alumnos en el sistema.',
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else {
        this.core.exportExcelAll(0).subscribe((data: any) => {
          let blob = new Blob([data], { type: 'text/xlsx' });
          let url = window.URL.createObjectURL(blob);
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, 'export.xlsx');
          } else {
            let a = document.createElement('a');
            a.href = url;
            a.download = `Reporte_Alumnos_${new Date().toLocaleString()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          window.URL.revokeObjectURL(url);

        }, error => {
          console.log(error);
          if (error.status == 401) {
            this.auth.refreshToken();
          } else if (error.status == 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Ups! Ha ocurrido un error en el servidor, inténtelo de nuevo mas tarde.',
              showConfirmButton: false,
              timer: 1500
            });
          }
        }, () => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se descargo el reporte completo de alumnos correctamente.',
            showConfirmButton: false,
            timer: 1500
          });
        });
      }
    } else if (this.reportType[1]) {
      if (this.isFilterCap) {
        if (this.capacitations.length != 0) {
          this.core.exportExcelCapacitation(this.name, this.trainerId).subscribe((data: any) => {

            let blob = new Blob([data], { type: 'text/xlsx' });
            let url = window.URL.createObjectURL(blob);
            if (navigator.msSaveOrOpenBlob) {
              navigator.msSaveBlob(blob, 'export.xlsx');
            } else {
              let a = document.createElement('a');
              a.href = url;
              a.download = `Reporte_Capacitaciones_${new Date().toLocaleString()}.xlsx`;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
            window.URL.revokeObjectURL(url);
          }, error => {
            console.log(error);
            if (error.status == 401) {
              this.auth.refreshToken();
            } else if (error.status == 500) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Ups! Ha ocurrido un error en el servidor, inténtelo de nuevo mas tarde.',
                showConfirmButton: false,
                timer: 1500
              });
            }
          }, () => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Se descargo el reporte de capacitaciones con filtro correctamente',
              showConfirmButton: false,
              timer: 1500
            });
          });
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ups! No se encontraron capacitaciones en el sistema.',
            showConfirmButton: false,
            timer: 1500
          });
        }
      } else {
        this.core.exportExcelAll(1).subscribe((data: any) => {
          let blob = new Blob([data], { type: 'text/xlsx' });
          let url = window.URL.createObjectURL(blob);
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, 'export.xlsx');
          } else {
            let a = document.createElement('a');
            a.href = url;
            a.download = `Reporte_Capacitaciones_${new Date().toLocaleString()}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          window.URL.revokeObjectURL(url);
        }, error => {
          console.log(error);
          if (error.status == 401) {
            this.auth.refreshToken();
          } else if (error.status == 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Ups! Ha ocurrido un error en el servidor, inténtelo de nuevo mas tarde.',
              showConfirmButton: false,
              timer: 1500
            });
          }
        }, () => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se descargo el reporte completo de capacitaciones correctamente.',
            showConfirmButton: false,
            timer: 1500
          });
        });
      }
    }
  }

  selectMenuItem(item: number) {
    switch (item) {
      case 0:
        this.hoverTextStudent = true;
        this.hoverTextTrainings = false;
        this.hoverTextErrors = false;
        this.reportType[0] = true;
        this.reportType[1] = false;
        this.reportType[2] = false;
        break;
      case 1:
        this.hoverTextStudent = false;
        this.hoverTextTrainings = true;
        this.hoverTextErrors = false;
        this.reportType[0] = false;
        this.reportType[1] = true;
        this.reportType[2] = false;
        break;
      case 2:
        this.hoverTextStudent = false;
        this.hoverTextTrainings = false;
        this.hoverTextErrors = true;
        this.reportType[0] = false;
        this.reportType[1] = false;
        this.reportType[2] = true;
        break;
    }
  }

}
