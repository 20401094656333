import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { LoginUserComponent } from './components/authentication/login-user/login-user.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { UpdatePasswordComponent } from './components/authentication/update-password/update-password.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CertificationsComponent } from './components/certifications/certifications.component';
import { StudentsSendComponent } from './components/certifications/students-send/students-send.component';
import { ErrorReportingComponent } from './components/error-reporting/error-reporting.component';
import { AuthErrorComponent } from './components/errors/auth-error/auth-error.component';
import { NotFountComponent } from './components/errors/not-fount/not-fount.component';
import { ServerErrorComponent } from './components/errors/server-error/server-error.component';
import { HomeComponent } from './components/home/home.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { CrudAdminComponent } from './components/profile-management/crud-admin/crud-admin.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { ReportsComponent } from './components/reports/reports.component';
import { CrudTrainersComponent } from './components/trainers/crud-trainers/crud-trainers.component';
import { TrainersComponent } from './components/trainers/trainers.component';
import { EditTrainingsComponent } from './components/trainings/edit-trainings/edit-trainings.component';
import { ListBuyersComponent } from './components/trainings/list-buyers/list-buyers.component';
import { AddStudentComponent } from './components/trainings/list-students/add-student/add-student.component';
import { ListStudentsComponent } from './components/trainings/list-students/list-students.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { AuthGuard } from './guards/auth.guard';
import { NotAuthGuard } from './guards/not-auth.guard';


const routes: Routes = [
  { path: 'ingresar', component: LoginUserComponent, canActivate: [NotAuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [NotAuthGuard] },
  { path: 'reset-password/:token/:mail', component: ResetPasswordComponent, canActivate: [NotAuthGuard] },
  { path: 'inicio', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'contraseña/:type', component: UpdatePasswordComponent, canActivate: [AuthGuard] },
  { path: 'categorias', component: CategoriesComponent, canActivate: [AuthGuard]},

  { path: 'certificaciones', component: CertificationsComponent, canActivate: [AuthGuard]},

  { path: 'capacitadores', component: TrainersComponent, canActivate: [AuthGuard]},
  { path: 'capacitadores/nuevo', component: CrudTrainersComponent, canActivate: [AuthGuard]},
  { path: 'capacitadores/editar/:id', component: CrudTrainersComponent, canActivate: [AuthGuard]},

  { path: 'capacitaciones', component: TrainingsComponent, canActivate: [AuthGuard]},
  { path: 'capacitaciones/editar/:id', component: EditTrainingsComponent, canActivate: [AuthGuard]},
  { path: 'capacitaciones/duplicar/:id', component: EditTrainingsComponent, canActivate: [AuthGuard]},
  { path: 'capacitaciones/alumnos/:id', component: ListStudentsComponent, canActivate: [AuthGuard]},
  { path: 'capacitaciones/compradores/:id', component: ListBuyersComponent, canActivate: [AuthGuard]},

  { path: 'reportes', component: ReportsComponent, canActivate: [AuthGuard]},

  { path: 'administracion', component: ProfileManagementComponent, canActivate: [AuthGuard]},
  { path: 'administracion/nuevo', component: CrudAdminComponent, canActivate: [AuthGuard]},
  { path: 'administracion/editar/:id', component: CrudAdminComponent, canActivate: [AuthGuard]},

  { path: 'notificaciones', component: NotificationsComponent, canActivate: [AuthGuard]},

  { path: 'formulario/:token', component: AddStudentComponent},

  { path: 'calendario', component: CalendarComponent, canActivate: [AuthGuard]},
  { path: '401', component: AuthErrorComponent, canActivate: [AuthGuard]},
  { path: '500', component: ServerErrorComponent, canActivate: [AuthGuard]},
  { path: '',   redirectTo: '/ingresar', pathMatch: 'full' },
  { path: '**', component: NotFountComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
