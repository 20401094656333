import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { TrainersComponent } from './components/trainers/trainers.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { TrainingsComponent } from './components/trainings/trainings.component';
import { CertificationsComponent } from './components/certifications/certifications.component';
import { ReportsComponent } from './components/reports/reports.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { RegisterUserComponent } from './components/authentication/register-user/register-user.component';
import { LoginUserComponent } from './components/authentication/login-user/login-user.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { EditTrainingsComponent } from './components/trainings/edit-trainings/edit-trainings.component';
import { ListStudentsComponent } from './components/trainings/list-students/list-students.component';


import { CrudTrainersComponent } from './components/trainers/crud-trainers/crud-trainers.component';
import { NotFountComponent } from './components/errors/not-fount/not-fount.component';
import { ServerErrorComponent } from './components/errors/server-error/server-error.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { OrderByPipe } from './pipes/order-by.pipe';
import { OrderByDatePipe } from './pipes/order-by-date.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AuthErrorComponent } from './components/errors/auth-error/auth-error.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule, PopoverConfig } from 'ngx-bootstrap/popover';
import { AddStudentComponent } from './components/trainings/list-students/add-student/add-student.component';
import { StudentsSendComponent } from './components/certifications/students-send/students-send.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { UpdatePasswordComponent } from './components/authentication/update-password/update-password.component';
import { CrudAdminComponent } from './components/profile-management/crud-admin/crud-admin.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DatePipe, registerLocaleData } from '@angular/common';
import localePy from '@angular/common/locales/es';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PercentageComponent } from './components/layout/percentage/percentage.component';
import { ErrorReportingComponent } from './components/error-reporting/error-reporting.component';
import { ReCaptchaModule } from 'angular-recaptcha3';
import { environment } from '../environments/environment';
import { MonthPickerComponent } from './components/layout/month-picker/month-picker.component';
import { ListBuyersComponent } from './components/trainings/list-buyers/list-buyers.component';
import { EditBuyersComponent } from './components/trainings/edit-buyers/edit-buyers.component';
registerLocaleData(localePy, 'es');
@NgModule({
  declarations: [
    AppComponent,
    CategoriesComponent,
    TrainersComponent,
    CalendarComponent,
    TrainingsComponent,
    CertificationsComponent,
    ReportsComponent,
    NotificationsComponent,
    ProfileManagementComponent,
    RegisterUserComponent,
    LoginUserComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EditTrainingsComponent,
    ListStudentsComponent,
    CrudTrainersComponent,
    NotFountComponent,
    ServerErrorComponent,
    HeaderComponent,
    HomeComponent,
    OrderByPipe,
    OrderByDatePipe,
    PaginationComponent,
    AuthErrorComponent,
    AddStudentComponent,
    StudentsSendComponent,
    UpdatePasswordComponent,
    CrudAdminComponent,
    PercentageComponent,
    ErrorReportingComponent,
    MonthPickerComponent,
    ListBuyersComponent,
    EditBuyersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    NgSelectModule,
    HttpClientModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    PopoverModule,
    TooltipModule.forRoot(),
    ProgressbarModule.forRoot(),
    PdfViewerModule,
    AngularEditorModule,
    BrowserAnimationsModule,
    ReCaptchaModule.forRoot({
      invisible: {
          sitekey: environment.CAPTCHA_KEY_SECRET,
      },
      normal: {
          sitekey: environment.CAPTCHA_KEY_SECRET,
      },
      language: 'es'
    }),
  ],
  providers: [PopoverConfig, DatePipe],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})

export class AppModule { }


