import { flatten } from '@angular/compiler';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';
import jwt_decode from 'jwt-decode';
import { StudentModel } from 'src/app/models/student';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GeographyService } from 'src/app/services/geography.service';
import { CityModel, CountryModel, ProvinceModel } from 'src/app/models/geography';


@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss']
})
export class AddStudentComponent implements OnInit {
  
  constructor(private geo: GeographyService, private fb: FormBuilder, private modalService: BsModalService, private route: Router, private core: CoreService, private routes: ActivatedRoute) { }

  pdfSource =  '../../../../../assets/pdf/TérminosdeUsoBARBIERIYCONSULSTEEL.pdf';
  isValid = false;
  isMax = false;
  loading: boolean = true;
  formId: string;
  capacitationId: string;
  maxForms: number = 0;
  currentForm: number = 0;
  modalRef: BsModalRef;
  description: string;
  linkedin: string;
  position: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  carac: string;
  photo: string;
  codeCountry: string;

  coutries: CountryModel[];
  provinces: ProvinceModel[];
  city: CityModel[];
  capacitationName: string;
  isEnableGeo: boolean = true;
  isNotCountryValid: boolean = false;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-xl' })
    );
  }

  ngOnInit(): void {
    this.studentForm.value.genre = 'N/A';
    let token = this.routes.snapshot.paramMap.get("token");
    this.core.isValid(token).subscribe((data: boolean) => {
      this.isValid = data;
      if (data) {
        let decoded: any = jwt_decode(token);
        this.capacitationId = decoded.capacitationId;
        this.formId = decoded.formId;
        this.maxForms = decoded.max;
        this.AllCountries();
        this.getCapacitationNameById(this.capacitationId);
        this.core.currentForm(this.formId).subscribe((data: number) => {
          this.currentForm = data;
          if(this.currentForm == this.maxForms){
            Swal.fire({  
              icon: 'success',  
              title: 'Formulario de alumnos',  
              text: 'Ha completado el cupo de alumnos.',
              showConfirmButton: false
            })  
          } 
        }, (error: any) => {
          if (error.error.code = 10) {
            this.isMax = true;
            this.isValid = false;
          }
        });
      } else {
        console.log('NotValid Token');
      }
      this.loading = false;
    });
  }

  goToStudents() {
    this.route.navigateByUrl('/capacitaciones/alumnos/2')
  }

  AllCountries(){
    this.geo.GetCountries().subscribe((data: any ) => {
      console.log(data);
      this.coutries = data;
      console.log(this.coutries);
    });
  }
  AllProvinces(event: any){
    //9 => Argentina
    if(event.id == 9){
      this.isNotCountryValid = false;
      this.isEnableGeo = false;
      this.geo.GetProvinces(event.id).subscribe((data: any ) => {
        console.log(data);
        this.provinces = data;
      });
    }else{
      this.isNotCountryValid = true;
    }
  }
  AllCities(event: any){
    this.geo.GetCities(event.id).subscribe((data: any ) => {
      console.log(data);
      this.city = data;
    });
  }

  getCapacitationNameById(id){
    this.core.getNameCapacitation(id).subscribe((data:any) => {
      this.capacitationName = data.data;
    });
  }

  addStudent() {
    this.loading = true;
    let student: StudentModel = new StudentModel();
    student.email = this.studentForm.value.email;
    student.firstName = this.studentForm.value.firstName;
    student.lastName = this.studentForm.value.lastName;

    this.studentForm.value.codeCountry== undefined ? '': this.studentForm.value.codeCountry


    student.phone = `+${this.studentForm.value.codeCountry== undefined ? '': this.studentForm.value.codeCountry} ${this.studentForm.value.carac== undefined ? '': this.studentForm.value.carac}-${this.studentForm.value.phone== undefined ? '': this.studentForm.value.phone}`;
    student.DNI = this.studentForm.value.dni;
    student.city = this.studentForm.value.city;
    student.country = this.studentForm.value.country;
    student.state = this.studentForm.value.state;
    student.gender = this.studentForm.value.gender;
    if (this.studentForm.value.isMail == '' || this.studentForm.value.isMail == undefined) {
      student.isMails = false;
    } else {
      student.isMails = this.studentForm.value.isMail;
    }
    student.location = this.studentForm.value.position;
    student.CapacitationId =  parseInt(this.capacitationId);
    student.FormId = this.formId;
    student.MaxPeople = this.maxForms;
    if(this.currentForm == this.maxForms){
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: `Ha completado el cupo de alumnos`,
        showConfirmButton: false,
        timer: 8000
      });
    } else {
      this.core.addStudent(student).subscribe((data: any) => {
        this.loading = false;
      }, (error: any) => {
        this.studentForm.reset();
        this.loading = false;
        if (error.error.code == 10) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: `El formulario ya no es valido porque ya se ha completado el cupo`,
            showConfirmButton: false,
            timer: 5000
          });
        }
        if (error.error.code == 16) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: `El formulario ya no es valido porque ya se ha completado el cupo de la capacitación (Comuníquese con el soporte)`,
            showConfirmButton: false,
            timer: 5000
          });
        }
        if (error.error.code == 17) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: `El alumno con el DNI (${student.DNI}) ya se encuentra en esta capacitación`,
            showConfirmButton: false,
            timer: 5500
          });
        }
        console.error(error);
      }, () => {
        this.loading = false;
        this.studentForm.reset();
        this.currentForm++;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: `Enviado alumno ${this.currentForm} de ${this.maxForms}`,
          showConfirmButton: false,
          timer: 8000
        });
        if(this.currentForm == this.maxForms){
          Swal.fire({  
            icon: 'success',  
            title: 'Formulario de alumnos',  
            text: 'Ha completado el cupo de alumnos.',
            showConfirmButton: false
          })  
        } 
        window.scroll(0,0);
      });
    }
  }

  isMale: boolean = false;
  isFemale: boolean = false;
  isOther: boolean = false;
  isNot: boolean = true;

  changeGenre(id: number) {
    switch (id) {
      case 0:
        this.isMale = !this.isMale;
        this.isFemale = false;
        this.isOther = false;
        this.isNot = false;
        this.studentForm.value.genre = 'Male';
        break;
      case 1:
        this.isMale = false;
        this.isFemale = !this.isFemale;
        this.isOther = false;
        this.isNot = false;
        this.studentForm.value.genre = 'Female';
        break;
      case 2:
        this.isMale = false;
        this.isFemale = false;
        this.isOther = !this.isOther;
        this.isNot = false;
        this.studentForm.value.genre = 'Other';
        break;
      case 3:
        this.isMale = false;
        this.isFemale = false;
        this.isOther = false;
        this.isNot = !this.isNot;
        this.studentForm.value.genre = 'N/A';
        break;
    }
    console.log(this.studentForm);
  }

  numPattern = /^\d+$/;
  spacePettern = /^[^-\s][a-zA-Z0-9_\s-]+$/;
  studentForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
    position: ['', [Validators.required, Validators.maxLength(35)]],
    email: ['', [Validators.required, Validators.email]],
    dni: ['', [Validators.required, Validators.pattern(this.spacePettern)]],
    gender: [null, Validators.required],
    country: ['', Validators.required],
    state: ['', Validators.required],
    city: ['', Validators.required],
    carac: ['', [Validators.minLength(2), Validators.maxLength(5), Validators.pattern(this.numPattern)]],
    codeCountry: ['', [Validators.minLength(1), Validators.maxLength(3), Validators.pattern(this.numPattern)]],
    phone: ['', [Validators.minLength(5), Validators.maxLength(8), Validators.pattern(this.numPattern)]],
    isMail: [false],
    isTerms: [false, Validators.required]
  });
}
