<div class="container">
  <div class="row mt-2">
    <div *ngIf=!change class="col-lg-5 align-self-center text-left">
      <h6><span><i class="fas fa-exclamation-circle"></i></span>   Errores de Api_Elearning</h6>
    </div>
    <div *ngIf=change class="col-lg-5 align-self-center text-left">
      <h6><span><i class="fas fa-exclamation-circle"></i></span>   Errores de integración</h6>
    </div>
    
    <div class="col-lg-2 align-self-center text-left">
      <form *ngIf="change" >
        <ng-select class="custom-filter" (clear)="getErrorType(1)" (change)="getErrorType($event.id)" [items]="errorList" bindValue="id" bindLabel="name" placeholder="TotvsElearning"></ng-select>
      </form>
    </div>
    
    <div *ngIf="!change" class="col-lg-3 text-mobile-resposive">
      <button class="btn btn-add" (click)="changeState(1)"><span><i class="fas fa-exchange-alt"></i></span>    Errores de Integration</button>
    </div>
    <div *ngIf="change" class="col-lg-3 text-mobile-resposive">
      <button class="btn btn-add" (click)="changeState(2)"><span><i class="fas fa-exchange-alt"></i></span>    Errores de Api_Elearning</button>
    </div>

    <div class="col-lg-2 text-mobile-resposive">
      <button class="btn btn-add" (click)="getAllErrors()"><span><i class="fas fa-plus"></i></span>   Todos</button>
    </div>

    <div class="container">
          <table class="table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Class</th>
                <th>Method</th>
                <th>Message</th>
                <th>Stack Trace</th>
              </tr>
            </thead>
            
            <tbody>
              <tr *ngFor="let item of errors | paginate: {itemsPerPage: 6, currentPage: page}">
                <td>{{item.create.slice(5,10)+" "+item.create.slice(11,19)}}</td>
                <td>{{item.className}}</td>   
                <td>{{item.methodName}}</td>
                <td style=" min-width: 200px;">{{item.message}}</td>
                <td>{{item.stackTrace}}</td>
              </tr>
              <br>
            </tbody>
          </table>
    </div>
  </div>
  <div *ngIf="errors.length == 0" class="row justify-content-center mt-4 mb-4">
    <h3>No hay errores registrados</h3>
  </div>
  <div *ngIf="errors.length != 0 " class="row mt-4 justify-content-center">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
</div>