import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest, Subscription } from 'rxjs';
import { AdminModel, AdminUpdateModel } from 'src/app/models/admin';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss']
})
export class ProfileManagementComponent implements OnInit {

  constructor(private fb: FormBuilder, private route: Router, private modalService: BsModalService, private changeDetection: ChangeDetectorRef, private core: CoreService, private auth: AuthService) { }

  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  admins: AdminModel[];
  newAdmin: AdminModel;
  updateAdmin: AdminUpdateModel;

  ngOnInit(): void {
    this.getAll();
  }

  show: boolean = false;
  showInfo(item: any){
    item.show = !item.show;

  }

  loading: boolean = true;

  getAll() {
    this.loading = true;
    this.core.getAdmins().subscribe((data: any) => {
      console.log(data);
      this.admins = data.data;
      this.loading = false;
    }, (error: any) => {
      if (error.status = 401) {
        this.auth.refreshToken();
        this.getAll();
      } else if (error.status = 500) {
        this.route.navigateByUrl('/500');
      }
      console.error(error.status);
    });
  }

  showBackdrop = false;
  showButtons = false;

  showDeleteAdmin(){
    this.showBackdrop = !this.showBackdrop;
    this.showButtons = !this.showButtons;
  }


  add() {
    console.log('test');
  }

  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string | any) => {
        this.adminForm.value.firstName = '';
        this.adminForm.value.lastName = '';
        this.adminForm.value.phone = '';
        this.adminForm.value.carc = '';
        console.log(this.adminForm.value);
        this.unsubscribe();
      })
    );
    this.subscriptions.push(_combine);
    this.modalRef = this.modalService.show(template);

  }


  editAdmin(id: number){
    this.route.navigateByUrl(`administracion/editar/${id}`)
  }

  goTonewAdmin(){
    this.route.navigateByUrl(`administracion/nuevo`)
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }


  spacePettern = /^[^-\s][a-zA-Z0-9_\s-]+$/


  deleteAdmin(id: number, name: string, lastname: string){
    console.log(this.admins);
    console.log(id);
    Swal.fire({
      title: 'Eliminar Administrador',
      text: `¿Está seguro que desea eliminar el administrador ${name} ${lastname}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E3000B',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.core.deleteAdmin(id).subscribe((data: any) => {
          console.log(data);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'El administrador ha sido borrado correctamente',
            showConfirmButton: false,
            timer: 1500
          });
          this.admins = [];
          //this.textToSearch = '';
          this.getAll();
        }, (error: any) => {
          if (error.status = 401) {
            this.auth.refreshToken();
            this.deleteAdmin(id, name, lastname);
          } else if (error.status = 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'El administrador no ha sido borrado correctamente debido a un error interno',
              showConfirmButton: false,
              timer: 1500
            });
          }
          console.error(error.status);
        });

      }
    });
  }

  adminForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern(this.spacePettern)]],
    lastName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern(this.spacePettern)]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern(this.spacePettern)]],
    carc: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern(this.spacePettern)]],
  })
}
