import { PersonCreateModel, PersonModel, PersonUpdateModel } from "./person";

export class TrainerModel {
  id: number;
  position: string;
  description: string;
  photo?: string;
  linkedin: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  fullName: string;
}

export class TrainerUpdateModel {
  position: string;
  description: string;
  linkedin: string;
  photo: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

export class TrainerCreateModel {
  position: string;
  description: string;
  linkedin: string;
  photo: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string = "none";
}
