import { CapacitationModel } from "./capacitation";


export class StudentModel {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  DNI: string;
  gender: string = 'null';
  country: string;
  state: string;
  city: string;
  location: string;
  description: string;
  isMails: boolean;
  CapacitationId: number;
  FormId: string;
  MaxPeople: number;
}

export class StudentResponseModel {
  firstname: string;
  lastname: string;
  email: string;
  dni: string;
  createat: Date;
  capacitationName: string[];
}

export class StudentCapacitationModel {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dni: string;
  gender: string;
  capacitations?: CapacitationModel;
  country: string;
  state: string;
  city: string;
  location: string;
  opinion?: any;
  isMails: boolean;
  createdAt: Date;
}

export class StudentCertificationModel {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  dni: string;
  gender: string;
  country: string;
  state: string;
  city: string;
  location: string;
  attendances: number;
  // Uso Interno
  isChecked: boolean;
}
