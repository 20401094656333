import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private fb: FormBuilder, private auth: AuthService) { }

  ngOnInit(): void {
  }

  reset(){
    this.auth.resetPassword(this.loginForm.value.email).subscribe((data: any) => {
      Swal.fire(
        'Correo enviado!',
        'Revise su casilla de correo electrónico (recuerde revisar correos no deseados)',
        'success'
      )
    }, (error: any) => {
      if (error.error.status == 500) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error interno del servidor. Intente nuevamente mas tarde',
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'El correo ingresado no se encuentra registrado',
          showConfirmButton: false,
          timer: 1500
        });
      }
    });
  }

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  })

}
