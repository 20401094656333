<nav *ngIf="isNewUser" class="navbar">
  <a class="navbar-brand" routerLink="/ingresar">
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<app-header *ngIf="!isNewUser"></app-header>
<div class="container animate__animated animate__fadeIn animate__faster" [ngClass]="{'margen-nav': !isNewUser}" style="padding-top: 15px;">
  <div class="row justify-content-center">
    <div *ngIf="isNewUser" class="card" >
      <div class="card-body">
        <div class="container-fluid">
          <div class="row justify-content-center mt-4">
            <h3 class="text-center font-StagSans" style="font-size: 25px;">¡Hola {{firstName}}! Bienvenido a nuestra plataforma</h3>
          </div>
          <div class="row justify-content-center mt-4">
            <p class="explication text-center">
              Te pedimos que ingreses una nueva contraseña para cambiar la que te dimos por defecto.
            </p>
          </div>
          <div class="row justify-content-center mt-3 text-center">
            <form [formGroup]="loginForm" (ngSubmit)="update(true)" style="width: 90%;">
              <div class="form-group">
                <input type="password" minlength="6" formControlName="password" required name="password" ngModel class="form-control input-login" placeholder="Nueva contraseña" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.password.errors?.required">
                  La nueva contraseña es requerida.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.password.errors?.minlength">
                  La nueva contraseña debe contener más de 6 caracteres.
                </div>
              </div>
              <div class="form-group">
                <input type="password" formControlName="confirmPassword" required name="confirmPassword" ngModel class="form-control input-login" placeholder="Confirmá la nueva contraseña" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.confirmPassword.invalid && (loginForm.controls.confirmPassword.dirty || loginForm.controls.confirmPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.confirmPassword.errors?.required">
                  Debe confirmar su contraseña.
                </div>
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.confirmPassword.invalid && (loginForm.controls.confirmPassword.dirty || loginForm.controls.confirmPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.confirmPassword.errors?.NoPassswordMatch">
                  Las contraseñas no coinciden.
                </div>
              </div>
              <button type="submit" [disabled]="!loginForm.valid" class="btn-login btn">Guardar e Ingresar</button>
            </form>
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="!isNewUser" class="card" >
      <div class="card-body">
        <div class="container-fluid">
          <div class="row justify-content-center mt-4">
            <h3 class="text-center font-StagSans" style="font-size: 25px;">Cambio de contraseña</h3>
          </div>
          <div class="row justify-content-center mt-3 text-center">
            <form [formGroup]="passwordForm" (ngSubmit)="update(false)" style="width: 90%;">
              <div class="form-group">
                <input type="password" formControlName="currentPassword" required name="currentPassword" ngModel class="form-control input-login" placeholder="Contraseña actual" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="passwordForm.controls.currentPassword.invalid && (passwordForm.controls.currentPassword.dirty || passwordForm.controls.currentPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="passwordForm.controls.currentPassword.errors?.required">
                  La contraseña actual es requerida.
                </div>
              </div>
              <div class="form-group">
                <input type="password" minlength="6" formControlName="password" required name="password" ngModel class="form-control input-login" placeholder="Nueva contraseña" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="passwordForm.controls.password.invalid && (passwordForm.controls.password.dirty || passwordForm.controls.password.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="passwordForm.controls.password.errors?.required">
                  La nueva contraseña es requerida.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="passwordForm.controls.password.errors?.minlength">
                  La nueva contraseña debe contener más de 6 caracteres.
                </div>
              </div>
              <div class="form-group">
                <input type="password" formControlName="confirmPassword" required name="confirmPassword" ngModel class="form-control input-login" placeholder="Confirmá la nueva contraseña" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="passwordForm.controls.confirmPassword.invalid && (passwordForm.controls.confirmPassword.dirty || passwordForm.controls.confirmPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="passwordForm.controls.confirmPassword.errors?.required">
                  Debe confirmar su contraseña.
                </div>
              </div>
              <div class="mb-3" *ngIf="passwordForm.controls.confirmPassword.invalid && (passwordForm.controls.confirmPassword.dirty || passwordForm.controls.confirmPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="passwordForm.controls.confirmPassword.errors?.NoPassswordMatch">
                  Las contraseñas no coinciden.
                </div>
              </div>
              <button type="submit" [disabled]="!passwordForm.valid" class="btn-login btn">Restablecer</button>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
