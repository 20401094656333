<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
    <h2>Editar Capacitación</h2>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="!loading" class="row mt-4">
    <h3>Datos de la Capacitación</h3>
  </div>
  <div *ngIf="!loading" class="row">
    <div class="line"></div>
  </div>
  <div *ngIf="!loading" class="row mt-4 mb-3 justify-content-center">
    <form (submit)="editCapacitation()" style="width: 80%;" [formGroup]="trainingForm">
      <div class="form-row">
          <div class="form-group col-lg-12">
            <label for="name">Nombre de la Capacitación*</label>
            <input [(ngModel)]="name" type="text" formControlName="name" class="form-control" id="name">
            <div *ngIf="trainingForm.controls.name.invalid && (trainingForm.controls.name.dirty || trainingForm.controls.name.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.name.errors?.required">
                El nombre es requerido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.name.errors?.minlength">
                El nombre debe contener mas de 2 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.name.errors?.maxlength">
                El nombre debe contener menos de 25 caracteres.
              </div>
            </div>
          </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label for="description">Descripción* <span style="font-size: 13px;" tooltip="Descripción completa del curso, objetivos, requisitos, información extra."><i class="far fa-question-circle"></i></span></label>
          <angular-editor [(ngModel)]="description" style="height: 70px;" placeholder="Descripción completa del curso" formControlName="description" [config]="editorConfig"></angular-editor>
          <div *ngIf="trainingForm.controls.description.invalid && (trainingForm.controls.description.dirty || trainingForm.controls.description.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.description.errors?.required">
              La descripción es requerida.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <label for="temary">Contenido* <span style="font-size: 13px;" tooltip="Temas a dictar en la capacitación"><i class="far fa-question-circle"></i></span></label>
          <angular-editor [(ngModel)]="temary" style="height: 70px;" placeholder="Temario de la capacitación" formControlName="temary" [config]="editorConfig"></angular-editor>
          <div *ngIf="trainingForm.controls.temary.invalid && (trainingForm.controls.temary.dirty || trainingForm.controls.temary.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.temary.errors?.required">
              El Contenido es requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="categorie">Categorías del Curso* <span style="font-size: 13px;" tooltip="Puede seleccionar múltiples categorías."><i class="far fa-question-circle"></i></span></label>
          <ng-select [items]="categories"
                  bindLabel="name"
                  formControlName="categorie"
                  bindValue="id"
                  class="custom"
                  placeholder="Seleccionar"
                  [multiple]="true">
          </ng-select>
          <div *ngIf="trainingForm.controls.categorie.invalid && (trainingForm.controls.categorie.dirty || trainingForm.controls.categorie.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.categorie.errors?.required">
              Debe seleccionar al menos una categoría.
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="trainer">Capacitadores* <span style="font-size: 13px;" tooltip="Puede seleccionar múltiples capacitadores."><i class="far fa-question-circle"></i></span></label>
          <ng-select [items]="trainers"
                  bindLabel="fullName"
                  formControlName="trainer"
                  bindValue="id"
                  class="custom"
                  placeholder="Seleccionar"
                  [multiple]="true"
                  [searchable]="true">
          </ng-select>
          <div *ngIf="trainingForm.controls.trainer.invalid && (trainingForm.controls.trainer.dirty || trainingForm.controls.trainer.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.trainer.errors?.required">
              Debe seleccionar al menos un capacitador.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="coverImage">Foto de Portada</label>
          <input #fileInput formControlName="coverImage" type="file" class="custom-file-input" (change)="filechange2(fileInput.files)" accept="image/x-png,image/jpeg,image/jpg" id="coverImage" lang="es">
          <label class="custom-file-label" for="coverImage" data-browse="Seleccionar">{{filename2}}</label>
          <small id="emailHelp" class="form-text text-muted mt-4 ml-4">Formato .JPG, .PNG. Peso máximo de 2MB. </small>
          <div *ngIf="trainingForm.controls.coverImage.invalid && (trainingForm.controls.coverImage.dirty || trainingForm.controls.coverImage.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.coverImage.errors?.required">
              La foto de portada es requerida.
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="thumbnail">Foto de Miniatura</label>
          <input #fileInput2 formControlName="thumbnail" type="file" class="custom-file-input" (change)="filechange(fileInput2.files)" accept="image/x-png,image/jpeg,image/jpg" id="thumbnail" lang="es">
          <label class="custom-file-label" for="validatedCustomFile" data-browse="Seleccionar">{{filename}}</label>
          <small id="emailHelp" class="form-text text-muted mt-4 ml-4">Formato .JPG, .PNG. Peso máximo de 2MB. </small>
          <div *ngIf="trainingForm.controls.thumbnail.invalid && (trainingForm.controls.thumbnail.dirty || trainingForm.controls.thumbnail.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.thumbnail.errors?.required">
              La foto de miniatura es requerida.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="startDate">Inicio del curso*</label>
          <input [(ngModel)]="startDate" (ngModelChange)="checkDates($event)" [min]="minStartDate" formControlName="startDate" type="date" class="form-control" id="startDate">
          <div *ngIf="trainingForm.controls.startDate.invalid && (trainingForm.controls.startDate.dirty || trainingForm.controls.startDate.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.startDate.errors?.required">
              Debe ingresar una fecha de inicio para el curso.
            </div>
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="starDateError">
            La fecha debe ser mayor o igual a la fecha actual
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="endDate">Finalización del curso*</label>
          <input [min]="startDate" [(ngModel)]="endDate" (ngModelChange)="checkDates($event, false)" formControlName="endDate" type="date" class="form-control" id="endDate">
          <div *ngIf="trainingForm.controls.endDate.invalid && (trainingForm.controls.endDate.dirty || trainingForm.controls.endDate.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.endDate.errors?.required">
              Debe ingresar una fecha de finalización para el curso.
            </div>
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="endDateError">
            La fecha debe ser mayor o igual a la fecha de inicio
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="startHour">Horario de Inicio del curso*</label>
          <input (ngModelChange)="checkHours($event)" [(ngModel)]="startHours" formControlName="startHour" type="time" class="form-control" id="startHour">
          <div *ngIf="trainingForm.controls.startHour.invalid && (trainingForm.controls.startHour.dirty || trainingForm.controls.startHour.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.startHour.errors?.required">
              Debe ingresar una hora de inicio para el curso.
            </div>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="endHour">Horario de Finalización del curso*</label>
          <input (ngModelChange)="checkHours($event, false)" [(ngModel)]="endHours" formControlName="endHour" type="time" class="form-control" id="endHour">
          <div *ngIf="trainingForm.controls.endHour.invalid && (trainingForm.controls.endHour.dirty || trainingForm.controls.endHour.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.endHour.errors?.required">
              Debe ingresar una hora de finalización para el curso.
            </div>
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="endHourError">
            La hora de finalización debe ser mayor a la hora de inicio
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="quantity">Cantidad de Plazas*</label>
          <input [min]="0" (ngModelChange)="changeVisibilityPlaza($event)" [(ngModel)]="quantity" formControlName="quantity" onkeypress="return event.charCode >= 48" type="number" min="0" class="form-control" id="quantity">
          <div *ngIf="trainingForm.controls.quantity.invalid && (trainingForm.controls.quantity.dirty || trainingForm.controls.quantity.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.quantity.errors?.required">
              La cantidad es requerida.
            </div>
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="minPlazaError">
            La cantidad debe ser mayor a 1 (Es visible en Magento).
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="precio">Precio del Curso*</label>
          <input min="0" step=".01" [(ngModel)]="price" formControlName="price" onkeypress="return event.charCode >= 48" type="number" class="form-control" id="precio">
          <div *ngIf="trainingForm.controls.price.invalid && (trainingForm.controls.price.dirty || trainingForm.controls.price.touched)">
            <div class="text-danger" style="font-size: 12px;" *ngIf="trainingForm.controls.price.errors?.required">
              El precio es requerido.
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="place">Lugar*</label>
          <input [(ngModel)]="place" formControlName="place" type="text" class="form-control" id="place">
        </div>
        <div class="form-group col-lg-6">
          <label for="materiallink">Link de Materiales del curso (Opcional)</label>
          <input [(ngModel)]="materiallink" type="url" formControlName="materiallink" class="form-control" id="materiallink">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="magentoVisibility">Visibilidad en Magento</label>
          <div class="custom-control custom-switch mt-3 ml-1">
            <input type="checkbox" (ngModelChange)="changeVisibility($event)" [ngModelOptions]="{standalone: true}" class="custom-control-input" id="magentoVisibility" formControlName="visibility">
            <label *ngIf="trainingForm.controls.visibility.value" style="cursor: pointer;" class="custom-control-label" for="magentoVisibility">Visible</label>
            <label *ngIf="!trainingForm.controls.visibility.value" style="cursor: pointer;" class="custom-control-label" for="magentoVisibility">No Visible</label>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="certification">Certificado</label>
          <div class="custom-control custom-switch mt-3 ml-1">
            <input type="checkbox" [ngModelOptions]="{standalone: true}" class="custom-control-input" id="certification" formControlName="certification">
            <label *ngIf="trainingForm.controls.certification.value" style="cursor: pointer;" class="custom-control-label" for="certification">Con Certificado</label>
            <label *ngIf="!trainingForm.controls.certification.value" style="cursor: pointer;" class="custom-control-label" for="certification">Sin Certificado</label>
          </div>
        </div>
      </div>


      <div class="form-row mt-5 mb-3 justify-content-center text-center">
        <div class="form-group col-lg-6">
          <button class="btn btn-back" (click)="goToTrainings()">Atrás</button>
        </div>
        <div class="form-group col-lg-6">
          <button *ngIf="isEdit && !saving" class="btn btn-save" [disabled]="saving || !trainingForm.valid || endDateError || starDateError || endHourError || minPlazaError" type="submit">Guardar</button>
          <button *ngIf="!isEdit && !saving" class="btn btn-save" [disabled]="saving || !trainingForm.valid || endDateError || starDateError || endHourError || minPlazaError" type="submit">Duplicas</button>
          <button *ngIf="saving" class="btn btn-save" [disabled]="saving" type="submit">
            <i class="fas fa-spinner fa-pulse"></i>
          </button>
          <div class="text-danger text-center" style="font-size: 12px;" *ngIf="!trainingForm.valid || endDateError || starDateError || endHourError || minPlazaError">
            Faltan campos por completar o existen errores
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
