<nav class="navbar">
  <a class="navbar-brand" routerLink="/ingresar">
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<div class="container animate__animated animate__fadeIn animate__faster" style="padding-top: 15px;">
  <div class="row justify-content-center">
    <div class="card" >
      <div class="card-body">
        <div class="container-fluid">
          <div class="row justify-content-center mt-4">
            <h3 class="text-center font-StagSans" style="font-size: 25px;">Ok, ya casi terminamos</h3>
          </div>
          <div class="row justify-content-center mt-4">
            <p class="explication">
              Escribí tu nueva contraseña.
            </p>
          </div>
          <div class="row justify-content-center mt-5 text-center">
            <form [formGroup]="loginForm" (ngSubmit)="update()" style="width: 90%;">
              <div class="form-group">
                <input type="password" formControlName="password" required name="password" ngModel class="form-control input-login" placeholder="Nueva contraseña" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.password.errors?.required">
                  La nueva contraseña es requerida.
                </div>
              </div>
              <div class="form-group">
                <input type="password" formControlName="confirmPassword" required name="confirmPassword" ngModel class="form-control input-login" placeholder="Confirmá la nueva contraseña" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.confirmPassword.invalid && (loginForm.controls.confirmPassword.dirty || loginForm.controls.confirmPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.confirmPassword.errors?.required">
                  Debe confirmar su contraseña.
                </div>
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.confirmPassword.invalid && (loginForm.controls.confirmPassword.dirty || loginForm.controls.confirmPassword.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.confirmPassword.errors?.NoPassswordMatch">
                  Las contraseñas no coinciden.
                </div>
              </div>
              <button type="submit" [disabled]="!loginForm.valid" class="btn-login btn">Restablecer</button>
            </form>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
