import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss']
})
export class ServerErrorComponent implements OnInit {

  constructor(private _location: Location, private auth: AuthService, private route: Router) { }

  ngOnInit(): void {
  }

  backClicked() {
    this._location.back();
  }

  closeSesion(){
    this.auth.logout();
    window.location.reload();
  }

}
