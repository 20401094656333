import { DatePipe } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  endOfWeek,
  startOfWeek,
  startOfMonth,
} from 'date-fns';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CapacitationModel } from 'src/app/models/capacitation';
import { TrainerModel } from 'src/app/models/trainer';
import { CoreService } from 'src/app/services/core.service';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/services/storage.service';
import html2canvas from "html2canvas";

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

  viewDate: Date = new Date();
  events: CalendarEvent[];
  locale;
  weeks = ['Dom', 'Lun', 'Mar', 'Mier', "Jue", "Vie", "Sab"];
  view: CalendarView;

  modalRef: BsModalRef;
  capacitation: CapacitationModel;
  isAdmin: boolean;
  constructor(private storage: StorageService, private datepipe: DatePipe, @Inject(LOCALE_ID) locale: string, private core: CoreService, private modalService: BsModalService, private router: Router, private dom: DomSanitizer) {
    this.locale = 'es';
    this.view  = CalendarView.Month;
  }

  loadingFilters: boolean;
  loading = true;

  ngOnInit(): void {
    let decoded: any = jwt_decode(this.storage.getToken());
    if (decoded.role == 'Administrador') {
      this.isAdmin = true;
      this.getTrainers();
      this.getAllCapacitations();
    } else {
      this.isAdmin = false;
      this.filterTrainersLoget(parseInt(decoded.Id));
    }
  }

  capacitations: CapacitationModel[];
  capacitationsFilter: CapacitationModel[];
  trainers: TrainerModel[];
  getAllCapacitations() {
    this.capacitations = [];
    this.capacitationsFilter = [];
    this.loadingFilters = true;
    this.loading = true;
    this.core.allCapacitations().subscribe((data: any) => {
      this.capacitations = data.data;
      this.capacitationsFilter = data.data;
    }, error => {
      console.log(error);
    }, () => {
      this.loadingFilters = false;
      this.chargeData();
    });
  }

  getTrainers() {
    this.trainers = [];
    this.loadingFilters = true;
    this.core.getTrainers().subscribe((data: any) => {
      this.trainers = data.data;
    }, error => {
      console.log(error);
    }, () => {
      this.loadingFilters = false;
    });
  }

  filterCapacitation(event) {
    this.loading = true;
    if (event.target.value !== 'all') {
      this.core.searchCapacitations(event.target.value).subscribe((data: any) => {
        this.capacitations = [];
        this.capacitations = data.data;
      }, error => {
        console.log(error);
      }, () => {
        this.loadingFilters = false;
        this.chargeData();
      });
    } else {
      this.core.allCapacitations().subscribe((data: any) => {
        this.capacitations = [];
        this.capacitations = data.data;
      }, error => {
        console.log(error);
      }, () => {
        this.loadingFilters = false;
        this.chargeData();
      });
    }
    const DropdownList = (document.getElementById('trainers')) as HTMLSelectElement;
    DropdownList.selectedIndex = 0;
  }

  filterTrainers(event) {
    this.loading = true;
    if (event.target.value !== 'all') {
      let capacitations1: CapacitationModel[] = [];
      this.core.allCapacitations().subscribe((data: any) => {
        this.capacitations = [];
        this.capacitations = data.data;
      }, error => {
        console.log(error);
      }, () => {
        this.loadingFilters = false;
        this.capacitations.forEach(element => {
          element.userTrainer.forEach(trainer => {
            if (trainer.id === parseInt(event.target.value)) {
              capacitations1.push(element);
            }
          });
        });
        this.capacitations = [];
        this.capacitations = capacitations1;
        this.chargeData();
      });
    } else {
      this.core.allCapacitations().subscribe((data: any) => {
        this.capacitations = [];
        this.capacitations = data.data;
      }, error => {
        console.log(error);
      }, () => {
        this.loadingFilters = false;
        this.chargeData();
      });
    }
    const DropdownList = (document.getElementById('capacitations')) as HTMLSelectElement;
    DropdownList.selectedIndex = 0;
  }

  activeDayIsOpen: boolean = false;
  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  filterTrainersLoget(id: number) {
    this.loading = true;
    let capacitations1: CapacitationModel[] = [];
    this.core.allCapacitations().subscribe((data: any) => {
      this.capacitations = data.data;
    }, error => {
      console.log(error);
    }, () => {
      this.capacitations.forEach(element => {
        element.userTrainer.forEach(trainer => {
          if (trainer.id == id) {
            capacitations1.push(element);
          }
        });
      });
      this.capacitations = [];
      this.capacitations = capacitations1;
      this.chargeData();
    });

  }

  chargeData(){
    this.events = [];
    this.capacitations.forEach(element => {
      let event: CalendarEvent = {
        start: new Date(element.startDate),
        end: new Date(element.endDate),
        id: element.id,
        title: `${element.name} De ${this.datepipe.transform(element.startHours, 'HH:mm')} a ${this.datepipe.transform(element.endHours, 'HH:mm')}`,
        color: colors.red,
        allDay: false
      };
      this.events.push(event);
    });
    this.loading = false;
  }

  changeView(id: number) {
    switch (id) {
      case 0:
        this.view = CalendarView.Month;
        break;
      case 1:
        this.view = CalendarView.Week;
        break;
      case 2:
        this.view = CalendarView.Day;
        break;
    }
  }


  loadHtml(){
    return this.dom.bypassSecurityTrustHtml(this.capacitation.description);
  }

  eventClicked({ event }: { event: CalendarEvent }, template: TemplateRef<any>): void {
    // tslint:disable-next-line: radix
    this.core.showCapacitation(parseInt(event.id.toString())).subscribe((data: any) => {
      this.capacitation = data.data;
      this.modalRef = this.modalService.show(template,
        Object.assign({}, { class: 'modal-xl' })
      );
    }, error => {
      console.log(error);
      if (error.status == 401) {
        //this.auth.refreshToken();
      } else if (error.status == 500) {
        this.router.navigateByUrl('/500');
      }
    });
  }

  imagenCreada: any;
  crearImagen() {
    html2canvas(document.querySelector("#contenido")).then(canvas => {

      this.imagenCreada = canvas.toDataURL();
      this.downloadImage(this.imagenCreada, `Calendario_${new Date().toLocaleString()}.png`);
    });
  }

  downloadImage(data, filename = 'untitled.jpeg') {
    var a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
}
}
