<nav class="navbar">
  <a class="navbar-brand">
    <img src="../../../../../assets/Barbieri-logo-2019-blanco.png" height="70" alt="">
  </a>
</nav>
<div *ngIf="isValid" class="img">

</div>
<progressbar *ngIf="isValid" style="width: 100%;" [max]="maxForms" [value]="currentForm" type="danger" [striped]="false" [animate]="true"><i>Formulario {{currentForm}} / {{maxForms}}</i></progressbar>

<div *ngIf="loading" class="d-flex justify-content-center">
  <div class="spinner-border text-danger" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div [ngClass]="{'readonly' : loading}">
  <div *ngIf="isValid else notValid" class="container font-Mada animate__animated animate__fadeIn animate__faster" style="a">
    <div class="row mt-3 justify-content-start">
      <h2 class="font-Mada" style="font-weight: bold;">FORMULARIO DE ALUMNOS  </h2>
      <h2 style="margin-left:12px;"> {{capacitationName}}</h2>
    </div>
    <div class="row justify-content-center mt-4 mb-5">
      <form (ngSubmit)="addStudent()" [formGroup]="studentForm" style="width: 80%;">
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="name">Nombre*</label>
            <input type="text" name="name" ngModel required minlength="2" maxlength="25" formControlName="firstName" class="form-control" id="name"  [readonly]="currentForm==maxForms"
              placeholder="ej: Mirian">
              <div *ngIf="studentForm.controls.firstName.invalid && (studentForm.controls.firstName.dirty || studentForm.controls.firstName.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.firstName.errors?.required">
                  El nombre es requerido.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.firstName.errors?.minlength">
                  El nombre debe contener mas de 2 caracteres.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.firstName.errors?.maxlength">
                  El nombre debe contener menos de 25 caracteres.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.firstName.errors?.pattern">
                  El nombre tiene un formato no admitido.
                </div>
              </div>
          </div>
          <div class="form-group col-lg-6">
            <label for="lastname">Apellido*</label>
            <input type="text" name="lastname" ngModel required minlength="2" maxlength="25" formControlName="lastName" class="form-control" id="lastname" [readonly]="currentForm==maxForms"
              placeholder="ej: Gonzales">
              <div *ngIf="studentForm.controls.lastName.invalid && (studentForm.controls.lastName.dirty || studentForm.controls.lastName.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.lastName.errors?.required">
                  El apellido es requerido.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.lastName.errors?.minlength">
                  El apellido debe contener mas de 2 caracteres.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.lastName.errors?.maxlength">
                  El apellido debe contener menos de 25 caracteres.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.lastName.errors?.pattern">
                  El apellido tiene un formato no admitido.
                </div>
              </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="cargo">DNI*</label>
            <input [pattern]="spacePettern" type="text" name="dni" formControlName="dni" ngModel maxlength="14" class="form-control" id="cargo" [readonly]="currentForm==maxForms"
              placeholder="ej: 1111111">
              <div *ngIf="studentForm.controls.dni.invalid && (studentForm.controls.dni.dirty || studentForm.controls.dni.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.dni.errors?.required">
                  El DNI es requerido.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.dni.errors?.pattern">
                  El DNI tiene un formato no admitido.
                </div>
              </div>
          </div>
          <div class="form-group col-lg-6">
            <label for="email">Correo electrónico*</label>
            <input type="email" class="form-control" required ngModel formControlName="email" name="email" id="email" [readonly]="currentForm==maxForms"
              placeholder="ej: email@email.com">
              <div *ngIf="studentForm.controls.email.invalid && (studentForm.controls.email.dirty || studentForm.controls.email.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.email.errors?.required">
                  El email es requerido.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.email.errors?.email">
                  El email tiene un formato no admitido.
                </div>
              </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="country">País*</label>
            <ng-select name="country" [readonly]="currentForm==maxForms" required formControlName="country" (change)="AllProvinces($event)" ngModel [items]="coutries" bindValue="countryName" bindLabel="countryName" placeholder="Selecciona un país" class="custom-geo"></ng-select>
            <div *ngIf="studentForm.controls.country.invalid && (studentForm.controls.country.dirty || studentForm.controls.country.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.country.errors?.required">
                El País es requerido.
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6">
            <label for="state">Provincia/Estado*</label>
            <ng-select *ngIf="!isNotCountryValid" [readonly]="currentForm==maxForms" required [disable]="isEnableGeo" name="state" formControlName="state" (change)="AllCities($event)" ngModel notFoundText="No se han encontrado elementos" [items]="provinces" bindValue="name" bindLabel="name" placeholder="Selecciona una Provincia/Estado" class="custom-geo"></ng-select>
            <input *ngIf="isNotCountryValid" required type="text" name="state" formControlName="state" ngModel maxlength="24" class="form-control" id="state">
            <div *ngIf="studentForm.controls.state.invalid && (studentForm.controls.state.dirty || studentForm.controls.state.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.state.errors?.required">
                La/El Provincia/Estado es requerida/o.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label  for="city">Ciudad de residencia*</label>
            <ng-select *ngIf="!isNotCountryValid" required [disable]="isEnableGeo" name="city" formControlName="city" ngModel  notFoundText="No se han encontrado elementos" [items]="city" bindValue="name" bindLabel="name" placeholder="Selecciona una Ciudad" class="custom-geo"></ng-select>
            <input [readonly]="currentForm==maxForms" *ngIf="isNotCountryValid" required type="text" name="city" formControlName="city" ngModel maxlength="24" class="form-control" id="city">
            <div *ngIf="studentForm.controls.city.invalid && (studentForm.controls.city.dirty || studentForm.controls.city.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.city.errors?.required">
                La Ciudad de residencia es requerida.
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6">
            <label  for="gender">Género*</label>
            <select [readonly]="currentForm==maxForms" name="gender" formControlName="gender" ngModel class="custom-select form-control form-control-2">
              <option value="F">Femenino</option>
              <option value="M">Masculino</option>
              <option value="O">Otros</option>
              <option value="PND">Prefiero no decirlo</option>
            </select>
            <div *ngIf="studentForm.controls.gender.invalid && (studentForm.controls.gender.dirty || studentForm.controls.gender.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.gender.errors?.required">
                El género es requerido.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="cargo">Ocupación*</label>
            <select name="position" [readonly]="currentForm==maxForms" required formControlName="position" id="cargo" ngModel class="custom-select form-control form-control-2">
              <option value="Arquitecto">Arquitecto</option>
              <option value="Ingeniero">Ingeniero</option>
              <option value="Maestro Mayor de Obras">Maestro Mayor de Obras</option>
              <option value="Vendedor">Vendedor</option>
              <option value="Constructor">Constructor</option>
              <option value="Hobbista">Hobbista</option>
              <option value="Estudiante">Estudiante</option>
              <option selected value="Otro">Otro</option>
            </select>
              <div *ngIf="studentForm.controls.position.invalid && (studentForm.controls.position.dirty || studentForm.controls.position.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.position.errors?.required">
                  La ocupación es requerida.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.position.errors?.maxlength">
                  La ocupación debe contener menos de 35 caracteres.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.position.errors?.pattern">
                  La ocupación tiene un formato no admitido.
                </div>
              </div>
          </div>
          <div class="form-group col-lg-6">
            <div class="form-row">
              <div class="form-group col-2">
                <label for="carc">Celular</label>
                <input [pattern]="numPattern" [readonly]="currentForm==maxForms" type="tel" [(ngModel)]="codeCountry" minlength="1" maxlength="3" class="form-control" formControlName="codeCountry" name="codeCountry" id="codeCountry"
                  placeholder="Cod. País">
              </div>
              <div class="form-group col-3">
                <input [pattern]="numPattern" [readonly]="currentForm==maxForms" type="tel" [(ngModel)]="carac" minlength="2" maxlength="5" class="form-control" formControlName="carac" name="carac" style="margin-top: 32px;" id="carc"
                  placeholder="Cod. Área">
              </div>
              <div class="form-group col-7">
                <input [pattern]="numPattern" [readonly]="currentForm==maxForms" type="tel" [(ngModel)]="phone" minlength="5" maxlength="8" class="form-control" formControlName="phone" name="phone" style="margin-top: 32px;"
                  id="phone" placeholder="N° de Celular">
              </div>

            </div>
            <div *ngIf="(studentForm.controls.carac.invalid || studentForm.controls.phone.invalid || studentForm.controls.codeCountry.invalid) && ((studentForm.controls.carac.dirty || studentForm.controls.codeCountry.dirty || studentForm.controls.phone.dirty) || (studentForm.controls.carac.touched || studentForm.controls.phone.touched || studentForm.controls.codeCountry.touched))">
              <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.phone.errors?.minlength || studentForm.controls.carac.errors?.minlength || studentForm.controls.codeCountry.errors?.minlength">
                El celular tiene un formato no admitido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="studentForm.controls.phone.errors?.pattern || studentForm.controls.carac.errors?.pattern || studentForm.controls.codeCountry.errors?.pattern">
                El celular tiene un formato no admitido (contiene letras).
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-9">
            <div class="container-fluid">
              <div class="row">
                <div class="form-group col">

                  <div class="form-check">
                    <input class="form-check-input " [readonly]="currentForm==maxForms" ngModel  type="checkbox" id="isMail" formControlName="isMail" value="true" aria-label="...">
                    <label for="isMail" style="margin-left: 9px; margin-top: 5px;">Quiero recibir notificaciones</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col">
                  <div class="form-check">
                    <input required class="form-check-input" [readonly]="currentForm==maxForms" ngModel  type="checkbox" id="isTerms" formControlName="isTerms" value="true" aria-label="...">
                    <label for="isTerms" style="margin-left: 9px; margin-top: 5px;">Acepto <span><a style="text-decoration: underline; cursor: pointer;" (click)="openModal(terms)">términos y condiciones</a></span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-3 text-center">
            <button class="btn btn-save" [disabled]="!studentForm.valid" type="submit">ENVIAR</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<ng-template #notValid>
  <div *ngIf="loading" class="container-fluid mt-5 animate__animated animate__bounceIn animate__faster">
    <div class="row justify-content-center" style="margin-top: 100px !important; margin-bottom: 100px;">
      <div class="spin"></div>
    </div>
  </div>
  <div *ngIf="!loading && !isMax" class="container-fluid mt-5 animate__animated animate__zoomIn animate__faster">
    <div class="row justify-content-center">
      <div class="card text-center">
        <div class="card-body">
          <i style="font-size: 70px;" class="far fa-surprise mb-3 notvalid"></i>
          <h2 class="card-title" style="font-weight: bold;">Ups! El formulario a expirado</h2>
          <p class="card-text">No han pasado más de 24hs? Comunicate con el <span style="font-weight: bold;"><a style="cursor: pointer;">Soporte</a></span></p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading && isMax" class="container-fluid mt-5 animate__animated animate__zoomIn animate__faster">
    <div class="row justify-content-center">
      <div style="height: 260px;" class="card text-center">
        <div class="card-body">
          <i style="font-size: 70px;" class="far fa-surprise mb-3 notvalid"></i>
          <h2 class="card-title" style="font-weight: bold;">Ups! El formulario a superado el limite de registros</h2>
          <p class="card-text">No has registrado mas de {{maxForms}} alumnos? Comunicate con el <span style="font-weight: bold;"><a style="cursor: pointer;">Soporte</a></span></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #terms>
    <div class="modal-content">
      <div style="z-index: 999999;" class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Términos y condiciones</h5>
        <button [readonly]="currentForm==maxForms" type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div (click)="modalRef.hide()" class="modal-body">
        <pdf-viewer [src]="pdfSource" [zoom]="0.8" [original-size]="false"></pdf-viewer>
      </div>

    </div>
</ng-template>
