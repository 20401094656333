<nav class="navbar">
  <a class="navbar-brand" >
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<div class="container animate__animated animate__bounceIn align-items-center minh-100">
  <div class="row justify-content-center align-items-center minh-100">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-auto">
          <h1 class="bomba"><i class="fas fa-bomb"></i></h1>
        </div>
        <div class="col-auto">
          <div class="container-fluid">
            <div class="row ">
              <div class="col-auto">
                <h1 class="text-404">404 /</h1>
              </div>
              <div class="col-auto">
                <p class="razon-error mt-4">No podemos encontrar la<br>página que solicitás</p>
              </div>
            </div>
            <div class="row margen text-center">
              <p>page not found</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-5">
        <p class="text-1">Seguí navegando</p>
      </div>
      <div class="row justify-content-center mt-2">
        <button (click)="goToHome()" class="btn btn-error">Volver a inicio</button>
      </div>
    </div>
  </div>

</div>
