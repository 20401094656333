
<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <!-- <bs-modal-backdrop class="modal-backdrop fade in show"></bs-modal-backdrop> -->
  <div class="row mt-4">
    <h2>Notificaciones</h2>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="!loading" class="row mt-4 justify-content-center animate__animated animate__fadeIn animate__faster">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto ">
                    <i class="fas fa-user-cog icons-types" tooltip="Se envían las Notificaciones a todos los Administradores"></i>
                  </div>
                  <div class="col-8 align-self-center">
                    <p class="font-types"><i class="fas fa-chevron-right"></i>&nbsp;&nbsp; Administrador</p>
                  </div>
                  <div class="col-auto">
                    <p style="font-size: 10px; color: #E3000B; font-weight: bold;" class="text-center hide-elements">Notificaciones<br>a enviar</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-check-circle check"></i>&nbsp;&nbsp; capacitación creada</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[0]" [ngModelOptions]="{standalone: true}" (click)="check(1)" class="custom-control-input" id="customSwitches">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-flag-checkered icon-black"></i>&nbsp;&nbsp; objetivo de inscriptos alcanzado</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[3]" [ngModelOptions]="{standalone: true}" (click)="check(4)" class="custom-control-input" id="customSwitches2">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches2"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="far fa-window-close icon-black"></i>&nbsp;&nbsp; la inscripción se cerró</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[6]" [ngModelOptions]="{standalone: true}" (click)="check(7)" class="custom-control-input" id="customSwitches3">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches3"></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 margin-phone">
          <div class="card">
            <div class="card-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto ">
                    <i class="fas fa-chalkboard-teacher icons-types" tooltip="Se envían las Notificaciones a todos los Capacitadores"></i>
                  </div>
                  <div class="col-8 align-self-center">
                    <p class="font-types"><i class="fas fa-chevron-right"></i>&nbsp;&nbsp; Capacitador</p>
                  </div>
                  <div class="col-auto">
                    <p style="font-size: 10px; color: #E3000B; font-weight: bold;" class="text-center hide-elements">Notificaciones<br>a enviar</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-check-circle check"></i>&nbsp;&nbsp; capacitación asignada</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[1]" [ngModelOptions]="{standalone: true}" (click)="check(2)" class="custom-control-input" id="customSwitches4">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches4"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-flag-checkered icon-black"></i>&nbsp;&nbsp; objetivo de inscriptos alcanzado</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[4]" [ngModelOptions]="{standalone: true}" (click)="check(5)" class="custom-control-input" id="customSwitches14">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches14"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="far fa-window-close icon-black"></i>&nbsp;&nbsp; la inscripción se cerró</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[7]" [ngModelOptions]="{standalone: true}" (click)="check(8)" class="custom-control-input" id="customSwitches6">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches6"></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto ">
                    <i class="fas fa-portrait icons-types" tooltip="Se envían las Notificaciones a todos los Alumnos"></i>
                  </div>
                  <div class="col-8 align-self-center">
                    <p class="font-types"><i class="fas fa-chevron-right"></i>&nbsp;&nbsp; Alumno</p>
                  </div>
                  <div class="col-auto" style="margin-left: 16px;">
                    <p style="font-size: 10px; color: #E3000B; font-weight: bold;" class="text-center hide-elements">Notificaciones<br>a enviar</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-check-circle check"></i>&nbsp;&nbsp; capacitación asignada</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[2]" [ngModelOptions]="{standalone: true}" (click)="check(3)" class="custom-control-input" id="customSwitches5">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches5"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <!-- <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-flag-checkered icon-black"></i>&nbsp;&nbsp; objetivo de inscriptos alcanzado</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[5]" [ngModelOptions]="{standalone: true}" (click)="check(6)" class="custom-control-input" id="customSwitches17">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches17"></label>
                      </div>
                    </form>
                  </div>
                </div> -->
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-sync-alt check"></i>&nbsp;&nbsp; cambios en la capacitación</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[8]" [ngModelOptions]="{standalone: true}" (click)="check(9)" class="custom-control-input" id="customSwitches8">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches8"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-bell icon-black"></i>&nbsp;&nbsp; recordatorio de participación</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[11]" [ngModelOptions]="{standalone: true}" (click)="check(13)" class="custom-control-input" id="customSwitches11">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches11"></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 margin-phone">
          <div class="card">
            <div class="card-body">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto ">
                    <i class="fas fa-user-tie icons-types" tooltip="Se envían las Notificaciones a todos los Comprador"></i>
                  </div>
                  <div class="col-8 align-self-center">
                    <p class="font-types"><i class="fas fa-chevron-right"></i>&nbsp;&nbsp; Comprador</p>
                  </div>
                  <div class="col-auto" style="margin-left: 13px;">
                    <p style="font-size: 10px; color: #E3000B; font-weight: bold;" class="text-center hide-elements">Notificaciones<br>a enviar</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-check-circle check"></i>&nbsp;&nbsp; registar alumnos</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[9]" [ngModelOptions]="{standalone: true}" (click)="check(10)" class="custom-control-input" id="customSwitches9">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches9"></label>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-10 align-self-center" style="margin-right: 8px;">
                    <p class="text-actions"><i class="fas fa-file-invoice-dollar icon-blue"></i>&nbsp;&nbsp; enviar resúmen de compra</p>
                  </div>
                  <div class="col-auto align-self-center">
                    <form >
                      <div class="custom-control custom-switch">
                        <input type="checkbox" [(ngModel)]="checks[10]" [ngModelOptions]="{standalone: true}" (click)="check(11)" class="custom-control-input" id="customSwitches10">
                        <label style="cursor: pointer;" class="custom-control-label" for="customSwitches10"></label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>


  </div>
  <div class="row justify-content-center mt-5 mb-5">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>
