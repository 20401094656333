import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StudentCapacitationModel } from 'src/app/models/student';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-list-students',
  templateUrl: './list-students.component.html',
  styleUrls: ['./list-students.component.scss']
})
export class ListStudentsComponent implements OnInit {

  page: number = 1;
  isAdmin: boolean;
  isEdit: boolean = false;

  constructor(private storage: StorageService, private modalService: BsModalService, private router: Router, private core: CoreService, private routes: ActivatedRoute, private auth: AuthService) { }

  filename: string;
  aux: string[];
  modalRef: BsModalRef;
  CapacitationId: number;
  notResult: boolean = false;
  students: StudentCapacitationModel[];
  allStudents: StudentCapacitationModel[];
  student: StudentCapacitationModel;
  searchText: string;
  loading: boolean = true;
  isLoading: boolean = false;
  order: string;
  newEmail: string;
  isEnable = false;
  isOrderByName = false;
  isOrderByMail = false;
  isDate = false;

  ngOnInit(): void {
    let id = this.routes.snapshot.paramMap.get("id");
    if (id != null) {
      let valoresAceptados = /^[0-9]+$/;
      if (id.match(valoresAceptados)) {
        this.getStudents(parseInt(id));
        this.CapacitationId = parseInt(id);
      } else {
        console.log('No Aceptado');
        this.router.navigateByUrl('/404');
      }

    }
    let decoded: any = jwt_decode(this.storage.getToken());
    if (decoded.role == 'Administrador') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  }

  openModal(template: TemplateRef<any>) {

      this.modalRef = this.modalService.show(template);
  }

  deleteStudent(id: number, name: string, lastname: string) {
    Swal.fire({
      title: 'Eliminar Alumno',
      text: `¿Está seguro que desea eliminar el alumno ${name} ${lastname}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E3000B',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.core.deleteStudent(id, this.CapacitationId).subscribe((data: any) => {
          console.log(data);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'El alumno ha sido eliminado correctamente',
            showConfirmButton: false,
            timer: 1500
          });
          this.students = [];
          this.getStudents(this.CapacitationId);
        }, (error: any) => {
          if (error.status = 401) {
            this.auth.refreshToken();
            this.deleteStudent(id, name, lastname);
          } else if (error.status = 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'El alumno no ha sido borrado correctamente debido a un error interno',
              showConfirmButton: false,
              timer: 1500
            });
          }
          console.error(error.status);
        });

      }
    });
  }

  editEmail() {
    this.isEdit = true;
  }

  onCancel() {
    this.isEdit = false;
  }

  onChangeEmail(evt) {
    this.isLoading = true;
    let body = {email: this.newEmail}
    let id = this.student.id;
    this.core.updateEmail(body, id).subscribe( res => {
      this.isEdit = false;
      this.isLoading = false;
      this.student.email = this.newEmail;
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: `Se ha actualizado correctamente el email de ${this.student.firstName} ${this.student.lastName}`,
        showConfirmButton: false,
        timer: 1500
      });
    })
  }

  showStudent(id: number, template: TemplateRef<any>) {
    this.students.forEach(element => {
      if (element.id === id) {
        this.student = element;
        this.newEmail = this.student.email
      }
    });
    this.modalRef = this.modalService.show(template);
  }

  getStudents(id: number) {
    this.core.allStudentsByCapacitation(id).subscribe((data: any) => {
      console.log(data);
      if (data.data.length == 0) {
        this.notResult = true;
      } else {
        this.students = data.data;
        this.allStudents = data.data;
      }
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.router.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }


  orderByName(){
    if (!this.isEnable) {
      this.isEnable = true;
      this.order = 'name';
      this.isDate = false;
      this.isOrderByName = true;
      this.isOrderByMail = false;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
      this.isOrderByMail = false;
      this.order = 'name';
    }

  }

  search(evt) {
    if(this.searchText.length > 2){
    let aFilter = [];

    for(let item of this.allStudents) {
      if(item.firstName.toUpperCase().includes(this.searchText.toUpperCase()) || 
      item.lastName.toUpperCase().includes(this.searchText.toUpperCase()) ||
      item.email.toUpperCase().includes(this.searchText.toUpperCase())){
        aFilter.push(item);
      }
    }
    
      this.students = aFilter;
    }else{
      this.students = this.allStudents;
    }
    
  }

  orderByDate(){
    if (!this.isDate) {
      this.isEnable = false;
      this.isDate = true;
      this.isOrderByName = false;
      this.isOrderByMail = false;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
      this.isOrderByMail = false;
    }
  }

  orderByMail(){
    if (!this.isEnable) {
      this.isEnable = true;
      this.order = 'category';
      this.isDate = false;
      this.isOrderByName = false;
      this.isOrderByMail = true;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
      this.isOrderByMail = false;
      this.order = 'category';
    }
  }


  downloadExcel(){
    this.core.downloadStudentsByCapacitation(this.CapacitationId).subscribe( (data: any) => {
      let blob = new Blob([data], { type: 'text/xlsx' });
      let url = window.URL.createObjectURL(blob);
      if(navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, 'export.xlsx');
      } else {
          let a = document.createElement('a');
          a.href = url;
          a.download =  `Lista_Alumnos_${new Date().toLocaleString()}.xlsx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    });
  }
}
