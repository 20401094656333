import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  constructor(private core: CoreService) { }

  notifications: {
    Id: number,
    Name: string,
    isEnable: boolean
  }[];

  checks: boolean[] = [true, true, true, true, true, true, true, true, true, true, true, true];

  loading: boolean = true;

  ngOnInit(): void {
    this.getChecks();
  }

  getChecks(){
    this.core.allNotifications().subscribe((data: any) => {
      data.data.forEach((element, index) => {
        this.checks[index] = element.isEnable;
      });
    }, error => {
      console.log(error);
    }, () => {
      this.loading = false;
    });
  }

  check(id: number) {
    switch (id) {
      case 1:
        this.checks[0] = !this.checks[0];
        this.core.setNotification(id, this.checks[0]).subscribe((data: boolean) => console.log(data));
        break;
      case 2:
        this.checks[1] = !this.checks[1];
        this.core.setNotification(id, this.checks[1]).subscribe((data: boolean) => console.log(data));
        break;
      case 3:
        this.checks[2] = !this.checks[2];
        this.core.setNotification(id, this.checks[2]).subscribe((data: boolean) => console.log(data));
        break;
      case 4:
        this.checks[3] = !this.checks[3];
        this.core.setNotification(id, this.checks[3]).subscribe((data: boolean) => console.log(data));
        break;
      case 5:
        this.checks[4] = !this.checks[4];
        this.core.setNotification(id, this.checks[4]).subscribe((data: boolean) => console.log(data));
        break;
      case 6:
        this.checks[5] = !this.checks[5];
        this.core.setNotification(id, this.checks[5]).subscribe((data: boolean) => console.log(data));
        break;
      case 7:
        this.checks[6] = !this.checks[6];
        this.core.setNotification(id, this.checks[6]).subscribe((data: boolean) => console.log(data));
        break;
      case 8:
        this.checks[7] = !this.checks[7];
        this.core.setNotification(id, this.checks[7]).subscribe((data: boolean) => console.log(data));
        break;
      case 9:
        this.checks[8] = !this.checks[8];
        this.core.setNotification(id, this.checks[8]).subscribe((data: boolean) => console.log(data));
        break;
      case 10:
        this.checks[9] = !this.checks[9];
        this.core.setNotification(id, this.checks[9]).subscribe((data: boolean) => console.log(data));
        break;
      case 11:
        this.checks[10] = !this.checks[10];
        this.core.setNotification(id, this.checks[10]).subscribe((data: boolean) => console.log(data));
        break;
      case 13:
        this.checks[11] = !this.checks[11];
        this.core.setNotification(id, this.checks[11]).subscribe((data: boolean) => console.log(data));
        break;
    }
  }
}
