export class CertificationRequestModel {
  CapacitationId: number;
  Type: number;
  UrlFirm: string;
  UrlLogos: string;
  UrlUniversity: string;
}


export class CertificationGenerateRequestModel {
  CapacitationId: number;
  Type: number;
  UrlFirm: string;
  UrlLogos: string;
  UrlUniversity: string;
  Students: number[];
}
