import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Event, Router } from '@angular/router';
import { PersonCreateModel } from 'src/app/models/person';
import { TrainerCreateModel, TrainerModel, TrainerUpdateModel } from 'src/app/models/trainer';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-crud-trainers',
  templateUrl: './crud-trainers.component.html',
  styleUrls: ['./crud-trainers.component.scss']
})
export class CrudTrainersComponent implements OnInit {

  constructor(private route: Router, private core: CoreService, private auth: AuthService, private routes: ActivatedRoute, private fb: FormBuilder) { }
  filename: string;
  file: FileList;
  private containFile: boolean = false;
  archivo: any;
  aux: string[];
  isEdit: boolean = false;
  newTrainer: TrainerCreateModel = new TrainerCreateModel();
  updateTrainer: TrainerUpdateModel = new TrainerUpdateModel();
  editTrainer: TrainerModel;
  private trainerID: number;
  private IMG_URL = environment.IMG_URL;
  ngOnInit(): void {
    this.filename = 'Nombre del archivo.JPG';
    console.log(this.trainerForm);
    let id = this.routes.snapshot.paramMap.get("id");
    if (id != null) {
      let valoresAceptados = /^[0-9]+$/;
      if (id.match(valoresAceptados)) {
        this.isEdit = true;
        this.showTrainer(parseInt(id));
        this.trainerID = parseInt(id);
      } else {
        console.log('No Aceptado');
        this.route.navigateByUrl('/404');
      }

    }
  }

  description: string;
  linkedin: string;
  position: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  carac: string;
  photo: string;
  codPais: string;

  showTrainer(id: number) {
    this.core.showTrainer(id).subscribe((data: any) => {
      this.editTrainer = data.data;
      console.log(data);
      this.description = this.editTrainer.description;
      this.linkedin = this.editTrainer.linkedin;
      this.position = this.editTrainer.position;
      this.email = this.editTrainer.email;
      this.firstName = this.editTrainer.firstName;
      this.lastName = this.editTrainer.lastName;
      let result = this.editTrainer.phone.split('-');
      let result2 = result[0].split(' ');
      let result3 = result2[0].split('+');
      this.codPais = result3[1];
      this.carac = result2[1];
      this.phone = result[1];
      this.photo = this.editTrainer.photo;
      console.log(this.editTrainer);
    }, (error: any) => {
      console.log(error.status)
      if (error.error.status = 401) {
        this.auth.refreshToken();
        this.showTrainer(id);
      } else if (error.error.status = 500) {
        this.route.navigateByUrl('/500')
      }
      console.error(error);
    });
  }

  addOrEditTrainer() {
    if (!this.isEdit) {
      let urlImg: string;
      console.log(this.trainerForm);
      this.newTrainer.description = this.trainerForm.value.description;
      this.newTrainer.linkedin = this.trainerForm.value.linkedin;
      this.newTrainer.position = this.trainerForm.value.position;

      this.newTrainer.email = this.trainerForm.value.email;
      this.newTrainer.firstName = this.trainerForm.value.firstName;
      this.newTrainer.lastName = this.trainerForm.value.lastName;
      this.newTrainer.phone = `+${this.trainerForm.value.codPais} ${this.trainerForm.value.carac}-${this.trainerForm.value.phone}`;
      console.log(this.newTrainer);
      this.core.existUser(this.newTrainer.email).subscribe((data: any) => {
        console.log(data.exist);
        if (!data.exist) {
          if (this.containFile) {
            this.core.uploudFile(this.file[0] ? this.file[0] : null).subscribe((data: any) => {
              urlImg = `${this.IMG_URL}${data.data}`;
              console.log(urlImg);
              this.newTrainer.photo = urlImg;
              this.core.addTrainer(this.newTrainer).subscribe((data1: any) => {
                console.log(data1);
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Se ha agradado un nuevo capacitador con éxito',
                  showConfirmButton: false,
                  timer: 1500
                })
                this.goToTrainers();
              }, (error: any) => {
                if (error.status = 401) {
                  this.auth.refreshToken();
                } else if (error.status = 500) {
                  this.route.navigateByUrl('/500')
                }
                console.error(error);
              });
            }, (error: any) => {
              if (error.error.code == 4) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Archivo no admitido',
                  showConfirmButton: false,
                  timer: 1500
                });
              }
              if (error.status == 401 && error.error.code != 4) {
                this.auth.refreshToken();
              } else if (error.status == 500) {
                this.route.navigateByUrl('/500')
              }
              console.error(error);
            });
          } else {
            this.core.addTrainer(this.newTrainer).subscribe((data1: any) => {
              console.log(data1);
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Se ha agradado un nuevo capacitador con éxito',
                showConfirmButton: false,
                timer: 1500
              })
              this.goToTrainers();
            }, (error: any) => {
              if (error.status = 401) {
                this.auth.refreshToken();
              } else if (error.status = 500) {
                this.route.navigateByUrl('/500')
              }
              console.error(error);
            });
          }

        } else {
          console.error('El capacitador ya existe!')
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ya existe un capacitador en ese correo electrónico',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }, (error: any) => {
        if (error.status = 401) {
          this.auth.refreshToken();
        } else if (error.status = 500) {
          this.route.navigateByUrl('/500')
        }
        console.error(error);
      });
    } else {
      let urlImg: string;
      this.updateTrainer.description = this.trainerForm.value.description;
      this.updateTrainer.linkedin = this.trainerForm.value.linkedin;
      this.updateTrainer.position = this.trainerForm.value.position;

      this.updateTrainer.email = this.trainerForm.value.email;
      this.updateTrainer.firstName = this.trainerForm.value.firstName;
      this.updateTrainer.lastName = this.trainerForm.value.lastName;
      this.updateTrainer.phone = `+${this.trainerForm.value.codPais} ${this.trainerForm.value.carac}-${this.trainerForm.value.phone}`;
      if (this.containFile) {
        this.core.uploudFile(this.file[0] ? this.file[0] : null).subscribe((data: any) => {
          urlImg = `${this.IMG_URL}${data.data}`;
          console.log(urlImg);
          this.updateTrainer.photo = urlImg;
          this.core.updateTrainer(this.trainerID, this.updateTrainer).subscribe((data1: any) => {
            console.log(data1);
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Se ha actualizado el capacitador con éxito',
              showConfirmButton: false,
              timer: 1500
            })
            this.goToTrainers();
          }, (error: any) => {
            if (error.error.code == 6) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Ya existe un capacitador en ese correo electrónico',
                showConfirmButton: false,
                timer: 1500
              });
            } else if (error.status = 401 && error.error.code != 6) {
              this.auth.refreshToken();
            } else if (error.status = 500) {
              this.route.navigateByUrl('/500')
            }
            console.error(error);
          });
        }, (error: any) => {
          if (error.error.code == 4) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Archivo no admitido',
              showConfirmButton: false,
              timer: 1500
            });
          }
          if (error.status == 401 && error.error.code != 4) {
            this.auth.refreshToken();
          } else if (error.status == 500) {
            this.route.navigateByUrl('/500')
          }
          console.error(error);
        });
      } else {
        if (this.photo != null) {
          this.updateTrainer.photo = this.photo;
        }
        this.core.updateTrainer(this.trainerID, this.updateTrainer).subscribe((data1: any) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Se ha actualizado el capacitador con éxito',
            showConfirmButton: false,
            timer: 1500
          });
          this.goToTrainers();
        }, (error: any) => {
          if (error.error.code == 6) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Ya existe un capacitador en ese correo electrónico',
              showConfirmButton: false,
              timer: 1500
            });
          } else if (error.error.code == 8) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'No se encuentra el usuario',
              showConfirmButton: false,
              timer: 1500
            });
          } else if (error.status = 401 && error.error.code != 6) {
            this.auth.refreshToken();
          } else if (error.status = 500) {
            this.route.navigateByUrl('/500')
          } 
          console.error(error);
        });
      }
    }

  }

  goToTrainers() {
    this.route.navigateByUrl('/capacitadores')
  }


  filechange(files: FileList) {
    this.file = files;
    this.filename = files[0].name;
    this.containFile = true;
  }

  print(e){
    console.log(this.trainerForm);
  }

  numPattern = /^\d+$/;
  spacePettern = /^[^-\s][a-zA-Z0-9_\s-]+$/;
  // Formulario reactivo
  trainerForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(2)]],
    position: ['', [Validators.maxLength(35)]],
    description: ['', Validators.maxLength(300)],
    photo: [''],
    email: ['', [Validators.required, Validators.email]],
    linkedin: ['', [Validators.maxLength(25)]],
    codPais: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(3), Validators.pattern(this.numPattern)]],
    carac: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(5), Validators.pattern(this.numPattern)]],
    phone: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(8), Validators.pattern(this.numPattern)]],
  });

}
