<nav class="navbar">
  <a class="navbar-brand" >
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<div class="container animate__animated animate__bounceIn align-items-center minh-100">
  <div class="row justify-content-center align-items-center minh-100">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-auto">
          <h1 class="bomba"><i class="fas fa-ghost"></i></h1>
        </div>
        <div class="col-auto">
          <div class="container-fluid">
            <div class="row ">
              <div class="col-auto">
                <h1 class="text-404">500 /</h1>
              </div>
              <div class="col-auto">
                <p class="razon-error ">Epa!<br>Hubo un error en el servidor<br>Por favor <span><a (click)="backClicked()" class="link-error">refrescá la página</a></span><br>o <span><a (click)="closeSesion()" class="link-error">vuelva iniciar sesión</a></span></p>
              </div>
            </div>
            <div class="row margen text-center">
              <p>internal several error</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
