import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Error, errorTypes } from 'src/app/models/error';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-error-reporting',
  templateUrl: './error-reporting.component.html',
  styleUrls: ['./error-reporting.component.scss']
})
export class ErrorReportingComponent implements OnInit {

  constructor(private core: CoreService, private route: Router, private auth: AuthService) { }


  ngOnInit(): void {
    this.getErrorType(2);
  }
  Id: number;
  Message: string = null;
  StackTrace: string = null;
  ClassName: string = null;
  MethodName: string = null;
  StatusCode: number;
  StatusDescription: string = null;
  Payload: string = null;
  EndPointUrl: string = null;
  errorType: number;
  Create: string = null;
  errorList: Array<errorTypes>=[{id:1, name:"TotvsElearning"},
  {id:3, name:"ElearningTotvs"},
  {id:4, name:"ElearningMagento"},
  {id:5, name:"MagentoElearning"}]
  
  change = false;
  errortypes: errorTypes;
  errors: Error[];
  page: number;
  

  getAllErrors() {
    this.errors = [];
    this.change=false;
    this.core.allErrorReport().subscribe((data: any) => {
      this.errors = data;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    });
  }

  changeState(errorId: number){
    this.change=!this.change;
    this.getErrorType(errorId);
  }
  getErrorType(errorType: number){
    this.errors = [];
    this.core.errorReport(errorType).subscribe((data:any) => {
      this.errors = data;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    })
  }
}
