import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DuplicateCapacitationModel, EditCapacitationModel } from '../models/capacitation';
import { CategorieModel } from '../models/categorie';
import { StudentModel } from '../models/student';
import { TrainerCreateModel, TrainerUpdateModel } from '../models/trainer';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class GeographyService {

  private token: string;
  private endPoint = environment.API_URL;
  private httpHeaderWithBearer = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${this.storage.getToken()}`
    })
  }

  constructor(private httpClient: HttpClient, private storage: StorageService) { }


  GetCountries(){
    return this.httpClient.get(`${this.endPoint}/locations/country`, this.httpHeaderWithBearer);
  }
  GetProvinces(id: number){
    return this.httpClient.get(`${this.endPoint}/locations/province/${id}`, this.httpHeaderWithBearer);
  }
  GetCities(id: number){
    return this.httpClient.get(`${this.endPoint}/locations/city/${id}`, this.httpHeaderWithBearer);
  }
}
