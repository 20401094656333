<app-header style="z-index: 999;"></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4 tablet-phone-mode">
    <h2>Accesos Directos</h2>
  </div>
  <div class="row mt-4 mb-5 justify-content-center animate__animated animate__zoomIn animate__faster">
    <div *ngIf="isAdmin" class="container">
      <div class="row ">
        <div class="col-lg-4 mt-3">
          <div class="card" (click)="goToPage('/capacitaciones')" (mouseenter)="hoverChangeEnter(3)" (mouseleave)="hoverChangeLeave(3)" [ngClass]="{'selected-card': hover[3]}" style="width: 90%;">
            <div class="card-body">
              <i class="home-icon mt-3 ml-2 fas fa-user-graduate"></i>
              <div class="row mt-5" >
                <div class="col-8">
                  <h6 [ngClass]="{'selected-card-text': hover[3]}" class="barb-red card-link ml-2">Capacitaciones</h6>
                </div>
                <div class="col-4 text-right">
                  <i [ngClass]="{'selected-card-text': hover[3]}" class="barb-red fas fa-chevron-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card" (click)="goToPage('/categorias')" (mouseenter)="hoverChangeEnter(0)" (mouseleave)="hoverChangeLeave(0)" [ngClass]="{'selected-card': hover[0]}" style="width: 90%;">
            <div class="card-body">
              <i class="home-icon mt-3 ml-2 fas fa-th-list"></i>
              <div class="row mt-5" >
                <div class="col-8">
                  <h6 [ngClass]="{'selected-card-text': hover[0]}" class="barb-red card-link ml-2">Categorías</h6>
                </div>
                <div class="col-4 text-right">
                  <i [ngClass]="{'selected-card-text': hover[0]}" class="barb-red fas fa-chevron-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card" (click)="goToPage('/capacitadores')" (mouseenter)="hoverChangeEnter(1)" (mouseleave)="hoverChangeLeave(1)" [ngClass]="{'selected-card': hover[1]}" style="width: 90%;">
            <div class="card-body">
              <i class="home-icon mt-3 ml-2 fas fa-users"></i>
              <div class="row mt-5" >
                <div class="col-8">
                  <h6 [ngClass]="{'selected-card-text': hover[1]}" class="barb-red card-link ml-2">Capacitadores</h6>
                </div>
                <div class="col-4 text-right">
                  <i [ngClass]="{'selected-card-text': hover[1]}" class="barb-red fas fa-chevron-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card" (click)="goToPage('/calendario')" (mouseenter)="hoverChangeEnter(2)" (mouseleave)="hoverChangeLeave(2)" [ngClass]="{'selected-card': hover[2]}" style="width: 90%;">
            <div class="card-body">
              <i class="home-icon mt-3 ml-2 far fa-calendar-alt"></i>
              <div class="row mt-5" >
                <div class="col-8">
                  <h6 [ngClass]="{'selected-card-text': hover[2]}" class="barb-red card-link ml-2">Calendario</h6>
                </div>
                <div class="col-4 text-right">
                  <i [ngClass]="{'selected-card-text': hover[2]}" class="barb-red fas fa-chevron-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-lg-4 mt-3">
        <div class="card" (click)="goToPage('/certificaciones')" (mouseenter)="hoverChangeEnter(4)" (mouseleave)="hoverChangeLeave(4)" [ngClass]="{'selected-card': hover[4]}" style="width: 90%;">
          <div class="card-body">
            <i class="home-icon mt-3 ml-2 fas fa-certificate"></i>
            <div class="row mt-5" >
              <div class="col-8">
                <h6 [ngClass]="{'selected-card-text': hover[4]}" class="barb-red card-link ml-2">Certificaciones</h6>
              </div>
              <div class="col-4 text-right">
                <i [ngClass]="{'selected-card-text': hover[4]}" class="barb-red fas fa-chevron-circle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="card" (click)="goToPage('/reportes')" (mouseenter)="hoverChangeEnter(5)" (mouseleave)="hoverChangeLeave(5)" [ngClass]="{'selected-card': hover[5]}" style="width: 90%;">
          <div class="card-body">
            <i class="home-icon mt-3 ml-2 fas fa-tasks"></i>
            <div class="row mt-5" >
              <div class="col-8">
                <h6 [ngClass]="{'selected-card-text': hover[5]}" class="barb-red card-link ml-2">Reportes</h6>
              </div>
              <div class="col-4 text-right">
                <i [ngClass]="{'selected-card-text': hover[5]}" class="barb-red fas fa-chevron-circle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="card" (click)="goToPage('/notificaciones')" (mouseenter)="hoverChangeEnter(6)" (mouseleave)="hoverChangeLeave(6)" [ngClass]="{'selected-card': hover[6]}" style="width: 90%;">
          <div class="card-body">
            <i class="home-icon mt-3 ml-2 far fa-bell"></i>
            <div class="row mt-5" >
              <div class="col-8">
                <h6 [ngClass]="{'selected-card-text': hover[6]}" class="barb-red card-link ml-2">Notificaciones</h6>
              </div>
              <div class="col-4 text-right">
                <i [ngClass]="{'selected-card-text': hover[6]}" class="barb-red fas fa-chevron-circle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 mt-3">
        <div class="card" (click)="goToPage('/administracion')" (mouseenter)="hoverChangeEnter(7)" (mouseleave)="hoverChangeLeave(7)" [ngClass]="{'selected-card': hover[7]}" style="width: 90%;">
          <div class="card-body">
            <i class="home-icon mt-3 ml-2 far fa-address-card"></i>
            <div class="row mt-5" >
              <div class="col-8">
                <h6 [ngClass]="{'selected-card-text': hover[7]}" class="barb-red card-link ml-2">Administradores</h6>
              </div>
              <div class="col-4 text-right">
                <i [ngClass]="{'selected-card-text': hover[7]}" class="barb-red fas fa-chevron-circle-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div *ngIf="!isAdmin"  class="container">
      <div class="row justify-content-center mt-5">
        <div class="col-lg-4 mt-3">
          <div class="card" (click)="goToPage('/capacitaciones')" (mouseenter)="hoverChangeEnter(3)" (mouseleave)="hoverChangeLeave(3)" [ngClass]="{'selected-card': hover[3]}" style="width: 90%;">
            <div class="card-body">
              <i class="home-icon mt-3 ml-2 fas fa-user-graduate"></i>
              <div class="row mt-5" >
                <div class="col-8">
                  <h6 [ngClass]="{'selected-card-text': hover[3]}" class="barb-red card-link ml-2">Capacitaciones</h6>
                </div>
                <div class="col-4 text-right">
                  <i [ngClass]="{'selected-card-text': hover[3]}" class="barb-red fas fa-chevron-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="card" (click)="goToPage('/calendario')" (mouseenter)="hoverChangeEnter(2)" (mouseleave)="hoverChangeLeave(2)" [ngClass]="{'selected-card': hover[2]}" style="width: 90%;">
            <div class="card-body">
              <i class="home-icon mt-3 ml-2 far fa-calendar-alt"></i>
              <div class="row mt-5" >
                <div class="col-8">
                  <h6 [ngClass]="{'selected-card-text': hover[2]}" class="barb-red card-link ml-2">Calendario</h6>
                </div>
                <div class="col-4 text-right">
                  <i [ngClass]="{'selected-card-text': hover[2]}" class="barb-red fas fa-chevron-circle-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
