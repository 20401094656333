<app-header> </app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <bs-modal-backdrop style="z-index: 800;" *ngIf="showBackdrop" class="modal-backdrop fade in show "></bs-modal-backdrop>
  <div class="row mt-4">
    <div class="col-lg-auto ">
      <h2>Administradores</h2>
    </div>
    <div class="col-lg-9 text-rigth">
      <div class="container-fluid">
        <div class="row justify-content-end">
          <div style="z-index: 999;" class="col-lg-auto">
            <button class="btn btn-cancel animate__animated animate__fadeIn animate__faster" *ngIf="showButtons" (click)="showDeleteAdmin()" style="font-weight: bold; font-size: 20px; color: #022A3A; z-index: 999;"><i class="far fa-times-circle"></i>&nbsp;&nbsp; Cancelar</button>
          </div>
          <div class="col-lg-auto">
            <button class="btn" (click)="showDeleteAdmin()" style="background: transparent; font-weight: bold; font-size: 20px; color: #022A3A;"><i class="far fa-minus-square"></i>&nbsp;&nbsp; Eliminar</button>
          </div>
          <div class="col-lg-auto">
            <button (click)="goTonewAdmin()" class="btn" style="background: transparent; font-weight: bold; font-size: 20px; color: #022A3A;"><i class="far fa-times-circle" style="transform: rotate(45deg);"></i>&nbsp;&nbsp; Agregar</button>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div class="row mt-5">
    <div class="container-fluid">
      <div *ngIf="!loading" class="row justify-content-center">
        <div *ngFor="let item of admins" style="z-index: 999;" class="col-auto">
          <button class="btn btn-cancel-black mb-2 animate__animated animate__bounceIn animate__faster" *ngIf="showButtons" (click)="deleteAdmin(item.id, item.firstName, item.lastName)"><i class="far fa-minus-square"></i>&nbsp;&nbsp; Eliminar</button>
          <div class="card" [ngClass]="{'indexToTop': showButtons}">
            <div class="card-body" style="padding: 10px;">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-auto">
                    <img *ngIf="item.photo != null" bind-src="item.photo" class="avatar">
                    <img *ngIf="item.photo == null" src="../../../assets/notPhoto.png" class="avatar">
                  </div>
                  <div class="col-auto" style="padding-right: 0 !important;">
                    <div class="container-fluid">
                      <div class="row">
                        <h5>{{item.firstName}}<br>{{item.lastName}}</h5>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-auto align-self-center">
                          <button class="btn" (click)="editAdmin(item.id)" style="background: transparent;"><i class="fas fa-pen"></i></button>
                        </div>
                        <div class="col-auto align-self-end">
                          <button *ngIf="!item.show" class="btn animate__animated animate__rotateIn animate__faster" (click)="showInfo(item)" style="background: transparent;"><i class="fas fa-chevron-down"></i></button>
                          <button *ngIf="item.show" class="btn animate__animated animate__rotateIn animate__faster" (click)="showInfo(item)" style="background: transparent;"><i class="fas fa-chevron-up"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <p style="margin-bottom: 0;"><span style="font-weight: bold;">Email</span><br>{{item.email}}</p>
                </div>
                <div *ngIf="item.show" class="row animate__animated animate__fadeIn animate__faster">
                  <div class="container-fluid">
                    <div class="row">
                      <p style="margin-bottom: 0;"><span style="font-weight: bold;">Teléfono</span><br>{{item.phone}}</p>
                    </div>
                    <div class="row">
                      <p style="margin-bottom: 0;"><span style="font-weight: bold;">LinkedIn</span><br>{{item.linkedin}}</p>
                    </div>
                    <div class="row">
                      <p style="margin-bottom: 0;"><span style="font-weight: bold;">Cargo</span><br>{{item.position}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row mt-5 justify-content-center mb-5">
        <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
      </div>
    </div>
  </div>
</div>

<ng-template #modalAdmin>
  <div style="z-index: 999999;" class="modal-body">
    <form [formGroup]="adminForm" (ngSubmit)="add()">
      <div class="form-group">
        <label for="recipient-name" class="col-form-label">Nombre*</label>
        <input maxlength="25" minlength="3" type="text" name="firstName" formControlName="firstName" ngModel [pattern]="spacePettern" class="form-control" required id="recipient-name">
        <div class="mb-3" *ngIf="adminForm.controls.firstName.invalid && (adminForm.controls.firstName.dirty || adminForm.controls.firstName.touched)">
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.firstName.errors?.required">
            El nombre es requerido.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.firstName.errors?.minlength">
            El nombre debe contener más de 3 caracteres.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.firstName.errors?.pattern">
            El nombre tiene un formato no admitido.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="recipient-name" class="col-form-label">Apellido*</label>
        <input maxlength="25" minlength="3" type="text" name="lastName" formControlName="lastName" ngModel [pattern]="spacePettern" class="form-control" required id="recipient-name">
        <div class="mb-3" *ngIf="adminForm.controls.lastName.invalid && (adminForm.controls.lastName.dirty || adminForm.controls.lastName.touched)">
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.lastName.errors?.required">
            El apellido es requerido.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.lastName.errors?.minlength">
            El apellido debe contener más de 3 caracteres.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.lastName.errors?.pattern">
            El apellido tiene un formato no admitido.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="recipient-name" class="col-form-label">Correo Electrónico*</label>
        <input maxlength="25" minlength="3" type="text" name="email" formControlName="email" ngModel [pattern]="spacePettern" class="form-control" required id="recipient-name">
        <div class="mb-3" *ngIf="adminForm.controls.email.invalid && (adminForm.controls.email.dirty || adminForm.controls.email.touched)">
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.email.errors?.required">
            El correo electrónico es requerido.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.email.errors?.minlength">
            El correo electrónico debe contener más de 3 caracteres.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.email.errors?.pattern">
            El correo electrónico tiene un formato no admitido.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="recipient-name" class="col-form-label">Teléfono*</label>
        <input maxlength="25" minlength="3" type="text" name="phone" formControlName="phone" ngModel [pattern]="spacePettern" class="form-control" required id="recipient-name">
        <div class="mb-3" *ngIf="adminForm.controls.phone.invalid && (adminForm.controls.phone.dirty || adminForm.controls.phone.touched)">
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.phone.errors?.required">
            El correo electrónico es requerido.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.phone.errors?.minlength">
            El correo electrónico debe contener más de 3 caracteres.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="adminForm.controls.phone.errors?.pattern">
            El correo electrónico tiene un formato no admitido.
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-auto">
              <button type="button" class="btn btn-back" (click)="modalRef.hide()">Cerrar</button>
            </div>
            <div class="col-auto">
              <button type="submit" [disabled]="!adminForm.valid" class="btn btn-save">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
