<nav class="navbar">
    <a class="navbar-brand" routerLink="/inicio">
      <img src="../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt=""><small style="color: white; font-size: 10px;">V {{versionApp}}</small>
    </a>
    <span class="navbar-text">

    </span>
    <p class="align-self-center" tooltip="Mi cuenta" style="cursor: pointer; font-size: 24px; margin-bottom: 0; margin-right: 5px;" [popover]="tooltipTemplate" containerClass="tooltip-custom" placement="bottom"><i class="far fa-user-circle"></i></p>
</nav>

<ng-template #tooltipTemplate>
  <div class="container-fluid">
    <div class="row">
      <div class="col-auto align-self-center">
        <img *ngIf="photo != null" [src]="photo" class="avatar">
        <img *ngIf="photo == null" src="../../../assets/notPhoto.png" class="avatar">
      </div>
      <div class="col-7 align-self-center">
        <h5 style="line-height: 25px; word-wrap: break-word;">{{firstName}} {{lastName}}</h5>
      </div>
    </div>
    <div class="row">
      <div class="col-auto align-self-center">
        <a  style="color: black; text-decoration: none; font-weight: bold; cursor: pointer;" (click)="closeSesion()">Cerrar Sesión</a>
      </div>
      <div class="col-auto align-self-center">
        <button (click)="changePassword()" class="btn btn-change">Cambiar contraseña</button>
      </div>
    </div>
  </div>
</ng-template>
