import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { CategorieModel } from 'src/app/models/categorie';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, Validators } from '@angular/forms';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  modalRef: BsModalRef;
  subscriptions: Subscription[] = [];
  selectedID: number;
  selectedName: string;
  selectedIdMagento: number;
  selectedDesc: string;
  textToSearch: string;
  notResult: boolean = false;
  page: number = 1;
  constructor(private core: CoreService, private route: Router, private auth: AuthService, private modalService: BsModalService, private fb: FormBuilder, private changeDetection: ChangeDetectorRef) { }
  categories: CategorieModel[];

  ngOnInit(): void {
    this.get();
    console.log(this.categorieForm.value);
  }
  loading: boolean = true;
  get() {

    this.loading = true;
    this.core.getCategories().subscribe((data: any) => {
      this.categories = data.data;
      console.log(data);
      console.log(this.categories);
      this.loading = false;
    }, (error: any) => {
      if (error.status = 401) {
        this.auth.refreshToken();
      } else if (error.status = 500) {
        this.route.navigateByUrl('/500')
      }
      console.error(error.status);
    });
  }

  search(text: any) {
    console.log(text);
      if (text.length >= 3) {
        this.categories = [];
        this.core.dynamicSearchCategory(text).subscribe((data: any) => {
          console.log(data);
          this.categories = data.data.result;
          if (this.categories.length != 0) {
            this.notResult = false;
          } else {
            this.notResult = true;
          }
          console.log(this.notResult);
        }, (error: any) => {
          console.log(error);
          if (error.status = 401) {
            this.auth.refreshToken();
          } else if (error.status = 500) {
            this.route.navigateByUrl('/500')
          }
          console.error(error.status);
        });
      } else if (text.length < 1 || text == '') {
        this.categories = [];
        this.notResult = false;
        this.get();
      }
  }


  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  openModal(template: TemplateRef<any>) {
    const _combine = combineLatest(
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string | any) => {
        this.categorieForm.value.name = '';
        this.categorieForm.value.idMagento = '';
        this.categorieForm.value.desc = '';
        console.log(this.categorieForm.value);
        this.unsubscribe();
      })
    );
    this.subscriptions.push(_combine);
    this.modalRef = this.modalService.show(template);

  }

  openModalEdit(templateEdit: TemplateRef<any>, id: number, name: string,  idMagento: number ,desc: string) {
    this.selectedID = id;
    this.selectedName = name;
    this.selectedIdMagento = idMagento;
    this.selectedDesc = desc;
    const _combine = combineLatest(
      this.modalService.onHidden
    ).subscribe(() => this.changeDetection.markForCheck());
    this.subscriptions.push(
      this.modalService.onHidden.subscribe((reason: string | any) => {
        this.categorieForm.value.name = '';
        this.categorieForm.value.idMagento = '';
        this.categorieForm.value.desc = '';
        console.log(this.categorieForm.value);
        this.unsubscribe();
      })
    );
    this.subscriptions.push(_combine);
    console.log(this.categorieForm.value);
    this.modalRef = this.modalService.show(templateEdit);
  }

  edit() {
    console.log(this.categorieForm.value);
    this.core.updateCategory(this.selectedID, this.categorieForm.value.name, this.categorieForm.value.idMagento ,this.categorieForm.value.desc).subscribe((data: any) => {
      this.categories = [];
      this.get();
      Swal.fire(
        'Actualizada',
        'La categoría a sido actualizada correctamente',
        'success'
      )
    }, (error: any) => {
      console.log(error);
      if (error.error.code == 5) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: `La categoría ${this.categorieForm.value.name} ya existe`,
          showConfirmButton: false,
          timer: 1500
        });
      } else if (error.status = 401 && error.error.code != 5) {
        this.auth.refreshToken();
      } else if (error.status = 500) {
        Swal.fire(
          'No actualizada',
          'La categoría no a sido actualizada debido a un error interno',
          'error'
        )
        this.route.navigateByUrl('/500')
      }
      console.error(error);
    });
    this.modalRef.hide();
  }

  add(){
    console.log(this.categorieForm.value);
    this.core.addCategory(this.categorieForm.value.name, this.categorieForm.value.idMagento ,this.categorieForm.value.desc).subscribe((data: any) => {
      this.categories = [];
      this.get();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: `La categoría ${this.categorieForm.value.name} Ha sido creada correctamente`,
        showConfirmButton: false,
        timer: 1500
      });
    }, (error: any) => {
      console.error(error);
      if (error.error.code == 5) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: `La categoría ${this.categorieForm.value.name} ya existe`,
          showConfirmButton: false,
          timer: 1500
        });
      } else if (error.status = 401 && error.error.code != 5) {
        this.auth.refreshToken();
      } else if (error.status = 500) {
        this.route.navigateByUrl('/500')
      }
      console.error(error.status);
    });
    this.modalRef.hide();
  }

  delete(id: number, name: string){
    Swal.fire({
      title: 'Eliminar categoría',
      text: `¿Está seguro que desea eliminar la categoría: ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E3000B',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.core.deleteCategory(id).subscribe((data: any) => {
          this.categories = [];
          this.get();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: `La categoría ${name} ha sido eliminada correctamente`,
            showConfirmButton: false,
            timer: 1500
          });
        }, (error: any) => {
          if (error.status = 401) {
            this.auth.refreshToken();
          } else if (error.status = 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: `La categoría ${name} no ha sido eliminada debido a un error interno`,
              showConfirmButton: false,
              timer: 1500
            });
          }
          console.error(error.status);
        });

      }
    });
  }

  spacePettern = /^[^-\s][a-zA-Z0-9_\s-]+$/


  categorieForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern(this.spacePettern)]],
    idMagento: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(3), Validators.pattern(this.spacePettern)]], 
    desc: ['']
  })
}
