import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'dirmod-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss']
})
export class PercentageComponent implements OnChanges {

  @Input() percentage: number; // decorate the property with @Input()
  constructor() { }

  ngOnChanges(): void {
    this.checkPercentage();
  }
  onlyPercentage: number;
  checkPercentage() {
    if (this.percentage < 10) {
      this.onlyPercentage = 0;
    } else if (this.percentage >= 10 && this.percentage < 20) {
      this.onlyPercentage = 10;
    } else if (this.percentage >= 20 && this.percentage < 30) {
      this.onlyPercentage = 20;
    } else if (this.percentage >= 30 && this.percentage < 40) {
      this.onlyPercentage = 30;
    } else if (this.percentage >= 40 && this.percentage < 50) {
      this.onlyPercentage = 40;
    } else if (this.percentage >= 50 && this.percentage < 60) {
      this.onlyPercentage = 50;
    } else if (this.percentage >= 60 && this.percentage < 70) {
      this.onlyPercentage = 60;
    } else if (this.percentage >= 70 && this.percentage < 80) {
      this.onlyPercentage = 70;
    } else if (this.percentage >= 80 && this.percentage < 90) {
      this.onlyPercentage = 80;
    } else if (this.percentage >= 90 && this.percentage < 100) {
      this.onlyPercentage = 90;
    } else if (this.percentage === 100) {
      this.onlyPercentage = 100;
    }
  }
}
