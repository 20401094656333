import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { TrainerModel } from 'src/app/models/trainer';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-trainers',
  templateUrl: './trainers.component.html',
  styleUrls: ['./trainers.component.scss']
})
export class TrainersComponent implements OnInit {

  constructor(private route: Router, private core: CoreService, private auth: AuthService, private modalService: BsModalService) { }
  items: number[];
  modalRef: BsModalRef;
  textToSearch: string;
  notResult: boolean = false;
  trainers: TrainerModel[];
  trainer: TrainerModel;
  ngOnInit(): void {
    this.items = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];
    this.getAll();
  }
  totalItems: number;
  page: number = 1;

  search(text: any) {
    console.log(text);
      if (text.length >= 3) {
        this.trainers = [];
        this.core.dynamicSearchTrainers(text).subscribe((data: any) => {
          console.log(data);
          this.trainers = data.data;
          if (this.trainers.length != 0) {
            this.notResult = false;
          } else {
            this.notResult = true;
          }
          console.log(this.notResult);
        }, (error: any) => {
          if (error.status = 401) {
            this.auth.refreshToken();
          } else if (error.status = 500) {
            this.route.navigateByUrl('/500')
          }
          console.error(error.status);
        });
      } else if (text.length < 1 || text == '') {
        this.trainers = [];
        this.notResult = false;
        this.getAll();
      }
  }



  openModal(template: TemplateRef<any>, id: number) {

    this.core.showTrainer(id).subscribe((data: any) => {
      console.log(data);
      this.trainer = data.data;
      this.modalRef = this.modalService.show(template);
    });
  }

  goToEdit(id: number) {
    this.route.navigateByUrl(`capacitadores/editar/${id}`)
  }
  loading: boolean = true;
  getAll() {
    this.loading = true;
    this.core.getTrainers().subscribe((data: any) => {
      console.log(data);

      // Validamos si no hay mas datos en la page y volvemos a la anterior
      if((data.data.length / (this.page - 1)) === 6 ) {
        this.page = this.page-1;
      }
      this.trainers = data.data;
      this.loading = false;
    }, (error: any) => {
      if (error.status = 401) {
        this.auth.refreshToken();
        this.getAll();
      } else if (error.status = 500) {
        this.route.navigateByUrl('/500')
      }
      console.error(error.status);
    });
  }

  newTrainer() {
    this.route.navigateByUrl('/capacitadores/nuevo')
  }

  delete(id: number, name: string, lastname: string){
    console.log(this.trainers);
    console.log(id);
    Swal.fire({
      title: 'Eliminar Capacitador',
      text: `¿Está seguro que desea eliminar el capacitador ${name} ${lastname}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E3000B',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.core.deleteTrainer(id).subscribe((data: any) => {
          console.log(data);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'El capacitador ha sido borrado correctamente',
            showConfirmButton: false,
            timer: 1500
          });
          this.trainers = [];
          this.textToSearch = '';
          this.getAll();
        }, (error: any) => {
          if (error.status = 401) {
            this.auth.refreshToken();
            this.delete(id, name, lastname);
          } else if (error.status = 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'El capacitador no ha sido borrado correctamente debido a un error interno',
              showConfirmButton: false,
              timer: 1500
            });
          }
          console.error(error.status);
        });

      }
    });
  }

}
