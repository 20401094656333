import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CapacitationModel, DuplicateCapacitationModel, EditCapacitationModel } from 'src/app/models/capacitation';
import { CategorieModel } from 'src/app/models/categorie';
import { TrainerModel } from 'src/app/models/trainer';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-trainings',
  templateUrl: './edit-trainings.component.html',
  styleUrls: ['./edit-trainings.component.scss']
})
export class EditTrainingsComponent implements OnInit {

  constructor(
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private core: CoreService,
    private fb: FormBuilder,
    private auth: AuthService
    ) { }

  private trainingId: number;
  filename: string;
  aux: string[];
  filename2: string;
  aux2: string[];
  categories: CategorieModel[];
  trainers: TrainerModel[];
  capacitation: CapacitationModel;
  name: string;
  categoriesList: number[] = [];
  trainersList: number[] = [];
  quantity: number;
  description: string;
  coverImage: string;
  thumbnail: string;
  materiallink: string;
  startDate: string;
  endDate: string;
  startHours: string;
  temary: string;
  endHours: string;
  price: number;
  minStartDate: string;
  place: string;
  isEdit: boolean;
  private IMG_URL = environment.IMG_URL;
  private containFile: boolean = false;
  private containFileCover: boolean = false;
  file: FileList;
  fileCover: FileList;
  loading = true;
  imageIsChanged = false;
  imageIsChangedCover = false;
  minPlaza: number = 0;

  ngOnInit(): void {
    this.allCategories();
    this.allTrainers();
    this.minStartDate = this.datepipe.transform(new Date().toISOString(), 'yyyy-MM-dd');
    console.log(this.minStartDate);
    let id = this.route.snapshot.paramMap.get("id");
    if (id != null) {
      let valoresAceptados = /^[0-9]+$/;
      if (id.match(valoresAceptados)) {
        this.getCapacitation(parseInt(id));
        this.trainingId = parseInt(id);
        this.isEdit = true;
      } else {
        if (id.includes('dup')) {
          const subs = id.split('-');
          console.log(subs);
          if (subs[0].match(valoresAceptados)) {
            this.getCapacitation(parseInt(subs[0]));
            this.trainingId = parseInt(subs[0]);
            this.isEdit = false;
          } else {
            console.log('No Aceptado');
            this.router.navigateByUrl('/404');
          }
        } else {
          console.log('No Aceptado');
          this.router.navigateByUrl('/404');
        }

      }

    }
    this.filename = 'Nombre del archivo.JPG';
    this.filename2 = 'Nombre del archivo.JPG';

  }

  saving: boolean = false;

  editCapacitation() {
    this.checkIfTheCourseExists();
  }
  urlImg: string  = '-';
  urlImgCover: string  = '-';

  save(){
    this.saving = true;
    if (this.isEdit) {
      let edit: EditCapacitationModel  = new EditCapacitationModel();
      edit.CategoriesID = this.trainingForm.value.categorie;
      edit.Description = this.trainingForm.value.description;
      edit.EndDate = this.trainingForm.value.endDate;
      edit.EndHours = this.trainingForm.value.endHour;
      edit.Name = this.trainingForm.value.name;
      edit.Price = this.trainingForm.value.price;
      edit.Quantity = this.trainingForm.value.quantity;
      edit.StartDate = this.trainingForm.value.startDate;
      edit.StartHours = this.trainingForm.value.startHour;
      edit.UserTrainerID = this.trainingForm.value.trainer;
      edit.MaterialLink = this.trainingForm.value.materiallink;
      edit.Temary = this.trainingForm.value.temary;
      edit.Place = this.trainingForm.value.place;
      edit.IsCertification = this.trainingForm.value.certification;
      if (this.imageIsChangedCover) {
        edit.CoverImage = this.urlImgCover;
      } else {
        edit.CoverImage = this.capacitation.coverImage;
      }
      if (this.imageIsChanged) {
        edit.Thumbnail = this.urlImg;
      } else {
        edit.Thumbnail = this.capacitation.thumbnail;
      }
      edit.Visibility = this.trainingForm.value.visibility;
      // Editar
      this.core.editCapacitation(this.trainingId, edit).subscribe((data: any) => {
        this.saving = true;
      }, error => {
        this.saving = false;
        if (error.error.code == 19) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ocurrio un error al crear el link de zoom, por favor intente nuevamente',
            showConfirmButton: false,
            timer: 1500
          });
        }
        else if (error.status == 401) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.router.navigateByUrl('/500');
        }
      }, () => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Se ha editado la capacitación con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        this.router.navigateByUrl('/capacitaciones');
      });
    } else {
      let duplicate: DuplicateCapacitationModel  = new DuplicateCapacitationModel();
      duplicate.CategoriesID = this.trainingForm.value.categorie;
      duplicate.CoverImage = this.trainingForm.value.coverImage;
      duplicate.Description = this.trainingForm.value.description;
      duplicate.EndDate = this.trainingForm.value.endDate;
      duplicate.EndHours = this.trainingForm.value.endHour;
      duplicate.Name = this.trainingForm.value.name;
      duplicate.Price = this.trainingForm.value.price;
      duplicate.Quantity = this.trainingForm.value.quantity;
      duplicate.StartDate = this.trainingForm.value.startDate;
      duplicate.StartHours = this.trainingForm.value.startHour;
      duplicate.Thumbnail = this.trainingForm.value.thumbnail;
      duplicate.UserTrainerID = this.trainingForm.value.trainer;
      duplicate.SKU = this.capacitation.sku;
      duplicate.Place = this.trainingForm.value.place;
      duplicate.MaterialLink = this.trainingForm.value.materiallink;
      duplicate.Temary = this.trainingForm.value.temary;
      duplicate.IsCertification = this.trainingForm.value.certification;
      if (this.imageIsChangedCover) {
        duplicate.CoverImage = this.urlImgCover;
      } else {
        duplicate.CoverImage = this.capacitation.coverImage;
      }
      if (this.imageIsChanged) {
        duplicate.Thumbnail = this.urlImg;
      } else {
        duplicate.Thumbnail = this.capacitation.thumbnail;
      }

      // Duplicar
      this.core.duplicateCapacitation(duplicate).subscribe((data: any) => {
        this.saving = true;
      }, error => {
        this.saving = false;
        debugger
        if (error.error.code == 19) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Ocurrio un error al crear el link de zoom, por favor intente nuevamente',
            showConfirmButton: false,
            timer: 1500
          });
        } else if (error.status == 401) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.router.navigateByUrl('/500');
        }
      }, () => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Se ha duplicado la capacitación con éxito',
          showConfirmButton: false,
          timer: 1500
        });
        this.router.navigateByUrl('/capacitaciones');
      });
    }
  }
  checkIfTheCourseExists(){
    const courseName = this.trainingForm.get('name').value;
    const id = this.trainingId;
    this.core.existingCourse(courseName, id).subscribe(res => {
      if (res.data) this.alertExistingCourse();
      else this.save();
    });
  }
  alertExistingCourse(){
    Swal.fire({
      position: 'top-end',
      icon: 'warning',
      title: 'No se puede guardar capacitaciones con mismo nombre',
      showConfirmButton: false,
      timer: 2500
    });
  }
  uploadFileCover() {
    // Sube imagen CoverImage
    if (this.containFileCover) {
      this.core.uploudFile(this.fileCover[0] ? this.fileCover[0] : null).subscribe((data: any) => {
        this.urlImgCover = `${this.IMG_URL}${data.data}`;
        console.log(this.urlImgCover);
      }, (error: any) => {
        this.containFileCover = false;
        this.filename2= 'Nombre del archivo.JPG';
        if (error.error.code == 4) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Archivo no admitido para la imagen de portada',
            showConfirmButton: false,
            timer: 1500
          });
        }
        if (error.status == 401 && error.error.code != 4) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.router.navigateByUrl('/500');
        }
        console.error(error);
        this.imageIsChangedCover = false;
        return;
      }, () => {
        this.imageIsChangedCover = true;
      });
    } else {
      this.urlImgCover = '-';
    }
  }

  uploadFile() {
    if (this.containFile) {
      this.core.uploudFile(this.file[0] ? this.file[0] : null).subscribe((data: any) => {
        this.urlImg = `${this.IMG_URL}${data.data}`;
        console.log(this.urlImg);
      }, (error: any) => {
        this.filename= 'Nombre del archivo.JPG';
        this.containFile = false;
        if (error.error.code == 4) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Archivo no admitido para la foto miniatura',
            showConfirmButton: false,
            timer: 1500
          });
        }
        if (error.status == 401 && error.error.code != 4) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.router.navigateByUrl('/500');
        }
        console.error(error);
        this.imageIsChanged = false;
        return;
      }, () => {
        this.imageIsChanged = true;
      });
    } else {
      this.urlImg = '-';
    }
  }

  getCapacitation(id: number) {
    this.core.showCapacitation(id).subscribe((data: any) => {
      this.loading = true;
      this.capacitation = data.data;
      console.log(this.capacitation);
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.router.navigateByUrl('/500');
      }
    }, () => {
      this.name = this.capacitation.name;
      this.temary = this.capacitation.temary;
      this.description = this.capacitation.description;
      this.coverImage = this.capacitation.coverImage;
      this.thumbnail = this.capacitation.thumbnail;
      this.startDate = this.datepipe.transform(this.capacitation.startDate, 'yyyy-MM-dd');
      this.endDate = this.datepipe.transform(this.capacitation.endDate, 'yyyy-MM-dd');
      this.startHours = this.datepipe.transform(this.capacitation.startHours, 'HH:mm');
      this.endHours = this.datepipe.transform(this.capacitation.endHours, 'HH:mm');
      this.price = this.capacitation.price;
      this.materiallink = this.capacitation.materialLink;
      this.quantity = this.capacitation.quantity;
      this.place = this.capacitation.place;
      this.capacitation.categories.forEach(element => {
        this.categoriesList.push(element.id);
      });
      this.capacitation.userTrainer.forEach(element => {
        this.trainersList.push(element.id);
      });
      this.trainingForm.get('categorie').setValue(this.categoriesList);
      this.trainingForm.get('trainer').setValue(this.trainersList);
      this.loading = false;

      // Hago uso del formulario reactivo
      this.trainingForm.get('visibility').setValue(this.capacitation.visibility);
      this.trainingForm.get('certification').setValue(this.capacitation.isCertification);
    });
  }

  allCategories() {
    this.core.getCategories().subscribe((data: any) => {
      console.log(data.data);
      this.categories = data.data;
      console.log(this.categories);
    });
  }

  allTrainers() {
    this.core.getAllTrainersUsers().subscribe((data: any) => {
      console.log(data.data);
      this.trainers = data.data;
      console.log(this.trainers);
    });
  }

  filechange(files: FileList) {
    this.file = files;
    this.filename = files[0].name;
    this.containFile = true;
    this.uploadFile();
  }

  filechange2(files: FileList) {
    this.fileCover = files;
    this.filename2 = files[0].name;
    this.containFileCover = true;
    this.uploadFileCover();
  }

  goToTrainings() {
    this.router.navigateByUrl('/capacitaciones')
  }

  starDateError: boolean = false;
  endDateError: boolean = false;
  checkDates(event, isStartDate: boolean = true){
    if (isStartDate) {
      const date = new Date();
      if (event < this.datepipe.transform(date, 'yyyy-MM-dd')) {
        if (this.isEdit) {
          this.starDateError = false;
        } else {
          this.starDateError = true;
        }
      } else {
        this.starDateError = false;
        if(this.endDate < this.startDate){
          this.endDateError = true;
        } else {
          this.endDateError = false;  
        }
      }
    } else {
      if (event < this.startDate) {
        this.endDateError = true;
      } else {
        this.endDateError = false;
      }
    }
  }

  starHourError: boolean = false;
  endHourError: boolean = false;
  checkHours(event, isStartHour: boolean = true){
    if (isStartHour) {
      if (this.endHours <= this.startHours) {
        this.endHourError = true;
      } else {
        this.endHourError = false;
      }
    } else {
      if (event <= this.startHours) {
        this.endHourError = true;
      } else {
        this.endHourError = false;
      }
    }
  }
  minPlazaError: boolean = false;
  changeVisibility(event: boolean) {
    if (event) {
      if (this.trainingForm.value.quantity === 0) {
        this.minPlazaError = true;
      } else {
        this.minPlazaError = false;
      }
    } else {
      if (this.trainingForm.value.quantity === 0) {
        this.minPlazaError = false;
      } else {
        this.minPlazaError = false;
      }
    }
  }

  changeVisibilityPlaza(event: number) {
    if (this.trainingForm.value.visibility) {
      if (event === 0) {
        this.minPlazaError = true;
      } else {
        this.minPlazaError = false;
      }
    } else {
      if (event === 0) {
        this.minPlazaError = false;
      } else {
        this.minPlazaError = false;
      }
    }
  }

  numPattern = /^\d+$/;
  spacePettern = /^[^-\s][a-zA-Z0-9_\s-]+$/;

  // Formulario reactivo
  // tslint:disable-next-line: member-ordering
  trainingForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(100), Validators.minLength(5)]],
    description: ['', [Validators.required]],
    categorie: ['', [Validators.required]],
    trainer: ['', [Validators.required]],
    thumbnail: [''],
    coverImage: [''],
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]],
    startHour: ['', [Validators.required]],
    endHour: ['', [Validators.required]],
    quantity: ['', [Validators.required]],
    price: ['', [Validators.required]],
    visibility: ['', [Validators.required]],
    materiallink: [''],
    temary: ['', Validators.required],
    place: ['', Validators.required],
    certification: ['']
  });

  // Configuración del editor

  // tslint:disable-next-line: member-ordering
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertVideo', 'insertImage', 'customClasses']
    ]
};

}
