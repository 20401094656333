import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: number;

  @Input() totalPages: number;

  @Input() numItems: number;

  @Output() paginaEmitter: EventEmitter<number> =  new EventEmitter();

  constructor() { }

  total = []
  numberOfItems: number;

  ngOnInit() {
    this.numberOfItems = this.totalPages / 5;
    if (this.totalPages > 5) {
      for (let index = 1; index <= 5; index++) {
        if (index <= this.totalPages) {
          this.total.push(index);
        }
      }
    } else {
      for (let index = 1; index <= this.totalPages; index++) {
        this.total.push(index);
      }
    }


  }

  siguiente(){
    if (this.page < this.totalPages) {
      this.page++;
      this.pasarPagina();
    }
  }

  anterior(){
    if (this.page > 1) {
      this.page--;
      this.pasarPagina();
    }
  }

  selectPage(num: number) {
    this.page = num;
    if (this.totalPages > 5) {
      if (num == this.total[this.total.length]) {
        this.total = [];
        for (let index = num; index <= 5; index++) {
          if (index <= this.totalPages) {
          this.total.push(index);
          }

        }
      }
    }
    this.pasarPagina();
  }

  pasarPagina(){

    this.paginaEmitter.emit(this.page);

  }
}
