import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';

import jwt_decode from 'jwt-decode';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-buyers',
  templateUrl: './list-buyers.component.html',
  styleUrls: ['./list-buyers.component.scss']
})
export class ListBuyersComponent implements OnInit {

  page: number = 1;
  capacitationId: string;
  maxForms: number = 0;
  modalRef: BsModalRef;
  isValid = true;
  searchText: string;
  isMax = false;
  isDate = false;
  data: any;
  isEnable = false;
  currentForm: number = 0;
  formId: string;
  loading: boolean = false;
  capacitationName: string;
  notResult: boolean = false;
  buyers = [];
  allBuyers = [];
  newEmail: string;
  buyer: any;
  isEdit: boolean = false;


  constructor(private modalService: BsModalService,private route: Router, private core: CoreService, private routes: ActivatedRoute) { }

   ngOnInit(): void {
    let capacitacionId = this.routes.snapshot.paramMap.get("id");
  
        this.capacitationId = capacitacionId;
      //  this.formId = decoded.formId;
      //  this.maxForms = decoded.max;
    //   this.AllCountries();
        this.getCapacitationNameById(capacitacionId);
       this.getAllBuyer(capacitacionId);
      
  }

  getAllBuyer(id: any) {
    this.core.getBuyer(id).subscribe(res => {
      var data: any = res;
      this.buyers = data.data
      this.allBuyers = data.data;
    })
  }

  getCapacitationNameById(id){
    this.core.getNameCapacitation(id).subscribe((data:any) => {
      this.capacitationName = data.data;
    });
  }

  showBuyer(id: number, template: TemplateRef<any>) {
    this.buyers.forEach(element => {
      if (element.id === id) {
        this.buyer = element;
        this.newEmail = this.buyer.email
      }
    });
    this.modalRef = this.modalService.show(template);
  }

  openModal(template: TemplateRef<any>) {

    this.modalRef = this.modalService.show(template);
}


editEmail(list: any) {
  list.isEdit = true;
  list.newEmail = list.email;
}

onCancel(list: any) {
  list.isEdit = false;
}

search(evt) {
  if(this.searchText.length > 2){
  let aFilter = [];

  for(let item of this.allBuyers) {
    if(item.completedName.toUpperCase().includes(this.searchText.toUpperCase()) || 
    item.email.toUpperCase().includes(this.searchText.toUpperCase())){
      aFilter.push(item);
    }
  }
  
    this.buyers = aFilter;
  }else{
    this.buyers = this.allBuyers;
  }
  
}

onChangeEmail(evt, i) {
  i.isLoading = true;
  let body = {
    email: i.email,
     newEmail: i.newEmail,
     capacitationId: Number(this.capacitationId) }
  let id = i.id;
  this.core.updateEmailBuyer(body).subscribe( res => {
    i.isEdit = false;
    i.isLoading = false;
    i.email = i.newEmail;
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: `Se ha actualizado correctamente el email de ${i.completedName}`,
      showConfirmButton: false,
      timer: 1500
    });
  }, err => {
    i.isEdit = false;
    i.isLoading = false;
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: `El email ya existe`,
      showConfirmButton: false,
      timer: 1500
    });
  })
}

}
