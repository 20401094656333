<app-header></app-header>
<div *ngIf="!goToSend" class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4 justify-content-start">
    <div class="col-auto align-self-center">
      <h2 style="margin: 0;">Certificados</h2>
    </div>
    <div style="padding-left: 0;" class="col-auto align-self-center">
      <p style="font-size: 17px; padding: 0; margin: 0;"><i tooltip="Seleccione un modelo de certificado y ajustelo"
          placement="bottom" class="far fa-question-circle"></i></p>
    </div>
    <div style="padding-left: 0;" class="col-auto align-self-center">
      <h6 *ngIf="disableItems" style="padding: 0; margin: 0;">Seleccione el modelo/tipo de certificado</h6>
      <h6 *ngIf="!disableItems && !certForm.valid" class="animate__animated animate__bounceIn animate__fast" style="padding: 0; margin: 0;">Seleccione una capacitación y suba las imágenes</h6>
      <h6 *ngIf="certForm.valid" class="animate__animated animate__bounceIn animate__fast" style="padding: 0; margin: 0;">Haga click en "Ver vista previa" o "Generar certificado"</h6>
    </div>
  </div>
  <div class="row mt-4">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 mt-4 mr-3">
          <div (click)="selectedCert(0)" class="cert-1">
            <p *ngIf="certSelected[0]" class="text-right mr-3 pt-2 cert-selected"><i
                class="fas fa-check animate__animated animate__bounceIn"></i></p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mr-3">
          <div (click)="selectedCert(1)" class="cert-2">
            <p *ngIf="certSelected[1]" class="text-right mr-3 pt-2 cert-selected"><i
                class="fas fa-check animate__animated animate__bounceIn"></i></p>
          </div>
        </div>
        <div class="col-lg-3 mt-4 mr-3">
          <div (click)="selectedCert(2)" class="cert-3">
            <p *ngIf="certSelected[2]" class="text-right mr-3 pt-2 cert-selected"><i
                class="fas fa-check animate__animated animate__bounceIn"></i></p>
          </div>
        </div>
      </div>
      <div class="row mt-5 animate__animated animate__fadeIn animate__fast" *ngIf="!disableItems">

        <form [formGroup]="certForm" style="width: 100%;">
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="endDate">Fecha <span><i tooltip="Filtra capacitaciones por fecha de finalización" triggers="mouseenter:mouseleave"
                  placement="top" class="far fa-question-circle"></i></span></label>
                <dirmod-month-picker id="endDate" [selectedDate]="actualDate" (endDate)="changeDate($event)" [ngModelOptions]="{standalone: true}"></dirmod-month-picker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="firmasLabel">Capacitaciones*</label>
                <ng-select ngModel [ngModelOptions]="{standalone: true}" [loading]="loadingCapacitations" required notFoundText="No hay capacitaciones finalizadas o con alumnos"  formControlName="CapacitationId" [items]="capacitations"
                  bindLabel="name" bindValue="id" placeholder="Seleccionar" appendTo="body" class="custom-filter-2"
                  [(ngModel)]="selectedCurso" (change)="changeCap()">
                </ng-select>
                <div *ngIf="certForm.controls.CapacitationId.invalid && (certForm.controls.CapacitationId.dirty || certForm.controls.CapacitationId.touched)">
                  <div class="text-danger" style="font-size: 12px;" *ngIf="certForm.controls.CapacitationId.errors?.required">
                    La capacitación es requerida.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6" style="z-index: 999999;">
              <label for="validatedCustomFile">Firma* <span><i [popover]="helpFirm" triggers="mouseenter:mouseleave"
                placement="top" class="far fa-question-circle"></i></span></label>
              <input ngModel name="Firm" required [ngModelOptions]="{standalone: true}" formControlName="Firm" #fileInputFirm type="file"
                (change)="filechangeFirm(fileInputFirm.files)" class="custom-file-input" accept="image/x-png"
                id="validatedCustomFile" lang="es">
              <label class="custom-file-label" for="validatedCustomFile" data-browse="Seleccionar">{{filenameFirm}}</label>
              <div *ngIf="certForm.controls.Firm.invalid && (certForm.controls.Firm.dirty || certForm.controls.Firm.touched)">
                <div class="text-danger mt-2" style="font-size: 12px;" *ngIf="certForm.controls.Firm.errors?.required">
                  La imagen de los firma es requerida.
                </div>
              </div>
            </div>
            <div class="col-6">
              <label for="validatedCustomFile">Logos de sponsors <span><i [popover]="helpSpon" triggers="mouseenter:mouseleave"
                placement="top" class="far fa-question-circle"></i></span></label>
              <input ngModel name="University" [ngModelOptions]="{standalone: true}" formControlName="Logos" #fileInputLogo type="file"
                (change)="filechangeLogo(fileInputLogo.files)" class="custom-file-input" accept="image/x-png"
                id="validatedCustomFile" lang="es">
              <label class="custom-file-label" for="validatedCustomFile" data-browse="Seleccionar">{{filenameLogo}}</label>

            </div>
            <div *ngIf="type == 2" class="col-6 animate__animated animate__fadeIn animate__fast">
              <label for="validatedCustomFile" >Logo de universidad <span><i [popover]="helpUni" triggers="mouseenter:mouseleave"
                placement="bottom" class="far fa-question-circle"></i></span></label>
              <input ngModel name="University" [ngModelOptions]="{standalone: true}" formControlName="University" #fileInputUni type="file"
                (change)="filechangeUni(fileInputUni.files)" class="custom-file-input" accept="image/x-png"
                id="validatedCustomFile" lang="es">
              <label class="custom-file-label" for="validatedCustomFile" data-browse="Seleccionar">{{filenameUni}}</label>

            </div>
          </div>
        </form>
      </div>
      <div class="row justify-content-center mt-4">
      </div>
      <div *ngIf="!disableItems && !showView" class="row justify-content-center mt-4">
        <a style="cursor: pointer;" class="go-back animate__animated animate__fadeIn animate__fast"
          (click)="showLiveView()" *ngIf="certForm.valid">Ver vista previa</a>
      </div>
      <div *ngIf="loadingLive" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
        <div class="spin"></div>
      </div>
      <div *ngIf="showView" class="row justify-content-center mt-4 ">
        <img class="animate__animated animate__fadeIn animate__fast" style="width: 70%;" [src]="imageToShow" alt="">
      </div>
      <div class="row justify-content-center mt-4">
        <button class="btn btn-cert" *ngIf="certForm.valid" (click)="generateCert()">Generar Certificado</button>
      </div>
    </div>
  </div>
  <div class="row mt-4 justify-content-center mb-5">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>
<app-students-send *ngIf="goToSend" [cert]="certification" (goBack)="changeView($event)"></app-students-send>

<ng-template #helpFirm>
  <div class="container-fluid" style="padding: 0;">
    <div class="row">
      <div class="col-5">
        <div style="width: 100%; height: 110px;" class="img-firm"></div>
      </div>
      <div class="col-7">
        <div class="container-fluid">
          <div class="row mb-2">
            <small style="font-weight: bold;">La imagen debe contar con<br>la firma más la aclaración debajo.<br>Todo en una misma imagen.</small>
          </div>
          <div class="row">
            <small>Formato: .PNG<br>Tamaño max.: 2MB<br>Dimensiones: 990px x 448px (obligatorio)</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #helpUni>
  <div class="container-fluid" style="padding: 0; z-index: 9999;">
    <div class="row">
      <div class="col-5">
        <div style="width: 100%; height: 110px;" class="img-uni"></div>
      </div>
      <div class="col-7">
        <div class="container-fluid">
          <div class="row mb-2">
            <small style="font-weight: bold;">Se recomienda respetar el tamaño especificado.</small>
          </div>
          <div class="row">
            <small>Formato: .PNG<br>Tamaño max.: 2MB<br>Dimensiones: 842px x 489px (obligatorio)</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #helpSpon style="width: 400px !important;">
  <div class="container-fluid" style="padding: 0;">
    <div class="row justify-content-center">
      <div style="width: 90%; height: 110px;" class="img-spon"></div>
    </div>
    <div class="row justify-content-center">
      <div class="col-6">
        <small>Formato: .PNG<br>Tamaño max.: 2MB<br>Dimensiones: 2727px x 395px (obligatorio)</small>
      </div>
      <div class="col-6">
        <small style="font-weight: bold;">Todos los sponsors en una misma imagen</small>
      </div>
    </div>
  </div>
</ng-template>
