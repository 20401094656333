<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
    <div class="col-10">
      <h2>Calendario</h2>
    </div>
    <div class="col-2 text-end">
      <button *ngIf="!loading" (click)="crearImagen()" tooltip="Descargar imagen PNG" class="btn btn-download animate__animated animate__fadeIn animate__faster"><i class="fas fa-file-download"></i>&nbsp;&nbsp;Descargar</button>
    </div>
  </div>
  <div class="row mt-4 justify-content-center animate__animated animate__fadeIn animate__faster">
    <div class="col-sm-4">
      <form *ngIf="isAdmin" >
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <select (change)="filterCapacitation($event)" [disabled]="loadingFilters" name="test" class="select-filter" id="capacitations">
                <option selected value="all">Capacitación</option>
                <option [value]="item.name" *ngFor="let item of capacitationsFilter">{{item.name}}</option>
              </select>
            </div>
            <div class="col-6">
              <select (change)="filterTrainers($event)" [disabled]="loadingFilters" name="test" class="select-filter" id="trainers">
                <option selected value="all">Capacitador</option>
                <option [value]="item.id" *ngFor="let item of trainers">{{item.fullName}}</option>
              </select>
              <!-- <ng-select class="custom-filter" (clear)="getAllCapacitations()" (change)="filterTrainers($event)" [loading]="loadingFilters" [items]="trainers" bindValue="id" bindLabel="firstName" placeholder="Capacitador"></ng-select> -->
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-4">
      <div *ngIf="!loading" class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-2 mt-2 text-right ">
            <a style="cursor: pointer;" mwlCalendarPreviousView [(viewDate)]="viewDate" [view]="view"><i class="arrow fas fa-chevron-left"></i></a>
          </div>
          <div class="col-8 text-center">
            <h3 class="month">{{viewDate | calendarDate:(view + 'ViewTitle'):'ES' | uppercase}}</h3>
          </div>
          <div class="col-2 mt-2 text-left">
            <a style="cursor: pointer;" mwlCalendarNextView [(viewDate)]="viewDate" [view]="view"><i class="arrow fas fa-chevron-right"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4">
      <form *ngIf="!loading" name="form" #f="ngForm" novalidate>
        <div class="form-group">
            <div class="switch-toggle switch-3 switch-ios">
                <input id="on" name="state-d" type="radio" checked="checked">
                <label for="on" (click)="changeView(0)">Mes</label>

                <input id="na" name="state-d" type="radio">
                <label for="na" (click)="changeView(1)">Semana</label>

                <input id="off" name="state-d" type="radio">
                <label for="off" (click)="changeView(2)">Día</label>

                <a></a>
            </div>
        </div>
    </form>
    </div>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="!loading" class="row mt-4 justify-content-center">
    <div id="contenido" class="container-fluid">
      <div [ngSwitch]="view" class="animate__animated animate__fadeIn animate__faster">
        <mwl-calendar-month-view
          *ngSwitchCase="'month'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          (dayClicked)="dayClicked($event.day)"
          [activeDayIsOpen]="activeDayIsOpen"
          (eventClicked)="eventClicked($event, template)"
          style="z-index: 0 !important;"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="'week'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          (dayClicked)="dayClicked($event.day)"
          [activeDayIsOpen]="activeDayIsOpen"
          (eventClicked)="eventClicked($event, template)"
          style="z-index: 0 !important;"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="'day'"
          [viewDate]="viewDate"
          [events]="events"
          [locale]="locale"
          (dayClicked)="dayClicked($event.day)"
          [activeDayIsOpen]="activeDayIsOpen"
          (eventClicked)="eventClicked($event, template)"
          style="z-index: 0 !important;"
        >
        </mwl-calendar-day-view>
      </div>
    </div>
  </div>
  <div class="row mt-4 mb-4 justify-content-center">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>

<ng-template  #template>

  <div style="z-index: 999999;" class="modal-body" >
    <button class="btn btn-close" style="z-index: 999 !important;" (click)="modalRef.hide()">Cerrar <i class="far fa-times-circle"></i></button>
    <div style="background-image: url(../../../assets/construccion.jpg);" class="cover"></div>
    <div class="container-fluid mt-3 mr-2">
      <div class="row justify-content-start">
        <div class="col-auto">
          <img src="../../../assets/Barbieri-Logo-MarcaGrafica.png" class="thumbnail">
        </div>
        <div class="col-auto">
          <div class="card " style="margin-top: 13px;">
              <div class="container-fluid" style="margin-top: 7px;">
                <div class="row">
                  <div class="col-auto align-content-center align-self-center">
                    <p style="font-size: 20px;"><i class="far fa-user-circle"></i></p>
                  </div>
                  <div class="col-auto align-content-center align-self-center">
                    <p style="line-height: 15px;">{{capacitation.currentStudents}} <br><small>inscriptos</small></p>
                  </div>

                </div>
              </div>
          </div>
        </div>
        <div *ngIf="capacitation.linkZoom != null" class="col-auto align-self-center">
          <a tooltip="Haz click para abrir en Zoom" [href]="capacitation.linkZoom" target="_blank"><img style="width: 120px;" src="../../../assets/Zoom-VideoConferencias-logo.png"></a>
        </div>
      </div>
      <div class="row justify-content-start mt-3">
        <h2 style="font-weight: bold; color: white; z-index: 999;">{{capacitation.name}} <span><p style="font-size: 12px;">SKU: {{capacitation.sku}}</p></span></h2>
      </div>
      <div class="row justify-content-start">
        <div class="col-auto" *ngFor="let item of capacitation.userTrainer">
          <div class="card custom-card">
            <div class="container-fluid">
              <div class="row">
                <p style="color: black; line-height: 15px; font-size: 20px; margin: 0; margin-top: 7px; margin-left: 7px;">{{item.firstName}} {{item.lastName}}<br><small style="font-size: 12px;">Capacitador</small></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-start mt-5">
        <div class="col-auto" *ngFor="let item of capacitation.categories">
          <div class="card custom-card-category">
            <p style="margin-top: 5px; text-align: center;">{{item.name}}</p>
          </div>
        </div>
      </div>
      <div *ngIf="capacitation.categories.length != 0" class="row justify-content-start ml-3 mt-2">
        <p>Categoría/as</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto">
          <p style="color: #606060; text-align: center;"><i class="far fa-calendar-check"></i> Fecha de inicio <br><span style="color: black; font-size: 16px;">{{capacitation.startDate | date:'dd/MM/YYYY'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;"><i class="far fa-calendar-check"></i> Fecha de cierre <br><span style="color: black; font-size: 16px;">{{capacitation.endDate | date:'dd/MM/YYYY'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;"><i class="far fa-clock"></i> Horario <br><span style="color: black; font-size: 16px;">{{capacitation.startHours | date:'HH:mm'}} / {{capacitation.endHours | date:'HH:mm'}}</span></p>
        </div>
        <div class="col-auto">
          <p style="color: #606060; text-align: center;">Precio <br><span style="color: black; font-size: 16px;">{{capacitation.price | currency:'$'}}</span></p>
        </div>

      </div>
      <div class="row justify-content-start">
        <p style="margin-bottom: 0;">Descripción</p>
      </div>
      <div class="row justify-content-center">
        <div class="card card-desc">
          <div  [innerHTML]="loadHtml()" style="padding: 5px;"></div>

        </div>
      </div>
    </div>
  </div>
</ng-template>
