<p>list-buyers works!</p>
<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
    <div class="col-10">
      <h2>Listado de Compradores</h2>
    </div>
    <div *ngIf="!loading && !notResult" class="col-auto">
      <div class="container-fluid mt-2">
        <div class="row justify-content-right text-right">
         
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="!loading && notResult" class="row mt-5 mb-5 justify-content-center animate__animated animate__fadeIn animate__faster">
    <h3 class="text-cneter">No existen compradores para esta capacitación</h3>
  </div>
  <div *ngIf="!loading && !notResult" class="row mt-4 animate__animated animate__fadeIn animate__faster">
    <div class="col-md-8">
      <div class="container">
        <div class="row">

        </div>
      </div>
    </div>
    <div class="col-md-4">
      <form style="width: 100%;">
        <div class="form-row align-items-center">
          <input type="search" class="form-control search-bar" placeholder="Buscar" name="buscar" [(ngModel)]="searchText" (ngModelChange)="search()" id="buscar">
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="!loading && !notResult" class="row mt-4 animate__animated animate__fadeIn animate__faster">
    <div class="container-fluid">
      <table class="table">
        <thead>
          <tr>
            <th style="color: #022A3A;" scope="col" (click)="orderByName()">Comprador  <span *ngIf="isOrderByName"><i class="fas fa-chevron-up"></i></span><span *ngIf="!isOrderByName"><i class="fas fa-chevron-down"></i></span></th>
            
            <th style="color: #022A3A;" scope="col" >Número de Documento</th>
            <th style="color: #022A3A;" scope="col" >E-Mail </th>
            <th style="color: #022A3A;" scope="col" >Dirección </th>

         
          </tr>
        </thead>
        <tbody>
          <tr  class="info-row" *ngFor="let i of buyers | paginate: {itemsPerPage: 10, currentPage: page}">

            <td>{{i.completedName}}</td>
            <td>{{i.documentNumber}}</td>
            <td> 
              <a class="widthEmail" *ngIf="!i.isEdit" style="color: #e3000b;" href="mailto:{{i.email}}">{{newEmail ? newEmail : i.email}}</a>
              <input *ngIf="i.isEdit" type="text" [(ngModel)]="i.newEmail" [disabled]="i.isLoading">

              <button *ngIf="!i.isEdit" class="editemail" (click)="editEmail(i)"><i class="fas fa-edit"></i></button> 
              <button *ngIf="i.isEdit && !i.isLoading" class="successemail" (click)="onChangeEmail($event, i)"><i class="fas fa-check"></i></button>
              <button *ngIf="i.isEdit && !i.isLoading" class="erroremail" (click)="onCancel(i)"><i class="fas fa-cancel"></i></button>
              <button *ngIf="i.isLoading" class="outline"><div class="spin customSpin"></div> </button>
            </td>
            <td>{{i.address}}, {{i.city}}</td>
           
          </tr>
          <br>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!loading && !notResult" class="row mt-4 justify-content-center animate__animated animate__fadeIn animate__faster">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
  <div class="row justify-content-center mb-5">
    <a routerLink="/capacitaciones" class="go-back text-center">Volver a Capacitaciones</a>
  </div>
</div>


