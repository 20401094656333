import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CapacitationModel } from 'src/app/models/capacitation';
import { CategorieModel } from 'src/app/models/categorie';
import { StatusModel } from 'src/app/models/status';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/services/storage.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent implements OnInit {

  page: number = 1;
  isAdmin: boolean;
  constructor(private storage: StorageService, private route: Router, private core: CoreService, private modalService: BsModalService, private dom: DomSanitizer, private auth: AuthService) { }

  date: Date;
  status: string;
  cat: string;
  searchText: string;
  order: string;
  start: Date = null;
  end: Date = null;
  isEnable = false;
  isOrderByName = false;
  isOrderByStatus = false;
  isOrderByCategory = false;
  isDate = false;
  @ViewChild('divID') divID: ElementRef;
  categories: CategorieModel[];
  statusall: StatusModel[];
  capacitations: CapacitationModel[];
  copyCapacitations: CapacitationModel[];
  capacitation: CapacitationModel;
  error: boolean = false;

  loading: boolean = true;
  disableFilters = true;
  modalRef: BsModalRef;

  loadHtml(){
    return this.dom.bypassSecurityTrustHtml(this.capacitation.description);
  }
  ngOnInit(): void {
    let decoded: any = jwt_decode(this.storage.getToken());
    if (decoded.role == 'Administrador') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.allCategories();
    this.allStatus();
    this.allCapacitations();
  }

  allCategories() {
    debugger
    this.core.getCategories().subscribe((data: any) => {
      console.log(data.data);
      this.categories = data.data;
      console.log(this.categories);
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.disableFilters = false;
    });
  }

  allStatus() {
    this.core.allStatus().subscribe((data: any) => {
      console.log(data.data);
      this.statusall = data.data;
      console.log(this.statusall);
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.disableFilters = false;
    });
  }
  html: any;
  showCapacitation(id: number, template: TemplateRef<any>) {
    this.core.showCapacitation(id).subscribe((data: any) => {
      this.capacitation = data.data;
      console.log(this.capacitation);
      this.modalRef = this.modalService.show(template,
        Object.assign({}, { class: 'modal-xl' })
      );
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    });
  }

  delete(id: number, name: string) {
    
    Swal.fire({
      title: 'Eliminar Capacitación',
      text: `¿Está seguro que desea eliminar el capacitación ${name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E3000B',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        this.core.deleteCapacitation(id).subscribe((data: any) => {
          console.log(data);
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'La capacitación ha sido borrada correctamente',
            showConfirmButton: false,
            timer: 1500
          });
          this.capacitations = [];
          this.allCapacitations();
        }, (error: any) => {
          if (error.status = 401) {
            this.auth.refreshToken();
            this.delete(id, name);
          } else if (error.status = 500) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'La capacitación no ha sido borrado correctamente debido a un error interno',
              showConfirmButton: false,
              timer: 1500
            });
          }
          console.error(error.status);
        });

      }
    });
  }

  allCapacitations() {
    this.loading = true;
    this.capacitations = [];
    this.core.allCapacitations().subscribe((data: any) => {
      this.capacitations = data.data;
      this.copyCapacitations = this.capacitations;
    }, error => {
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }

  goToPage(e){
    console.log(e);
  }
  orderByName(){
    if (!this.isEnable) {
      this.isEnable = true;
      this.order = 'name';
      this.isDate = false;
      this.isOrderByName = true;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
      this.order = 'name';
    }

  }

  orderByDate(){
    if (!this.isDate) {
      this.isEnable = false;
      this.isDate = true;
      this.isOrderByName = false;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
    }
  }

  orderByCategory(){
    if (!this.isEnable) {
      this.isEnable = true;
      this.order = 'category';
      this.isDate = false;
      this.isOrderByName = false;
      this.isOrderByStatus = false;
      this.isOrderByCategory = true;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
      this.isOrderByStatus = false;
      this.isOrderByCategory = false;
      this.order = 'category';
    }
  }

  orderByStatus(){
    if (!this.isEnable) {
      this.isEnable = true;
      this.order = 'status';
      this.isDate = false;
      this.isOrderByName = false;
      this.isOrderByStatus = true;
      this.isOrderByCategory = false;
    } else {
      this.isDate = false;
      this.isEnable = false;
      this.isOrderByName = false;
      this.isOrderByStatus = false;
      this.isOrderByCategory = false;
      this.order = 'status';
    }
  }

  goToEdit(id: number){
    this.route.navigateByUrl(`/capacitaciones/editar/${id}`);
  }

  goToDuplicate(id: number) {
    this.route.navigateByUrl(`/capacitaciones/duplicar/${id}-dup`);
  }

  goToStudents(id: number){
    this.route.navigateByUrl(`capacitaciones/alumnos/${id}`);
  }

  goToBuyers(id: number){
    this.route.navigateByUrl(`capacitaciones/compradores/${id}`);
  }

  changeDate(){
    console.log(this.date);
    this.loading = true;
    this.core.filterCapacitationsWithDate(this.date).subscribe((data: any) => {
      this.capacitations = [];
      this.capacitations = data.data;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }


  startDate(){
    this.page= 1;
    if(this.end==null){
      var dateStart = formatDate(this.start, 'yyyy-MM-dd','en_US');
      this.capacitations = this.copyCapacitations.filter(capacitation => formatDate(capacitation.startDate, 'yyyy-MM-dd','en_US') >= dateStart);
    }else{
      this.intervalDate();
    }
  }
  endDate() {
    this.page= 1;
    if(this.start==null){
      var dateEnd = formatDate(this.end, 'yyyy-MM-dd','en_US');
      this.capacitations = this.copyCapacitations.filter(capacitation => formatDate(capacitation.startDate, 'yyyy-MM-dd','en_US') <= dateEnd);
    }else{
      this.intervalDate();
    }
  }

  intervalDate() {
    if(this.start<=this.end)
    {
      var dateStart = formatDate(this.start, 'yyyy-MM-dd','en_US');
      var dateEnd = formatDate(this.end, 'yyyy-MM-dd','en_US');
      
      this.error = false;
      this.capacitations = this.copyCapacitations.filter(capacitation => formatDate(capacitation.startDate, 'yyyy-MM-dd','en_US') >= dateStart 
                                                                          && formatDate(capacitation.startDate, 'yyyy-MM-dd','en_US') <= dateEnd);
    }else{
      this.error = true;
      this.capacitations = [];
    }
  }

  resetFilters(select: any, select2: any){
    select.handleClearClick();
    select2.handleClearClick();
    var date_input = (document.getElementById('date')) as HTMLInputElement;
    //erase the input value
    date_input.value = '';

    this.start= null;
    this.end = null;
    //prevent error on older browsers (aka IE8)
    if (date_input.type === 'date') {
      //update the input content (visually)
      date_input.type = 'text';
      date_input.type = 'date';
    }
    var date_input1 = (document.getElementById('date1')) as HTMLInputElement;

    //erase the input value
    date_input1.value = '';

    //prevent error on older browsers (aka IE8)
    if (date_input1.type === 'date') {
      //update the input content (visually)
      date_input1.type = 'text';
      date_input1.type = 'date';
    }
  }

  changeStatus(event){
    this.loading = true;
    this.status = event.id;
    this.copyCapacitations = [];
    this.capacitations = [];
    this.page= 1;
    // tslint:disable-next-line: radix
    this.core.filterCapacitationsWithStatus(parseInt(this.status)).subscribe((data: any) => {
      this.capacitations = data.data;
      this.copyCapacitations = data.data;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }


  changeCat(event){
    console.log(event);
    this.loading = true;
    this.cat = event.id;
    this.copyCapacitations = [];
    this.capacitations = [];
    this.page= 1;
    // tslint:disable-next-line: radix
    this.core.filterCapacitationsWithCategory(parseInt(this.cat)).subscribe((data: any) => {
      this.capacitations = [];
      this.capacitations = data.data;
      this.copyCapacitations = data.data;
    }, error => {
      console.log(error);
      if (error.status == 401) {
        this.auth.refreshToken();
      } else if (error.status == 500) {
        this.route.navigateByUrl('/500');
      }
    }, () => {
      this.loading = false;
    });
  }

  search() {
    if (this.searchText.length >= 3) {
    this.copyCapacitations = [];
    this.capacitations = [];
    this.page= 1;

      this.loading = true;
      this.capacitations = [];
      this.core.searchCapacitations(this.searchText).subscribe((data: any) => {
        console.log(data);
        this.capacitations = data.data;
      }, error => {
        if (error.status == 401) {
          this.auth.refreshToken();
        } else if (error.status == 500) {
          this.route.navigateByUrl('/500');
        }
      }, () => {
        this.loading = false;
      });
    
    } else if (this.searchText == '') {
      this.capacitations = [];
      this.allCapacitations();
    }
 

  }
}
