<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4 tablet-phone-mode">
    <div class="col-lg-auto">
      <h2>Capacitadores</h2>
    </div>
    <div class="col-lg-6">
      <div class="container-fluid">
        <div class="row justify-content-end-tablet">
          <form class="mobile-search" style="width: 50%;">
            <input class="form-control search-bar" type="search" placeholder="Buscar" name="buscar" id="buscar" (ngModelChange)="search($event)" [(ngModel)]="textToSearch">
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-3 text-mobile-resposive">
      <button class="btn btn-add" routerLink="nuevo"><span><i class="fas fa-plus"></i></span>   Agregar Nuevo Capacitador</button>
    </div>

  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div class="row mt-4 justify-content-center">
    <div class="container">
      <div *ngIf="!notResult || !loading" class="row justify-content-mobile">
        <div *ngFor="let item of trainers | paginate: {itemsPerPage: 6, currentPage: page}" class="col-lg-4 mb-4">
          <div class="card animate__animated animate__fadeIn animate__faster">
            <div class="card-body">
              <div class="container-fluid">
                <div class="row mt-3">
                  <div class="col-4">
                    <img *ngIf="item.photo != null" bind-src="item.photo" class="avatar">
                    <img *ngIf="item.photo == null" src="../../../assets/notPhoto.png" class="avatar">
                  </div>
                  <div class="col-8 mt-3">
                    <p class="name-last">{{item.firstName | slice:0:10}} {{item.lastName | slice:0:15}} </p>
                    <a class="desc" data-toggle="modal" (click)="openModal(template, item.id)">Ver más</a>
                  </div>
                </div>
                <div class="row mt-4 justify-content-center text-center">
                  <div class="col-5">
                    <a class="btn-edit" (click)="goToEdit(item.id)"><span style="font-size: 16px;"><i class="fas fa-pen"></i></span>  Editar</a>
                  </div>
                  <div class="col-5">
                    <a class="btn-delete" (click)="delete(item.id, item.firstName, item.lastName)"><span style="font-size: 16px;"><i class="fas fa-trash"></i></span>  Eliminar</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="notResult" class="row justify-content-center">
        <h3>No se encuentran coincidencias</h3>
      </div>
    </div>
  </div>
  <div *ngIf="!loading" class="row mt-4 justify-content-center">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
  <div class="row mt-4 justify-content-center mb-3">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>

<!-- Scrollable modal -->
<ng-template #template>
    <div style="z-index: 999999;" class="modal-body">
      <div class="container-fluid">
        <div class="row justify-content-center text-center">
          <img *ngIf="trainer.photo != null" bind-src="trainer.photo" class="avatar">
          <img *ngIf="trainer.photo == null" src="../../../assets/notPhoto.png" class="avatar">
        </div>
        <div class="row justify-content-center mt-3 text-center">
          <h3>{{trainer.firstName}} {{trainer.lastName}}</h3>
        </div>
        <div class="row justify-content-center mt-4">
          <p style="overflow-wrap: anywhere;">{{trainer.description}}</p>
        </div>
        <div class="row justify-content-center mt-4">
          <p>E-Mail: {{trainer.email}}</p>
        </div>
        <div class="row justify-content-center mt-4">
          <p>Celular: {{trainer.phone}}</p>
        </div>
        <div class="row justify-content-center mt-4">
          <p>LinkedIn: <span><a style="color: black;" href="https://www.linkedin.com/in/{{trainer.linkedin}}" target="_blank" rel="noopener noreferrer">{{trainer.linkedin}}</a></span> </p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-back" (click)="modalRef.hide()">Cerrar</button>
    </div>
</ng-template>
