<nav class="navbar">
  <a class="navbar-brand" routerLink="/ingresar">
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<div class="container animate__animated animate__fadeIn animate__faster" style="padding-top: 15px;">
  <div class="row justify-content-center">
    <div class="card" >
      <div class="card-body">
        <div class="container-fluid">
          <div class="row justify-content-center mt-5">
            <h3 class="text-center font-StagSans" style="font-size: 25px;">¡Hola! Ingresá tu e-mail</h3>
          </div>
          <div class="row justify-content-center mt-5 text-center">
            <form [formGroup]="loginForm" (ngSubmit)="login()" style="width: 90%;">
              <div class="form-group">
                <input type="text" formControlName="email" required name="email" ngModel class="form-control input-login" placeholder="E-mail" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty || loginForm.controls.email.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.email.errors?.required">
                  El email es requerido.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.email.errors?.email">
                  El email tiene un formato no admitido.
                </div>
              </div>
              <div class="form-group">
                <div class="input-group" id="show_hide_password">
                <input type="password" #password formControlName="password" required name="password" ngModel placeholder="Contraseña" class="input-login form-control" id="exampleInputPassword1">
      <div class="input-group-addon">
        <a (click)="onViewPassword()"> <span class="input-group-text"><i [ngClass]="visiblePassword ? 'fa fa-eye-slash' : 'fa fa-eye'" aria-hidden="true"></i></span></a>
      </div>
    </div>
                
              </div>

              <div class="mb-3" *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.password.errors?.required">
                  La contraseña es requerida.
                </div>
              </div>
              <!-- <div class="form-row justify-content-center mb-3">
                <recaptcha [size]="'normal'" [hide]="false" (captchaExpired)="onCaptchaExpired()" (captchaResponse)="onCaptchaResponse($event)"></recaptcha>
              </div> -->

              <!-- <button type="submit" [disabled]="!loginForm.valid || !isCaptchaChecked" class="btn-login btn">Ingresar</button> -->
              <button type="submit" [disabled]="!loginForm.valid" class="btn-login btn">Ingresar</button>
            </form>
          </div>
          <div class="row justify-content-center mt-5 text-center">
            <a class="not-key" routerLink="/forgot-password">¿No recordás tu contraseña?</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
