import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaService } from 'angular-recaptcha3';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss']
})
export class LoginUserComponent implements OnInit {
  loading = false;
  // tokenCaptacha: string = null;
  tokenCaptacha: string = "";
  @ViewChild('password', {read: ElementRef}) password: ElementRef;
  isCaptchaChecked = false;
  visiblePassword = false;
  constructor(private route: Router, private auth: AuthService, private fb: FormBuilder, private recaptchaService: ReCaptchaService) { }

  ngOnInit(): void {
    console.log(environment.API_URL);
    console.log(environment.environment_name);
  }


  onViewPassword() {
    if(this.password.nativeElement.type === 'text') {
      this.password.nativeElement.type = 'password'
      this.visiblePassword = false;
    }else {
      this.password.nativeElement.type = 'text'
      this.visiblePassword = true;
    }
  }
  onCaptchaResponse(event: string) {
    this.tokenCaptacha = event;
    this.isCaptchaChecked = true;
    console.log(event);
  }

  onCaptchaExpired() {
    this.isCaptchaChecked = false;
  }

  login() {
    this.auth.login(this.loginForm.value.email, this.loginForm.value.password, this.tokenCaptacha);
  }

  // Formulario login
  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

}
