<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
    <div class="col-lg-2 align-self-center text-left">
      <h2>Reportes</h2>
    </div>
    <div class="col-lg-2 align-self-center text-center">
      <h5 [ngClass]="{'hoverTextOk': hoverTextStudent, 'hoverTextOkS': !hoverTextStudent}" (click)="selectMenuItem(0)">Alumnos</h5>
    </div>
    <div class="col-lg-2 align-self-center text-center">
      <h5 [ngClass]="{'hoverTextOk': hoverTextTrainings, 'hoverTextOkS': !hoverTextTrainings}" (click)="selectMenuItem(1)">Capacitaciones</h5>
    </div>
    <div class="col-lg-2 align-self-center text-center">
      <h5 [ngClass]="{'hoverTextOk': hoverTextErrors, 'hoverTextOkS': !hoverTextErrors}" (click)="selectMenuItem(2)">Errores</h5>
    </div>
    <div class="col-lg-4 align-self-center text-right">
      <button *ngIf="!hoverTextErrors" (click)="exportExcel()" class="btn btn-excel"><i class="far fa-file-excel"></i>&nbsp;&nbsp;Exportar a Excel</button>
    </div>
  </div>
  <!-- Reporte de estudiantes -->
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="reportType[0] && !loading" class="row mt-5 justify-content-center animate__animated animate__fadeIn animate__fast">
    <div class="container-fluid">
      <div class="form-row">
        <form #filterForm="ngForm" (ngSubmit)="filterAll(filterForm)">
          <div class="row">
            <div class="col form-group">
              <label for="firstName">Nombre</label>
              <input [(ngModel)]="firstName" type="text" name="firstName" class="form-control" id="firstName" placeholder="...">
            </div>
            <div class="col form-group">
              <label for="lastName">Apellido</label>
              <input [(ngModel)]="lastName" type="text" name="lastName" class="form-control" id="lastName" placeholder="...">
            </div>
            <div class="col form-group">
              <label for="dni">DNI</label>
              <input [(ngModel)]="dni" type="text" name="dni" class="form-control" id="dni" placeholder="...">
            </div>
            <div class="col form-group">
              <label for="email">Mail</label>
              <input [(ngModel)]="email" type="text" name="email" class="form-control" id="email" placeholder="...">
            </div>
            <div class="col form-group">
              <label for="capacitacion">Capacitación</label>
              <input [(ngModel)]="capacitation" type="text" name="capacitacion" class="form-control" id="capacitacion" placeholder="...">
            </div>
            <div class="col align-self-end form-group">
              <button type="submit" class="btn btn-filtrar">Filtrar  <i class="fas fa-chevron-right"></i></button>
            </div>
            <div *ngIf="isFilter" class="col-1 align-self-end form-group">
              <button  type="button" (click)="getAllStudents()" class="btn btn-not-filtrar"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </form>
      </div>
      <div class="row">
        <div class="container-fluid">
          <table *ngIf="students.length != 0" class="table">
            <tbody>
              <tr *ngFor="let item of students | paginate: {itemsPerPage: 6, currentPage: page}" class="info-row" >
                <td style="font-weight: bold;">{{item.firstname}}</td>
                <td style="font-weight: bold;">{{item.lastname}}</td>
                <td>{{item.dni}}</td>
                <td>{{item.email}}</td>
                <td>{{item.capacitationName}}</td>
                <td>{{item.createAt | date:'dd/MM/yyyy'}}</td>
              </tr>
              <br>
            </tbody>
          </table>
          <div *ngIf="students.length == 0" class="row justify-content-center mt-4 mb-4">
            <h3>No se encontraron alumnos en el sistema</h3>
          </div>
        </div>
      </div>
      <div *ngIf="students.length != 0" class="row justify-content-center">
        <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
          nextLabel=""></pagination-controls>
      </div>
    </div>
  </div>
  <!-- Reporte de capacitaciones -->
  <div *ngIf="reportType[1] && !loading" class="row mt-5 justify-content-center animate__animated animate__fadeIn animate__fast">
    <div class="container-fluid">
      <div class="form-row">
        <form #filterFormCap="ngForm" (ngSubmit)="filterCapacitationsReport(filterFormCap)" style="width: 100%;">
          <div class="row">
            <div class="col form-group">
              <label for="capacitacion">Capacitación</label>
              <input [(ngModel)]="name" name="name" type="text" class="form-control" id="name" placeholder="...">
            </div>
            <div class="col form-group">
              <label for="capacitador">Capacitador</label>
              <ng-select class="custom-filter-2" [ngModelOptions]="{standalone: true}" [items]="trainers" [(ngModel)]="trainerId" id="trainerId" placeholder="..." bindValue="id" bindLabel="fullName"></ng-select>
            </div>
            <div class="col form-group">
              <label for="capacitador">Categorías</label>
              <ng-select class="custom-filter-2" [(ngModel)]="categorySelect" id="categoryId" [ngModelOptions]="{standalone: true}" placeholder="...">
                <ng-option *ngFor = "let category of categories" [value] = "category.name">{{category.name}}</ng-option>
              </ng-select>
            </div>
            <div class="col-4 text-right align-self-end form-group">
              <button style="margin-right: 22px;" class="btn btn-filtrar" type="submit">Filtrar  <i class="fas fa-chevron-right"></i></button>
            </div>
            <div *ngIf="isFilterCap" class="col-1 align-self-end form-group">
              <button  type="button" (click)="getAllCapacitations()" class="btn btn-not-filtrar"><i class="fas fa-times"></i></button>
            </div>
          </div>

        </form>
      </div>
      <div class="row">
        <div class="container-fluid">
          <table *ngIf="capacitations.length != 0" class="table">
            <tbody>
              <tr *ngFor="let item of capacitations | paginate: {itemsPerPage: 6, currentPage: pageCap}"  class="info-row" >
                <td style="font-weight: bold;">{{item.name}}</td>
                <td>{{item.userTrainerName}}</td>
                <td>{{item.currentStudents}}/{{item.quantity}}</td>
                <td>...</td>
                <td>{{item.startDate | date:'dd/MM/yyyy'}}</td>
              </tr>
              <br>
            </tbody>
          </table>
          <div *ngIf="capacitations.length == 0" class="row justify-content-center mt-4 mb-4">
            <h3>No se encontraron capacitaciones en el sistema</h3>
          </div>
        </div>
      </div>
      <div *ngIf="capacitations.length != 0" class="row justify-content-center">
        <pagination-controls style="padding-left: 0;" (pageChange)="pageCap = $event" responsive="true" previousLabel=""
          nextLabel=""></pagination-controls>
      </div>
    </div>
  </div>
  <div *ngIf="reportType[2] && !loading" class="row mt-5 justify-content-center animate__animated animate__fadeIn animate__fast">
    <app-error-reporting></app-error-reporting>
  </div>

  <div class="row justify-content-center mt-4 mb-5">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>
