export class AdminModel {
  id: number;
  position: string;
  description: string;
  photo?: string;
  linkedin: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  show: boolean = false;
  isTrainer: boolean;
}

export class AdminUpdateModel {
  position: string;
  description: string;
  linkedin: string;
  photo: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  isTrainer: boolean;
}

export class AdminCreateModel {
  position: string;
  description: string;
  linkedin: string;
  photo: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string = "none";
  isTrainer: boolean;
}
