<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
      <h2>Listado de Alumnos</h2>
  </div>
  <div class="row mt-4">
    <div class="col-md-8">
      <div class="container">
        <div class="row">
          <form style="width: 100%;">
            <div class="form-row align-items-center">
              <div class="col-auto ">
                <label class="mt-2 font-color" for="inlineFormInputName">Capacitación:</label>
              </div>
              <div class="col-auto ml-3">
                <input disabled class="form-control input-custom" [placeholder]="capacitationName" >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <form style="width: 100%;">
        <div class="form-row align-items-center">
          <input (ngModelChange)="search($event)" [(ngModel)]="searchText" type="search" class="form-control search-bar" placeholder="Buscar todos" name="buscar" id="buscar">
        </div>
      </form>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-8">
      <div class="container">
        <div class="row">
          <form  style="width: 100%;">
            <div class="form-row align-items-center">
              <div class="col-auto ">
                <label class="mt-2 font-color" for="inlineFormInputName">Filtrar por porcentaje de asistencia:</label>
              </div>
              <div class="col-auto ml-3">
                <ng-select [searchable]=false (change)="filter()" (clear)="getAllStudents()" [(ngModel)]="min_max" name="min_max" placeholder="Seleccione porcentajes" class="input-custom-filter-ngselect">
                  <ng-option value="all">Todos</ng-option>
                  <ng-option value="10_20">10-20%</ng-option>
                  <ng-option value="20_30">20-30%</ng-option>
                  <ng-option value="30_40">30-40%</ng-option>
                  <ng-option value="40_50">40-50%</ng-option>
                  <ng-option value="50_60">50-60%</ng-option>
                  <ng-option value="60_70">60-70%</ng-option>
                  <ng-option value="70_100">70-100%</ng-option>
                </ng-select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="notElements && !loading" class="row mt-5 mb-5 justify-content-center">
    <h4 *ngIf="!notResults" class="text-center animate__animated animate__fadeIn animate__fast">No existen alumnos entre el {{min}} y {{max}}% de asistencia.<br>(Intente seleccionando otro rango de porcentajes)</h4>
    <h4 *ngIf="notResults" class="text-center animate__animated animate__fadeIn animate__fast">No se encontraron resultados para su búsqueda.</h4>
  </div>
  <div *ngIf="!notElements && !loading" class="row mt-4 animate__animated animate__fadeIn animate__faster">
    <div class="container-fluid">
      <table class="table">
        <thead>
          <tr>
            <th style="color: #022A3A;" scope="col" class="text-center" >
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <input (change)="selectAll($event)" type="checkbox" id="customCheck1">
                </div>
              </div>
            </th>
            <th style="color: #022A3A;" scope="col" >Alumno</th>
            <th style="color: #022A3A;" class="text-center" scope="col">Asistencia</th>
            <th style="color: #022A3A;" scope="col" >E-Mail</th>
          </tr>
        </thead>
        <tbody>
          <tr  class="info-row" *ngFor="let i of students | paginate: {itemsPerPage: 10, currentPage: page}">
            <th>
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <input type="checkbox" [checked]="i.isChecked" (change)="selectedStudents(i.id, $event)" id="customCheck1">
                </div>
              </div>
            </th>
            <td>{{i.firstName}} {{i.lastName}}</td>
            <td>
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <dirmod-percentage [percentage]="i.attendances"></dirmod-percentage>
                </div>
              </div>
            </td>
            <td>{{i.email}}</td>
            <td class="text-center">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    <button class="btn btn-action" triggers="mouseenter:mouseleave" (mouseenter)="selectedStudent(i.id)" placement="left" [popover]="showStudent" ><i class="fas fa-eye "></i></button>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center text-muted">
              {{capacitationName}}
            </td>
          </tr>
          <br>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!notElements && !loading" class="row justify-content-center animate__animated animate__fadeIn animate__faster">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
  <div *ngIf="!notElements && !loading" class="row justify-content-center mt-4 mb-4 animate__animated animate__fadeIn animate__faster">
    <button *ngIf="!saving" class="btn btn-send " (click)="sendPDF()"><span ><i class="fas fa-paper-plane" style="margin-right: 10px;"></i></span> Enviar Certificados</button>
    <button *ngIf="saving" class="btn btn-send " [disabled]="saving"><i class="fas fa-spinner fa-pulse"></i></button>
  </div>
  <div class="row justify-content-center mb-5">
    <a (click)="goToCertifications(false)" class="go-back text-center" style="cursor: pointer;">Volver a Certificados</a>
  </div>
</div>



<ng-template #showStudent>
  <div style="z-index: 999999;">
    <div class="container-fluid">
      <div class="row mt-2">
          <div class="container-fluid">
            <div class="row">
              <p style="margin-bottom: 5px; font-size: 16px; line-height: 17px; font-weight: bold;">{{student.firstName}} {{student.lastName}}</p>
            </div>
          </div>
      </div>
      <div class="row mt-2">
        <div class="container-fluid">
          <div class="row">
            <p style="font-weight: bold; margin-bottom: 5px;">Ocupación</p>
          </div>
          <div class="row">
            <p>{{student.location}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid">
          <div class="row">
            <p style="font-weight: bold; margin-bottom: 5px;">Teléfono</p>
          </div>
          <div class="row">
            <p>{{student.phone}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid">
          <div class="row">
            <p style="font-weight: bold; margin-bottom: 5px;">Email</p>
          </div>
          <div class="row">
            <p>{{student.email}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid">
          <div class="row">
            <p style="font-weight: bold; margin-bottom: 5px;">País</p>
          </div>
          <div class="row">
            <p>{{student.country}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid">
          <div class="row">
            <p style="font-weight: bold; margin-bottom: 5px;">Provincia/Estado</p>
          </div>
          <div class="row">
            <p>{{student.state}}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="container-fluid">
          <div class="row">
            <p style="font-weight: bold; margin-bottom: 5px;">Ciudad</p>
          </div>
          <div class="row">
            <p>{{student.city}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
