<div [ngSwitch]="onlyPercentage">
  <p class="pors">{{percentage}}</p>
 <div *ngSwitchCase="0" class="spin0"></div>
 <div *ngSwitchCase="10" class="spin25"></div>
 <div *ngSwitchCase="20" class="spin25"></div>
 <div *ngSwitchCase="30" class="spin25"></div>
 <div *ngSwitchCase="40" class="spin50"></div>
 <div *ngSwitchCase="50" class="spin50"></div>
 <div *ngSwitchCase="60" class="spin50"></div>
 <div *ngSwitchCase="70" class="spin75"></div>
 <div *ngSwitchCase="80" class="spin75"></div>
 <div *ngSwitchCase="90" class="spin75"></div>
 <div *ngSwitchCase="100" class="spin100"></div>
</div>
