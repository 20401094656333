import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  /**
  * Guarda el token en el local storage
  * @param token Token a guardar
  */
  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  /**
  * Obtiene el token del local storage
  */
  getToken(): string {
    return localStorage.getItem('token');
  }

  /**
  * Guarda el Rol en el local storage
  * @param token Rol a guardar
  */
  setRole(role: string) {
    localStorage.setItem('role', role);
  }

  /**
  * Guarda el refresh token en el local storage
  * @param rtoken Refresh Token a guardar
  */
  setRefreshToken(rtoken: string) {
    localStorage.setItem('refreshToken', rtoken);
  }

  /**
  * Obtiene el refresh token del local storage
  */
  getRefreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  /**
  * Obtiene el rol del local storage
  */
  getRole(): string {
    return localStorage.getItem('role');
  }

  /**
  * Comprueba si el token esta en el local storage
  * @returns Devuelve un true si el token existe
  */
  isToken(): boolean{
    if (localStorage.getItem('token') == null) {
      return false;
    }
    return true;
  }

  /**
  * Elimina el token del local storage
  */
  removeToken() {
    localStorage.removeItem('token');
  }

  /**
  * Elimina el refresh token del local storage
  */
  removeRefreshToken() {
    localStorage.removeItem('refreshToken');
  }

  /**
  * Elimina el rol del local storage
  */
  removeRole() {
    localStorage.removeItem('role');
  }
}
