import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserLoginModel } from '../models/user';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private endPoint = environment.API_URL;
  private httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }
  private httpHeaderWithBearer = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${this.storage.getToken()}`
    })
  }
  private userAuth = {
    email: undefined,
    password: undefined,
    tokenCaptcha: undefined
  }

  constructor(private httpClient: HttpClient, private storage: StorageService, private route: Router) { }

  loginUser: UserLoginModel;

  updateHeader() {
    this.httpHeaderWithBearer = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${this.storage.getToken()}`
      })
    }
  }

  login(email: string, password: string, tokenCaptcha: string): any {
    // if (email != '' && password != '' && tokenCaptcha != '') {
    if (email != '' && password != '') {
      this.userAuth.email = email;
      this.userAuth.password = password;
      this.userAuth.tokenCaptcha = tokenCaptcha;
      Swal.fire({
        html:
          `<br/><br/><div class="spin"></div><br/><br/>`,
        showConfirmButton: false,
        timer: 2000
      });
      this.httpClient.post(`${this.endPoint}/authentication`, this.userAuth, this.httpHeader).subscribe((data: any) => {

        this.loginUser = data.data;
        console.log(this.loginUser);
        console.log(data.message);

      }, (error: any) => {
        console.log(error);
        if (error.error.code == 13) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Su cuenta ya no se encuentra disponible',
            showConfirmButton: false,
            timer: 2000
          });
        }
        else if (error.error.status == 500) {
          this.route.navigateByUrl('/500');
        } else if (error.error.code == 1) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Email y/o Contraseña incorrectos',
            showConfirmButton: false,
            timer: 2000
          });
        }
        return false;
      }, () => {
        this.storage.setToken(this.loginUser.token);
        let decoded: any = jwt_decode(this.loginUser.token);
        this.storage.setRefreshToken(this.loginUser.refreshToken);
        if (password === 'none') {
          this.route.navigateByUrl('/contraseña/welcome');
        } else {
          this.route.navigateByUrl('/inicio');
        }
      });
    } else {
      console.error('Wrong Login... Set email, password, and captcha');
    }

  }

  resetPassword(_email: string): any {
    let reset = {
      email: _email
    }
    return this.httpClient.post(`${this.endPoint}/authentication/RecoveryPassword`, reset, this.httpHeader);
  }

  updatePassword(token: string, email: string, password: string) {
    let update = {
      email: email,
      token: token,
      password: password
    };
    return this.httpClient.post(`${this.endPoint}/authentication/UpdatePassword`, update, this.httpHeader);
  }

  refreshToken(){
    let decoded: any = jwt_decode(this.storage.getToken());
    let refresh = {
      Id: parseInt(decoded.Id),
      refreshToken: this.storage.getRefreshToken()
    }
    console.log(refresh);
    this.httpClient.post(`${this.endPoint}/authentication/RefreshToken`, refresh, this.httpHeaderWithBearer).subscribe((data: any) => {
      console.log(data);
      if (data.data == null) {
        this.logout();
        window.location.reload();
      } else {
        this.storage.setToken(data.token);
        this.storage.setRefreshToken(data.refreshToken);
      }
    }, (error: any) => {
      console.error(error);
      if (error.status = 500) {
        this.route.navigateByUrl('/500')
      }

    });
  }

  logout(){
    this.storage.removeToken();
    this.storage.removeRefreshToken();
    this.storage.removeRole();
    console.info('LOGOUT');
  }


  changePassword(email: string, currentPass: string, password: string): any{
    this.updateHeader();
    let newPassword = {
      CurrentPassword: currentPass,
      Password: password,
      Email: email
    };
    return this.httpClient.post(`${this.endPoint}/authentication/newPassword`, newPassword, this.httpHeaderWithBearer)
  }

}
