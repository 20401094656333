import { Time } from "@angular/common";
import { CategorieModel } from "./categorie";
import { StatusModel } from "./status";
import { TrainerModel } from "./trainer";

export class CapacitationModel {
  id: number;
  sku: string;
  name: string;
  quantity: number;
  description: string;
  temary: string;
  coverImage: string;
  thumbnail: string;
  startDate: Date;
  endDate: Date;
  startHours: Date;
  endHours: Date;
  linkZoom: string;
  price: number;
  status: StatusModel;
  categories: CategorieModel[];
  modality: any;
  userTrainer: TrainerModel[];
  currentStudents: number;
  currentPurchases: number;
  userTrainerName: string[];
  visibility: boolean;
  materialLink: string;
  place: string;
  isCertification: boolean;
  createdByTotvs: boolean;
}


export class EditCapacitationModel {
  Name: string;
  Description: string;
  CategoriesID: number[];
  UserTrainerID: number[];
  CoverImage: string;
  Thumbnail: string;
  StartDate: Date;
  EndDate: Date;
  StartHours: string;
  EndHours: string;
  Price: number;
  Quantity: number;
  Visibility: boolean;
  MaterialLink: string;
  Temary: string;
  Place: string;
  IsCertification: boolean;
  CreatedByTotvs: boolean;
}

export class DuplicateCapacitationModel {
  SKU: string;
  Name: string;
  Description: string;
  CategoriesID: number[];
  UserTrainerID: number[];
  CoverImage: string;
  Thumbnail: string;
  StartDate: Date;
  EndDate: Date;
  StartHours: string;
  EndHours: string;
  Price: number;
  Quantity: number;
  MaterialLink: string;
  Temary: string;
  Place: string;
  IsCertification: boolean;
  CreatedByTotvs: boolean;
}

