import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { CoreService } from 'src/app/services/core.service';
import { PasswordValidators } from '../reset-password/ConfirmPassword';
import jwt_decode from 'jwt-decode';
import { StorageService } from 'src/app/services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  constructor(private fb: FormBuilder, private core: CoreService, private route: Router, private auth: AuthService, private storage: StorageService, private routes: ActivatedRoute) { }

  firstName: string;
  email: string;
  isNewUser: boolean;

  ngOnInit(): void {
    let decoded: any = jwt_decode(this.storage.getToken());
    this.firstName = decoded.firstName;
    this.getUserData(decoded.Id, decoded.role);
    let type = this.routes.snapshot.paramMap.get("type");
    if (type == 'welcome') {
      this.isNewUser = true;
    } else {
      this.isNewUser = false;
    }
  }


  getUserData(id: number, role: string) {
    if (role == 'Capacitador') {
      this.core.showTrainer(id).subscribe( (data: any) => {
        this.email = data.data.email;
        console.log(data);
      });
    } else {
      this.core.showAdmin(id).subscribe( (data: any) => {
        this.email = data.data.email;
        console.log(data);
      });
    }
  }

  update(isNew: boolean){
    if (isNew) {
      this.auth.changePassword(this.email, 'none', this.loginForm.value.password).subscribe( (data: any) => {
        console.log(data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Contraseña cambiada! Bienvenido/a.',
          showConfirmButton: false,
          timer: 1500
        });
        this.route.navigateByUrl('/inicio');
      }, (error: any) => {
        if (error.error.code == 14) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'La contraseña actual no es la correcta',
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    } else {
      this.auth.changePassword(this.email, this.passwordForm.value.currentPassword, this.passwordForm.value.password).subscribe( (data: any) => {
        console.log(data);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Contraseña cambiada!',
          showConfirmButton: false,
          timer: 1500
        });
        this.route.navigateByUrl('/inicio');
      }, (error: any) => {
        if (error.error.code == 14) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'La contraseña actual no es la correcta',
            showConfirmButton: false,
            timer: 1500
          });
        }
      });
    }

  }


  loginForm = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required]]
  },
  {
     // check whether our password and confirm password match
     validator: PasswordValidators.passwordMatch
  });

  passwordForm = this.fb.group({
    currentPassword: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword: ['', [Validators.required]]
  },
  {
     // check whether our password and confirm password match
     validator: PasswordValidators.passwordMatch
  });
}
