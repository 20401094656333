<nav class="navbar">
  <a class="navbar-brand" routerLink="/ingresar">
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<div class="container animate__animated animate__fadeIn animate__faster" style="padding-top: 15px;">
  <div class="row justify-content-center">
    <div class="card" >
      <div class="card-body">
        <div class="container-fluid">
          <div class="row justify-content-center mt-5">
            <h3 class="text-center font-StagSans" style="font-size: 25px;">Restablecer la contraseña</h3>
          </div>
          <div class="row justify-content-center mt-5">
            <p class="explication" style="font-size: 17px !important;">
              A continuación ingrese el correo electrónico asociado a su cuenta.
            </p>
          </div>
          <div class="row justify-content-center mt-5 text-center">
            <form [formGroup]="loginForm" (ngSubmit)="reset()" style="width: 90%;">
              <div class="form-group">
                <input type="text" formControlName="email" required name="email" ngModel class="form-control input-login" placeholder="E-mail" id="exampleInputEmail1" aria-describedby="emailHelp">
              </div>
              <div class="mb-3" *ngIf="loginForm.controls.email.invalid && (loginForm.controls.email.dirty || loginForm.controls.email.touched)">
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.email.errors?.required">
                  El email es requerido.
                </div>
                <div class="text-danger" style="font-size: 12px;" *ngIf="loginForm.controls.email.errors?.email">
                  El email tiene un formato no admitido.
                </div>
              </div>
              <button type="submit" [disabled]="!loginForm.valid" class="btn-login btn">Enviar</button>
            </form>
          </div>
          <div class="row justify-content-center mt-5">
            <p class="explication text-center">
              Te vamos a enviar un link a la casilla de correo
              asociada a la cuenta que tenés en la plataforma Barbieri E-Learning.
              <br>
              <br>
              Ingresá al link y seguí las instrucciones.
            </p>
          </div>
          <div class="row justify-content-center ">
            <a routerLink="/ingresar" style="color: black; text-decoration: none; font-weight: bold;">Volver</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
