// tslint:disable-next-line: jsdoc-format
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  DuplicateCapacitationModel,
  EditCapacitationModel,
} from '../models/capacitation';
import { CategorieModel } from '../models/categorie';
import {
  CertificationGenerateRequestModel,
  CertificationRequestModel,
} from '../models/certificate';
import { StudentModel } from '../models/student';
import { TrainerCreateModel, TrainerUpdateModel } from '../models/trainer';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})

export class CoreService {
  private token: string;
  private endPoint = environment.API_URL;

  private httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    }),
  };

  private httpHeader2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  private httpHeaderWithBearer = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.storage.getToken()}`,
    }),
  };

  private httpHeaderWithBearerImage = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.storage.getToken()}`,
    }),
    responseType: 'blob' as 'blob',
  };

  private httpHeaderWithBearerDownload = {
    headers: new HttpHeaders({
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      responseType: 'blob',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.storage.getToken()}`,
    }),
    responseType: 'blob' as 'blob',
  };

  private httpHeaderWithBearerFile = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${this.storage.getToken()}`,
    }),
  };

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService
  ) {}

  /**
   * Valida la sesion actual
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  validateSesion(): any {
    return this.httpClient.get(
      `${this.endPoint}/authentication/Validation`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Sube archivos de imagenes jpg/png
   * @param file Archivo a subir
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  uploudFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(
      `${this.endPoint}/file/upload`,
      formData,
      this.httpHeaderWithBearerFile
    );
  }

  /**
   * Sube archivos de imagenes png
   * @param file Archivo a subir
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  uploudFilePng(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post(
      `${this.endPoint}/file/upload/png`,
      formData,
      this.httpHeaderWithBearerFile
    );
  }

  // Categorías API
  /**
   * Muestra todas las categorías
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  getCategories(): any {
    return this.httpClient.get(
      `${this.endPoint}/categories/all`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Búsqueda dinámica de categorias
   * @param text Texto a buscar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  dynamicSearchCategory(text: string): any {
    return this.httpClient.get(
      `${this.endPoint}/categories/search?name=${text}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Añade una categoría
   * @param name Nombre de la categoría ha añadir
   * @param desc Descripción de la categoría ha añadir
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  addCategory(name: string, idMagento: number, desc: string): any {
    let categorie = {
      Name: name,
      IdMagento: idMagento,
      Description: desc,
    };
    return this.httpClient.post(
      `${this.endPoint}/categories`,
      categorie,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Elimina una categoría
   * @param id ID de la categoría ha eliminar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  deleteCategory(id: number): any {
    return this.httpClient.delete(
      `${this.endPoint}/categories/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Actualiza una categoría
   * @param id ID de la categoría ha actualizar
   * @param name Nombre de la categoría ha actualizar
   * @param desc Descripción de la categoría ha actualizar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  updateCategory(
    id: number,
    name: string,
    idMagento: number,
    desc: string
  ): any {
    let categorie = {
      name: name,
      idMagento: idMagento,
      description: desc,
    };
    console.log(categorie);
    return this.httpClient.put(
      `${this.endPoint}/categories/${id}`,
      categorie,
      this.httpHeaderWithBearer
    );
  }

  // Capacitadores API
  /**
   * Muestra todos los capacitadores
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  getTrainers(): any {
    return this.httpClient.get(
      `${this.endPoint}/trainers/all`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Muestra todos los capacitadores
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  getAllUsers(): any {
    return this.httpClient.get(
      `${this.endPoint}/user/all`,
      this.httpHeaderWithBearer
    );
  }

  getAllTrainersUsers(): any {
    return this.httpClient.get(
      `${this.endPoint}/user/allTrainers`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Búsqueda dinámica de capacitadores
   * @param text Texto a buscar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  dynamicSearchTrainers(text: string): any {
    return this.httpClient.get(
      `${this.endPoint}/trainers/search?name=${text}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Valida si existe un usuario (administrador/capacitador) con el email
   * @param email Email a comprobar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  existUser(email: string): any {
    return this.httpClient.get(
      `${this.endPoint}/user/exist?email=${email}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Muestra todos los capacitadores con paginador
   * @param page Pagina a mostrar
   * @param pageSize Cantidad de capacitadores por pagina
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  getTrainersPages(page: number, pageSize: number): any {
    return this.httpClient.get(
      `${this.endPoint}/trainers?pageSize=${pageSize}&page=${page}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Muestra información especifica de un capacitador
   * @param id ID del capacitador ha mostrar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  showTrainer(id: number): any {
    return this.httpClient.get(
      `${this.endPoint}/trainers/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Elimina un capacitador
   * @param id ID del capacitador ha eliminar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  deleteTrainer(id: number): any {
    return this.httpClient.delete(
      `${this.endPoint}/trainers/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Actualiza un capacitador
   * @param id ID del administrador ha actualizar
   * @param trainer Capacitador que se quiere actualizar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  updateTrainer(id: number, trainer: TrainerUpdateModel): any {
    return this.httpClient.put(
      `${this.endPoint}/trainers/${id}`,
      trainer,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Añade un nuevo capacitador
   * @param trainer Capacitador que se quiere añadir
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  addTrainer(trainer: TrainerCreateModel): any {
    return this.httpClient.post(
      `${this.endPoint}/trainers`,
      trainer,
      this.httpHeaderWithBearer
    );
  }

  // Administradores API
  /**
   * Muestra todos los administradores
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  getAdmins(): any {
    return this.httpClient.get(
      `${this.endPoint}/admin/all`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Búsqueda dinámica de administradores
   * @param text Texto a buscar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  dynamicSearchAdmins(text: string): any {
    return this.httpClient.get(
      `${this.endPoint}/admin/search?name=${text}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Muestra todos los administradores con paginador
   * @param page Pagina a mostrar
   * @param pageSize Cantidad de administradores por pagina
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  getAdminsPages(page: number, pageSize: number): any {
    return this.httpClient.get(
      `${this.endPoint}/admin?pageSize=${pageSize}&page=${page}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Muestra información especifica de un administrador
   * @param id ID del administrador ha mostrar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  showAdmin(id: number): any {
    return this.httpClient.get(
      `${this.endPoint}/admin/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Elimina un administrador
   * @param id ID del administrador ha eliminar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  deleteAdmin(id: number): any {
    return this.httpClient.delete(
      `${this.endPoint}/admin/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Actualiza un administrador
   * @param id ID del administrador ha actualizar
   * @param trainer Administrador que se quiere actualizar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  updateAdmin(id: number, trainer: TrainerUpdateModel): any {
    return this.httpClient.put(
      `${this.endPoint}/admin/${id}`,
      trainer,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Añade un nuevo administrador
   * @param trainer Administrador que se quiere añadir
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  addAdmin(trainer: TrainerCreateModel): any {
    return this.httpClient.post(
      `${this.endPoint}/admin`,
      trainer,
      this.httpHeaderWithBearer
    );
  }

  // Estudiantes
  /**
   * Añade un nuevo estudiante por formulario
   * @param student Estudiante que se quiere añadir
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  addStudent(student: StudentModel) {
    return this.httpClient.post(
      `${this.endPoint}/students/add`,
      student,
      this.httpHeader2
    );
  }

  updateEmail(body: any, id: any) {
    return this.httpClient.put(
      `${this.endPoint}/students/updateEmail/${id}`,
      body
    );
  }

  updateEmailBuyer(body: any) {
    return this.httpClient.put(
      `${this.endPoint}/buyer/updateEmail`,
      body
    );
  }


  getBuyer(id: any) {
    return this.httpClient.get(
      `${this.endPoint}/buyer/${id}`
    );
  }

  /**
   * Elimina un estudiante
   * @param UserID ID Único del Estudiante que se quiere eliminar
   * @param CapacitationID ID Único de la capacitación asociada al alumno que se quiere eliminar
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  deleteStudent(UserID: number, CapacitationID: number) {
    return this.httpClient.delete(
      `${this.endPoint}/students/${UserID}/${CapacitationID}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Valida si el formulario esta vencido o no
   * @param token Token del formulario actual
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  isValid(token: string): any {
    return this.httpClient.get(
      `${this.endPoint}/students/isValid?token=${token}`,
      this.httpHeader
    );
  }

  /**
   * Obtiene el formulario actual
   * @param id ID único del formulario actual
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  currentForm(id: string) {
    return this.httpClient.get(
      `${this.endPoint}/students/currentForm/${id}`,
      this.httpHeader
    );
  }

  // Reportes de Estudiantes y Capacitaciones
  /**
   * Obtiene todos los estudiantes para el reporte
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  allStudentReport(): any {
    return this.httpClient.get(
      `${this.endPoint}/reports/students/all`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Obtiene todos las capacitaciones para el reporte
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  allCapacitationReport(): any {
    return this.httpClient.get(
      `${this.endPoint}/reports/capacitation/all`,
      this.httpHeaderWithBearer
    );
  }

  // allCapacitationReportFilter(name: string, trainerId: number) {
  //   if (name != null) {
  //     return this.httpClient.get(
  //       `${this.endPoint}/reports/capacitation/filter?name=${name}`,
  //       this.httpHeaderWithBearer
  //     );
  //   }
  //   if (trainerId != null) {
  //     return this.httpClient.get(
  //       `${this.endPoint}/reports/capacitation/filter?trainerId=${trainerId}`,
  //       this.httpHeaderWithBearer
  //     );
  //   }
  //   if (trainerId != null && name != null) {
  //     return this.httpClient.get(
  //       `${this.endPoint}/reports/capacitation/filter?name=${name}&trainerId=${trainerId}`,
  //       this.httpHeaderWithBearer
  //     );
  //   } else if (trainerId == null && name == null) {
  //     return this.httpClient.get(
  //       `${this.endPoint}/reports/capacitation/filter`,
  //       this.httpHeaderWithBearer
  //     );
  //   }
  // }

  /**
   * Obtiene todos los estudiantes con filtro para el reporte
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  allStudentReportFilter(
    firstName: string = null,
    lastName: string = null,
    dni: string = null,
    email: string = null,
    capacitacion: string = null
  ): any {
    if (capacitacion != null) {
      return this.httpClient.get(
        `${this.endPoint}/reports/students/filter?firstname=${firstName}&lastname=${lastName}&email=${email}&dni=${dni}&capacitation=${capacitacion}`,
        this.httpHeaderWithBearer
      );
    } else {
      return this.httpClient.get(
        `${this.endPoint}/reports/students/filter?firstname=${firstName}&lastname=${lastName}&email=${email}&dni=${dni}`,
        this.httpHeaderWithBearer
      );
    }
  }

  exportExcel(
    firstName: string,
    lastName: string,
    dni: string,
    email: string,
    capacitacion: string
  ): any {
    if (capacitacion != null) {
      return this.httpClient.get(
        `${this.endPoint}/reports/export/student?name=${firstName}&lastname=${lastName}&email=${email}&dni=${dni}&capacitation=${capacitacion}`,
        this.httpHeaderWithBearerDownload
      );
    } else {
      return this.httpClient.get(
        `${this.endPoint}/reports/export/student?name=${firstName}&lastname=${lastName}&email=${email}&dni=${dni}`,
        this.httpHeaderWithBearerDownload
      );
    }
  }

  /**
   * Descarga un reporte con toda la información de estudiantes o capacitaciones dependiendo del tipo de reporte
   * @param type 0 Para estudiantes y 1 Para capacitaciones
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  exportExcelAll(type: number) {
    return this.httpClient.get(
      `${this.endPoint}/reports/export/all/${type}`,
      this.httpHeaderWithBearerDownload
    );
  }

  exportExcelCapacitation(name: string, trainerId: number) {
    if (name != null) {
      return this.httpClient.get(
        `${this.endPoint}/reports/export/capacitation?name=${name}`,
        this.httpHeaderWithBearerDownload
      );
    }
    if (trainerId != null) {
      return this.httpClient.get(
        `${this.endPoint}/reports/export/capacitation?trainerId=${trainerId}`,
        this.httpHeaderWithBearerDownload
      );
    }
    if (trainerId != null && name != null) {
      return this.httpClient.get(
        `${this.endPoint}/reports/export/capacitation?name=${name}&trainerId=${trainerId}`,
        this.httpHeaderWithBearerDownload
      );
    } else if (trainerId == null && name == null) {
      return this.httpClient.get(
        `${this.endPoint}/reports/export/capacitation`,
        this.httpHeaderWithBearerDownload
      );
    }
  }

  // Notificaciones

  allNotifications() {
    return this.httpClient.get(
      `${this.endPoint}/notification/all`,
      this.httpHeaderWithBearer
    );
  }

  setNotification(ID: number, isEnables: boolean) {
    const notification = {
      Id: ID,
      isEnable: isEnables,
    };
    return this.httpClient.post(
      `${this.endPoint}/notification/activate`,
      notification,
      this.httpHeaderWithBearer
    );
  }

  // Capacitaciones

  allCapacitations() {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/all`,
      this.httpHeaderWithBearer
    );
  }

  searchCapacitations(name: string) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/search?name=${name}`,
      this.httpHeaderWithBearer
    );
  }

  filterCapacitationsWithCategory(IdCapacitation: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/filter?categorieId=${IdCapacitation}`,
      this.httpHeaderWithBearer
    );
  }

  filterCapacitationsWithStatus(IdStatus: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/filter?statusId=${IdStatus}`,
      this.httpHeaderWithBearer
    );
  }

  filterCapacitationsWithDate(date: Date) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/filter?date=${date}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Obtiene todos los estudiantes de una capacitación
   * @param id ID único de una capacitación
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  allStudentsByCapacitation(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/students/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Obtiene información de un estudiante
   * @param id ID único del estudiante
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  showStudentsByCapacitation(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/student/${id}`,
      this.httpHeaderWithBearer
    );
  }

  /**
   * Descarga un Excel con todos los estudiantes de una capacitación
   * @param id ID único de una capacitación
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  downloadStudentsByCapacitation(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/export/${id}`,
      this.httpHeaderWithBearerDownload
    );
  }

  showCapacitation(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/${id}`,
      this.httpHeaderWithBearer
    );
  }

  getNameCapacitation(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/GetName/${id}`,
      this.httpHeaderWithBearer
    );
  }

  deleteCapacitation(id: number) {
    return this.httpClient.delete(
      `${this.endPoint}/capacitations/${id}`,
      this.httpHeaderWithBearer
    );
  }

  allStatus() {
    return this.httpClient.get(
      `${this.endPoint}/capacitations/status`,
      this.httpHeaderWithBearer
    );
  }

  editCapacitation(id: number, editModel: EditCapacitationModel) {
    return this.httpClient.put(
      `${this.endPoint}/capacitations/${id}`,
      editModel,
      this.httpHeaderWithBearer
    );
  }

  duplicateCapacitation(duplicateModel: DuplicateCapacitationModel) {
    return this.httpClient.post(
      `${this.endPoint}/capacitations/duplicate`,
      duplicateModel,
      this.httpHeaderWithBearer
    );
  }

  getUser(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/user/${id}`,
      this.httpHeaderWithBearer
    );
  }

  // Errores del sistema
  /**
   * Obtiene todos los errores registrados
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  allErrorReport() {
    return this.httpClient.get(
      `${this.endPoint}/logs/all`,
      this.httpHeaderWithBearer
    );
  }

  // Errores del sistema
  /**
   * Obtiene los errores registrados en los
   * ultimos 7 días, por tipo
   * @returns devuelve un Observable (debe suscribirse a el)
   */
  errorReport(errorType: number) {
    return this.httpClient.get(
      `${this.endPoint}/logs/last/${errorType}`,
      this.httpHeaderWithBearer
    );
  }

  // Certificados
  ShowLiveView(cert: CertificationRequestModel) {
    return this.httpClient.post(
      `${this.endPoint}/certificate/liveview`,
      cert,
      this.httpHeaderWithBearerImage
    );
  }

  GeneratePDFAndSend(cert: CertificationGenerateRequestModel) {
    return this.httpClient.post(
      `${this.endPoint}/certificate/generate`,
      cert,
      this.httpHeaderWithBearer
    );
  }

  ShowStudents(id: number) {
    return this.httpClient.get(
      `${this.endPoint}/certificate/${id}/students`,
      this.httpHeaderWithBearer
    );
  }

  ShowStudentsFilterCert(id: number, min: number, max: number) {
    return this.httpClient.get(
      `${this.endPoint}/certificate/${id}/students/filter/${min}/${max}`,
      this.httpHeaderWithBearer
    );
  }

  ShowStudentsSearchCert(id: number, name: string) {
    return this.httpClient.get(
      `${this.endPoint}/certificate/${id}/students/search?name=${name}`,
      this.httpHeaderWithBearer
    );
  }
  existingCourse(courseName: string, id: number):Observable<any>{
    const params = new HttpParams()
          .set('name', courseName)
          .set('id', id.toString())
    return this.httpClient.get<any>(this.endPoint + "/capacitations/ExistingCourse", { params }).pipe( value =>{
      return value;
    })
   }
}
