<nav class="navbar">
  <a class="navbar-brand" >
    <img src="../../../../assets/Barbieri-logo-2019-blanco.png" height="50" alt="">
  </a>
</nav>
<div class="container animate__animated animate__bounceIn align-items-center minh-100">
  <div class="row justify-content-center align-items-center minh-100">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-auto">
          <h1 class="bomba"><i class="fas fa-user-lock"></i></h1>
        </div>
        <div class="col-auto">
          <p class="razon-error mt-5">Necesitás autorización para ingresar <br><span><a routerLink="/ingresar" class="link-error">intentá ingresar los datos de nuevo</a></span></p>
        </div>
      </div>
    </div>
  </div>

</div>

