<div *ngIf="numItems" class="pagination">
 <div class="container-fluid pagina">
   <div class="row align-items-center align-self-center">
     <div style="padding: 0;" class="col-auto">
       <button class="btn no-selected" (click)="anterior()"><i class="fas fa-chevron-left"></i></button>
     </div>
     <div style="padding: 0;" class="col-auto text-center align-items-center align-self-center">
       <button *ngFor="let item of total; index as i" class="btn btn-central" (click)="selectPage(item)">{{item}}</button>
    </div>
    <div style="padding: 0;" class="col-auto">
      <button class="btn no-selected" (click)="siguiente()"><i class="fas fa-chevron-right"></i></button>
    </div>
   </div>
 </div>
</div>
