<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4">
    <div class="col-10">
      <h2>Listado de Alumnos</h2>
    </div>
    <div *ngIf="!loading && !notResult" class="col-auto">
      <div class="container-fluid mt-2">
        <div class="row justify-content-right text-right">
          <div class="col-auto">
            <a (click)="downloadExcel()" style="cursor: pointer;"><i class="fas fa-arrow-down" style="margin-right: 10px;"></i>  Descargar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
    <div class="spin"></div>
  </div>
  <div *ngIf="!loading && notResult" class="row mt-5 mb-5 justify-content-center animate__animated animate__fadeIn animate__faster">
    <h3 class="text-cneter">No existen alumnos inscriptos para esta capacitación</h3>
  </div>
  <div *ngIf="!loading && !notResult" class="row mt-4 animate__animated animate__fadeIn animate__faster">
    <div class="col-md-8">
      <div class="container">
        <div class="row">

        </div>
      </div>
    </div>
    <div class="col-md-4">
      <form style="width: 100%;">
        <div class="form-row align-items-center">
          <input type="search" class="form-control search-bar" placeholder="Buscar" name="buscar" [(ngModel)]="searchText" (ngModelChange)="search()" id="buscar">
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="!loading && !notResult" class="row mt-4 animate__animated animate__fadeIn animate__faster">
    <div class="container-fluid">
      <table class="table">
        <thead>
          <tr>
            <th style="color: #022A3A;" scope="col" (click)="orderByName()">Alumno <span *ngIf="isOrderByName"><i class="fas fa-chevron-up"></i></span><span *ngIf="!isOrderByName"><i class="fas fa-chevron-down"></i></span></th>
            <th style="color: #022A3A;" scope="col" >E-Mail </th>
            <th style="color: #022A3A;" scope="col" >País/Estado </th>
            <th style="color: #022A3A;" scope="col" >Ocupación </th>

            <th style="color: #022A3A;" class="text-center" scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr  class="info-row" *ngFor="let i of students | orderBy:order:isEnable | orderByDate:isDate | paginate: {itemsPerPage: 10, currentPage: page}">

            <td>{{i.firstName}} {{i.lastName}}</td>
            <td>{{i.email}}</td>
            <td>{{i.country}} / {{i.state}}</td>
            <td>{{i.location}}</td>
            <td class="text-center">
              <div class="container-fluid">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    <button tooltip="Más Detalles" class="btn btn-action" (click)="showStudent(i.id, template)"><i class="fas fa-eye "></i></button>
                  </div>
                  <div class="col-auto">
                    <button tooltip="Eliminar Alumno" *ngIf="isAdmin" class="btn btn-action" (click)="deleteStudent(i.id, i.firstName, i.lastName)"><i class="far fa-trash-alt"></i></button>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <br>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!loading && !notResult" class="row mt-4 justify-content-center animate__animated animate__fadeIn animate__faster">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
  <div class="row justify-content-center mb-5">
    <a routerLink="/capacitaciones" class="go-back text-center">Volver a Capacitaciones</a>
  </div>
</div>

<ng-template  #template>
  <div style="z-index: 999999;" class="modal-body">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <h3>Datos del alumno</h3>
      </div>
      <div class="row">
        <div class="col-auto">
          <p><span style="font-weight: bold;">Nombre:</span> {{student.firstName}}</p>
        </div>
        <div class="col-auto">
          <p><span style="font-weight: bold;">Apellido:</span> {{student.lastName}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <p><span style="font-weight: bold;">DNI:</span> {{student.dni}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <p>
            <span style="font-weight: bold;">E-Mail: </span> 
            <a class="widthEmail" *ngIf="!isEdit" style="color: #e3000b;" href="mailto:{{student.email}}">{{newEmail ? newEmail : student.email}}</a>
            <input *ngIf="isEdit" type="text" [(ngModel)]="newEmail" [disabled]="isLoading">
            <button *ngIf="!isEdit" class="editemail" (click)="editEmail()"><i class="fas fa-edit"></i></button> 
            <button *ngIf="isEdit && !isLoading" class="successemail" (click)="onChangeEmail($event)"><i class="fas fa-check"></i></button>
            <button *ngIf="isEdit && !isLoading" class="erroremail" (click)="onCancel()"><i class="fas fa-cancel"></i></button>
            
            <button *ngIf="isLoading" class="outline"><div class="spin customSpin"></div> </button>
          </p>
        </div>
   
      </div>
      <div class="row">
      <div class="col-auto">
        <p><span style="font-weight: bold;">Celular: </span> <a style="color: #e3000b;" href="tel:{{student.phone}}">{{student.phone}}</a></p>
      </div>
    </div>
      <div class="row">
        <div class="col-auto">
          <p><span style="font-weight: bold;">País:</span> {{student.country}}</p>
        </div>
        <div class="col-auto">
          <p><span style="font-weight: bold;">Provincia/Estado:</span> {{student.state}}</p>
        </div>
        <div class="col-auto">
          <p><span style="font-weight: bold;">Ciudad:</span> {{student.city}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
