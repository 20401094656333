import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import jwt_decode from 'jwt-decode';
import { CoreService } from 'src/app/services/core.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isAdmin: boolean;

  constructor(private route: Router, private storage: StorageService, private core: CoreService, private auth: AuthService) { }

  ngOnInit(): void {
    let decoded: any = jwt_decode(this.storage.getToken());
    if (decoded.role == 'Administrador') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    this.validation();

  }

  validation(){
    this.core.validateSesion().subscribe( (data: any) => {
      console.log(data);
    }, (error: any) => {
      if (error.status == 401) {
        this.auth.refreshToken();
      }
      console.error(error);
    });
  }

  goToPage(url: string) {
    this.route.navigateByUrl(url);
  }

  hover: boolean[] = [false, false, false, false, false, false, false, false];

  hoverChangeEnter(id: number) {
    this.hover[id] = true;
  }

  hoverChangeLeave(id: number) {
    this.hover[id] = false;
  }
}
