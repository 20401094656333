<app-header></app-header>
<div class="container margen-nav animate__animated animate__fadeIn animate__faster">
  <div class="row mt-4 tablet-phone-mode">
    <div class="col-lg-auto ">
      <h2>Categorías</h2>
    </div>
    <div class="col-lg-6">
      <div class="container-fluid">
        <div class="row justify-content-end-tablet">
          <form class="mobile-search" style="width: 50%;">
            <input class="form-control search-bar" [(ngModel)]="textToSearch" type="search" placeholder="Buscar" name="buscar" id="buscar" (ngModelChange)="search($event)" >
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-3 text-mobile-resposive">
      <button class="btn btn-add" data-toggle="modal" (click)="openModal(template)"><span><i class="fas fa-plus"></i></span>   Agregar Nueva Categoría</button>
    </div>
  </div>
  <div *ngIf="loading" class="row justify-content-center animate__animated animate__bounceIn animate__faster" style="margin-top: 100px !important; margin-bottom: 100px;">
      <div class="spin"></div>
  </div>
  <div *ngIf="!loading" class="row mt-4">
    <div class="container-fluid">
      <div *ngIf="!notResult" class="row">
        <div *ngFor="let item of categories | paginate: {itemsPerPage: 6, currentPage: page}" class="col-lg-4 mb-5">
          <div class="card animate__animated animate__fadeIn animate__faster" style="width: 18rem;">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <i class="home-icon mt-3 ml-2 fas fa-th-list"></i>
                </div>
                <div class="col-6">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-6">
                        <button type="button" (click)="openModalEdit(templateEdit, item.id, item.name, item.idMagento, item.description)" class="btn btn-edit" data-toggle="tooltip" data-placement="top" title="Editar">
                          <i class="fas fa-pen"></i>
                        </button>
                      </div>
                      <div class="col-6">
                        <button (click)="delete(item.id, item.name)" type="button" class="btn btn-delete" data-toggle="tooltip" data-placement="top" title="Eliminar">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12">
                  <h6 class="barb-red card-link ml-2">{{item.name}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="notResult" class="row justify-content-center">
        <h3>No se encuentran coincidencias</h3>
      </div>
    </div>
  </div>
  <div *ngIf="!loading" class="row mt-4 justify-content-center">
    <pagination-controls style="padding-left: 0;" (pageChange)="page = $event" responsive="true" previousLabel=""
    nextLabel=""></pagination-controls>
  </div>
  <div class="row mt-4 justify-content-center mb-5">
    <a routerLink="/inicio" class="go-back text-center">Volver a Inicio</a>
  </div>
</div>

<!-- Modal para agregar nueva categoría -->
<ng-template #template>
      <div style="z-index: 999999;" class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Nueva categoría</h5>
        <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div style="z-index: 999999;" class="modal-body">
        <form [formGroup]="categorieForm" (ngSubmit)="add()">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Nombre*</label>
            <input maxlength="25" minlength="3" type="text" name="name" formControlName="name" ngModel [pattern]="spacePettern" class="form-control" required id="recipient-name">
            <div class="mb-3" *ngIf="categorieForm.controls.name.invalid && (categorieForm.controls.name.dirty || categorieForm.controls.name.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.name.errors?.required">
                El nombre es requerido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.name.errors?.minlength">
                El nombre debe contener más de 3 caracteres.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.name.errors?.pattern">
                El nombre tiene un formato no admitido.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="recipient-idMagento" class="col-form-label">ID Magento*</label>
            <input maxlength="10" minlength="3" formControlName="idMagento" required ngModel type="number" [pattern]="spacePettern" name="idMagento" class="form-control" required id="recipient-idMagento">
            <div class="mb-3" *ngIf="categorieForm.controls.idMagento.invalid && (categorieForm.controls.idMagento.dirty || categorieForm.controls.idMagento.touched)">
              <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.idMagento.errors?.required">
                El ID es requerido.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.idMagento.errors?.minlength">
                El ID debe contener más de 3 cifras.
              </div>
              <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.idMagento.errors?.pattern">
                El ID tiene un formato no admitido.
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="message-text" class="col-form-label">Descripción</label>
            <textarea class="form-control" formControlName="desc" name="desc" ngModel id="message-text"></textarea>
          </div>
          <div class="form-group">
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-auto">
                  <button type="button" class="btn btn-back" (click)="modalRef.hide()">Cerrar</button>
                </div>
                <div class="col-auto">
                  <button type="submit" [disabled]="!categorieForm.valid" class="btn btn-save">Guardar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
</ng-template>

<!-- Modal para editar una categoría -->
<ng-template #templateEdit>
  <div style="z-index: 999999;" class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Editar categoría</h5>
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="z-index: 999999;" class="modal-body">
    <form [formGroup]="categorieForm" (ngSubmit)="edit()">
      <div class="form-group">
        <label for="recipient-name" class="col-form-label">Nombre*</label>
        <input maxlength="35" minlength="3" formControlName="name" required ngModel type="text" [pattern]="spacePettern" name="name" [(ngModel)]="selectedName" class="form-control" required id="recipient-name">
        <div class="mb-3" *ngIf="categorieForm.controls.name.invalid && (categorieForm.controls.name.dirty || categorieForm.controls.name.touched)">
          <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.name.errors?.required">
            El nombre es requerido.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.name.errors?.minlength">
            El nombre debe contener más de 3 caracteres.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.name.errors?.pattern">
            El nombre tiene un formato no admitido.
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="recipient-idMagento" class="col-form-label">ID Magento*</label>
        <input maxlength="10" minlength="3" formControlName="idMagento" required ngModel type="number" [pattern]="spacePettern" name="idMagento" [(ngModel)]="selectedIdMagento" class="form-control" required id="recipient-idMagento">
        <div class="mb-3" *ngIf="categorieForm.controls.idMagento.invalid && (categorieForm.controls.idMagento.dirty || categorieForm.controls.idMagento.touched)">
          <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.idMagento.errors?.required">
            El ID es requerido.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.idMagento.errors?.minlength">
            El ID debe contener más de 3 cifras.
          </div>
          <div class="text-danger" style="font-size: 12px;" *ngIf="categorieForm.controls.idMagento.errors?.pattern">
            El ID tiene un formato no admitido.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="message-text" class="col-form-label">Descripción</label>
        <textarea class="form-control" formControlName="desc" ngModel name="desc" [(ngModel)]="selectedDesc" id="message-text"></textarea>
      </div>
      <div class="form-group">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-auto">
              <button type="button" class="btn btn-back" (click)="modalRef.hide()">Cerrar</button>
            </div>
            <div class="col-auto">
              <button type="submit" [disabled]="!categorieForm.valid" class="btn btn-save">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
