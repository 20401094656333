import { Pipe, PipeTransform } from '@angular/core';
import { CapacitationModel } from '../models/capacitation';

@Pipe({
  name: 'orderByDate'
})
export class OrderByDatePipe implements PipeTransform {

  transform(value: any, isEnable: boolean = true): unknown {
    if (isEnable) {
      let newVal = value.sort((a: any, b: any) => {
        let date1 = new Date(a.startDate);
        let date2 = new Date(b.startDate);

        if (date1 < date2) {
          return 1;
        } else if (date1 > date2) {
          return -1;
        } else {
          return 0;
        }
      });

      return newVal;
    } else {
      let newVal = value.sort((a: any, b: any) => {
        let date1 = new Date(a.startDate);
        let date2 = new Date(b.startDate);

        if (date1 > date2) {
          return 1;
        } else if (date1 < date2) {
          return -1;
        } else {
          return 0;
        }
      });

      return newVal;
    }
  }
}


