import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-fount',
  templateUrl: './not-fount.component.html',
  styleUrls: ['./not-fount.component.scss']
})
export class NotFountComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }

  goToHome() {
    this.route.navigateByUrl('/inicio');
  }

}
